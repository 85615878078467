/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dockGroupCount
// ====================================================

export interface dockGroupCount {
  dockGroupCount: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: systemStatsQuery
// ====================================================

export interface systemStatsQuery_systemActiveTripCount {
  __typename: "Count";
  count: number | null;
}

export interface systemStatsQuery_systemStats {
  __typename: "SystemStats";
  /**
   * The number of trips today
   */
  tripsToday: number;
  /**
   * The number of trips yesterday
   */
  tripsYesterday: number;
  /**
   * highscore number of trips in one day
   */
  highscoreTrips: number;
  /**
   * Number of trips this year
   */
  tripsThisYear: number;
  /**
   * Number of trips last year
   */
  tripsLastYear: number;
  /**
   * The number of active unique users today
   */
  uniqueUsersToday: number;
  /**
   * highscore number of unique users in a day
   */
  highscoreUniqueUsers: number;
  /**
   * The date the highscore for unique users where set
   */
  highscoreUniqueUsersDate: CORE_Date;
  /**
   * The median duration of trips today in seconds
   */
  medianDurationToday: number;
  /**
   * The median duration of trips this year in seconds
   */
  medianDurationThisYear: number;
}

export interface systemStatsQuery {
  systemActiveTripCount: systemStatsQuery_systemActiveTripCount | null;
  /**
   * Statistics for the system
   */
  systemStats: systemStatsQuery_systemStats;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: orderStateQuery
// ====================================================

export interface orderStateQuery {
  orderState: OrderPaymentState;
}

export interface orderStateQueryVariables {
  token: string;
  paymentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: articlePageQuery
// ====================================================

export interface articlePageQuery_articlePage_slugsByLocale {
  __typename: "SanityPageMetaSlug";
  locale: string | null;
  slug: string | null;
}

export interface articlePageQuery_articlePage {
  __typename: "ArticlePage";
  title: string;
  file: CORE_JSON | null;
  heading: string | null;
  intro: CORE_JSON | null;
  content: CORE_JSON | null;
  slugsByLocale: articlePageQuery_articlePage_slugsByLocale[] | null;
}

export interface articlePageQuery {
  articlePage: articlePageQuery_articlePage | null;
}

export interface articlePageQueryVariables {
  systemId?: string | null;
  locale?: string | null;
  slug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: checkPaymentCompletionQuery
// ====================================================

export interface checkPaymentCompletionQuery_checkPaymentCompletion_AsyncProcessCompleted {
  __typename: "AsyncProcessCompleted" | "AsyncProcessFailed" | "AsyncProcessCompletionPostponed";
}

export interface checkPaymentCompletionQuery_checkPaymentCompletion_AsyncProcessCompletionPending {
  __typename: "AsyncProcessCompletionPending";
  checkAfterMilliseconds: CORE_PositiveInt;
}

export type checkPaymentCompletionQuery_checkPaymentCompletion = checkPaymentCompletionQuery_checkPaymentCompletion_AsyncProcessCompleted | checkPaymentCompletionQuery_checkPaymentCompletion_AsyncProcessCompletionPending;

export interface checkPaymentCompletionQuery {
  /**
   * Poll for this until AsyncProcessCompleted or AsyncProcessFailed is returned before max number of tries reached
   */
  checkPaymentCompletion: checkPaymentCompletionQuery_checkPaymentCompletion;
}

export interface checkPaymentCompletionQueryVariables {
  token: string;
  type: PaymentDocumentType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: checkPaymentSetupCompletionQuery
// ====================================================

export interface checkPaymentSetupCompletionQuery_checkPaymentSetupCompletion_AsyncProcessCompleted {
  __typename: "AsyncProcessCompleted" | "AsyncProcessFailed" | "AsyncProcessCompletionPostponed";
}

export interface checkPaymentSetupCompletionQuery_checkPaymentSetupCompletion_AsyncProcessCompletionPending {
  __typename: "AsyncProcessCompletionPending";
  checkAfterMilliseconds: CORE_PositiveInt;
}

export type checkPaymentSetupCompletionQuery_checkPaymentSetupCompletion = checkPaymentSetupCompletionQuery_checkPaymentSetupCompletion_AsyncProcessCompleted | checkPaymentSetupCompletionQuery_checkPaymentSetupCompletion_AsyncProcessCompletionPending;

export interface checkPaymentSetupCompletionQuery {
  /**
   * Poll for this until AsyncProcessCompleted or AsyncProcessFailed is returned before max number of tries reached
   */
  checkPaymentSetupCompletion: checkPaymentSetupCompletionQuery_checkPaymentSetupCompletion;
}

export interface checkPaymentSetupCompletionQueryVariables {
  setupToken: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateCurrentUserMutation
// ====================================================

export interface updateCurrentUserMutation_updateCurrentUser_currentSubscription_product {
  __typename: "Product";
  id: string;
  name: string | null;
  durationMethod: ProductDurationMethod | null;
  durationMins: number | null;
  rentalMeterType: RentalMeterType | null;
  price: CORE_Money | null;
  validTo: CORE_IsoDateTime | null;
  renewOptMethod: ProductOptMethod;
  /**
   * Can the product be renewed?
   */
  isRenewable: boolean;
}

export interface updateCurrentUserMutation_updateCurrentUser_currentSubscription {
  __typename: "ProductSubscription";
  id: string;
  product: updateCurrentUserMutation_updateCurrentUser_currentSubscription_product | null;
  state: ProductSubscriptionStatus | null;
  validFrom: CORE_IsoDateTime | null;
  validTo: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
}

export interface updateCurrentUserMutation_updateCurrentUser_parsedPhoneNumber {
  __typename: "ParsedPhoneNumber";
  countryCode: string;
  nationalNumber: string;
  formattedNumber: string;
}

export interface updateCurrentUserMutation_updateCurrentUser_paymentMethod_Blik {
  __typename: "Blik" | "AcssDebit" | "Affirm" | "AfterpayClearpay" | "Alipay" | "AuBecsDebit" | "BacsDebit" | "Bancontact" | "Cashapp" | "CustomerBalance" | "Eps" | "Fpx" | "Giropay" | "Grabpay" | "Ideal" | "Klarna" | "Konbini" | "Link" | "Oxxo" | "P24" | "Paynow" | "Paypal" | "Pix" | "Promptpay" | "RevolutPay" | "SepaDebit" | "Sofort" | "Swish" | "UsBankAccount" | "WechatPay" | "Zip";
}

export interface updateCurrentUserMutation_updateCurrentUser_paymentMethod_Card {
  __typename: "Card";
  id: string;
  /**
   * Represents expiration date in MM/YY format
   */
  expiresAt: string;
  expired: boolean;
  last4: string;
  brand: CardBrand;
  /**
   * Display version of brand, eg american_express => American Express
   */
  displayBrand: string;
  /**
   * Indicates whether can payment method be removed or not, due to debt or active trip
   */
  removable: boolean;
}

export type updateCurrentUserMutation_updateCurrentUser_paymentMethod = updateCurrentUserMutation_updateCurrentUser_paymentMethod_Blik | updateCurrentUserMutation_updateCurrentUser_paymentMethod_Card;

export interface updateCurrentUserMutation_updateCurrentUser_productRenewals_ProductRenewalPending_product {
  __typename: "Product";
  id: string;
}

export interface updateCurrentUserMutation_updateCurrentUser_productRenewals_ProductRenewalPending {
  __typename: "ProductRenewalPending";
  product: updateCurrentUserMutation_updateCurrentUser_productRenewals_ProductRenewalPending_product;
}

export interface updateCurrentUserMutation_updateCurrentUser_productRenewals_ProductRenewalScheduled_product {
  __typename: "Product";
  id: string;
  price: CORE_Money | null;
}

export interface updateCurrentUserMutation_updateCurrentUser_productRenewals_ProductRenewalScheduled {
  __typename: "ProductRenewalScheduled";
  product: updateCurrentUserMutation_updateCurrentUser_productRenewals_ProductRenewalScheduled_product;
  scheduledAt: CORE_IsoDateTime;
}

export interface updateCurrentUserMutation_updateCurrentUser_productRenewals_ProductRenewalFailed_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface updateCurrentUserMutation_updateCurrentUser_productRenewals_ProductRenewalFailed {
  __typename: "ProductRenewalFailed";
  product: updateCurrentUserMutation_updateCurrentUser_productRenewals_ProductRenewalFailed_product;
}

export type updateCurrentUserMutation_updateCurrentUser_productRenewals = updateCurrentUserMutation_updateCurrentUser_productRenewals_ProductRenewalPending | updateCurrentUserMutation_updateCurrentUser_productRenewals_ProductRenewalScheduled | updateCurrentUserMutation_updateCurrentUser_productRenewals_ProductRenewalFailed;

export interface updateCurrentUserMutation_updateCurrentUser_wallet {
  __typename: "UserWallet";
  enabled: boolean;
  balance: CORE_Money;
  currency: string;
}

export interface updateCurrentUserMutation_updateCurrentUser_currentTrip {
  __typename: "Trip";
  id: string;
}

export interface updateCurrentUserMutation_updateCurrentUser {
  __typename: "User";
  id: string;
  name: string | null;
  email: string | null;
  gender: Gender | null;
  birthYear: string | null;
  preferredLanguageCode: string | null;
  postalCode: string | null;
  havePinCode: boolean | null;
  currentSubscription: updateCurrentUserMutation_updateCurrentUser_currentSubscription | null;
  phoneNumber: string;
  parsedPhoneNumber: updateCurrentUserMutation_updateCurrentUser_parsedPhoneNumber;
  penaltyStatus: PenaltyStatus;
  agreedToReceiveEmail: boolean | null;
  agreedToTerms: boolean;
  agreedToReceiveExternalPromotions: boolean | null;
  agreedToRegistrationAgreementWithdrawal: boolean;
  agreedToSubscriptionAgreementWithdrawal: boolean;
  agreedToSendDataToThirdParties: boolean | null;
  paymentMethod: updateCurrentUserMutation_updateCurrentUser_paymentMethod | null;
  requiresAddingPaymentCard: boolean | null;
  hasUnpaidOrders: boolean | null;
  /**
   * List of product renewals
   */
  productRenewals: updateCurrentUserMutation_updateCurrentUser_productRenewals[];
  superpowers: boolean | null;
  rfid: string | null;
  wallet: updateCurrentUserMutation_updateCurrentUser_wallet | null;
  currentTrip: updateCurrentUserMutation_updateCurrentUser_currentTrip | null;
}

export interface updateCurrentUserMutation {
  updateCurrentUser: updateCurrentUserMutation_updateCurrentUser | null;
}

export interface updateCurrentUserMutationVariables {
  input?: UserInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: buyProductPageQuery
// ====================================================

export interface buyProductPageQuery_products_image_metadata_dimensions {
  __typename: "SanityImageMetaDataDimensions";
  width: number | null;
  height: number | null;
}

export interface buyProductPageQuery_products_image_metadata {
  __typename: "SanityImageMetaData";
  dimensions: buyProductPageQuery_products_image_metadata_dimensions | null;
}

export interface buyProductPageQuery_products_image {
  __typename: "SanityImage";
  url: string | null;
  metadata: buyProductPageQuery_products_image_metadata | null;
}

export interface buyProductPageQuery_products_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface buyProductPageQuery_products_connectionPriceMatrixes_priceMatrix_matrix {
  __typename: "PriceMatrixItem";
  minutes: number;
  price: CORE_Money;
  quantity: number;
  type: PriceMatrixItemType;
}

export interface buyProductPageQuery_products_connectionPriceMatrixes_priceMatrix {
  __typename: "PriceMatrix";
  lastStepInfinite: boolean;
  matrix: buyProductPageQuery_products_connectionPriceMatrixes_priceMatrix_matrix[];
}

export interface buyProductPageQuery_products_connectionPriceMatrixes {
  __typename: "ConnectionPriceMatrix";
  id: string;
  type: ConnectionPriceMatrixType;
  priceMatrix: buyProductPageQuery_products_connectionPriceMatrixes_priceMatrix;
  vehicleCategory: VehicleCategory2;
}

export interface buyProductPageQuery_products {
  __typename: "Product";
  id: string;
  /**
   * Localised product description
   */
  descriptionText: string | null;
  name: string | null;
  image: buyProductPageQuery_products_image | null;
  slug: buyProductPageQuery_products_slug | null;
  price: CORE_Money | null;
  extendedRentalPeriodCharge: CORE_Money | null;
  tripStartCharge: CORE_Money | null;
  rentalMeterType: RentalMeterType | null;
  campaign: string | null;
  purchasable: boolean | null;
  purchasableFrom: CORE_IsoDateTime | null;
  purchasableTo: CORE_IsoDateTime | null;
  connectionPriceMatrixes: buyProductPageQuery_products_connectionPriceMatrixes[] | null;
  renewOptMethod: ProductOptMethod;
  durationMethod: ProductDurationMethod | null;
  durationMins: number | null;
}

export interface buyProductPageQuery_buyProductPage_purchaseFeatures {
  __typename: "SanityBuyProductPagePurchaseFeature";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
}

export interface buyProductPageQuery_buyProductPage_consents {
  __typename: "SanityConsentDescriptions";
  info: string | null;
  autoRenew: string | null;
  allowCharges: string | null;
}

export interface buyProductPageQuery_buyProductPage {
  __typename: "BuyProductPage";
  title: string;
  heading: string | null;
  giftCardBlock: CORE_JSON | null;
  purchaseFeatures: (buyProductPageQuery_buyProductPage_purchaseFeatures | null)[] | null;
  consents: buyProductPageQuery_buyProductPage_consents | null;
}

export interface buyProductPageQuery_system_connectionPriceMatrixes_priceMatrix_matrix {
  __typename: "PriceMatrixItem";
  minutes: number;
  price: CORE_Money;
  quantity: number;
  type: PriceMatrixItemType;
}

export interface buyProductPageQuery_system_connectionPriceMatrixes_priceMatrix {
  __typename: "PriceMatrix";
  lastStepInfinite: boolean;
  matrix: buyProductPageQuery_system_connectionPriceMatrixes_priceMatrix_matrix[];
}

export interface buyProductPageQuery_system_connectionPriceMatrixes {
  __typename: "ConnectionPriceMatrix";
  id: string;
  type: ConnectionPriceMatrixType;
  priceMatrix: buyProductPageQuery_system_connectionPriceMatrixes_priceMatrix;
  vehicleCategory: VehicleCategory2;
}

export interface buyProductPageQuery_system {
  __typename: "System";
  id: string;
  connectionPriceMatrixes: buyProductPageQuery_system_connectionPriceMatrixes[] | null;
}

export interface buyProductPageQuery {
  products: buyProductPageQuery_products[] | null;
  buyProductPage: buyProductPageQuery_buyProductPage | null;
  system: buyProductPageQuery_system | null;
}

export interface buyProductPageQueryVariables {
  systemId?: string | null;
  locale?: string | null;
  productImageOptions?: SanityImageOptions | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: requiredUserInputFieldsQuery
// ====================================================

export interface requiredUserInputFieldsQuery_requiredUserInputFields {
  __typename: "RequiredUserInputFields";
  email: boolean;
}

export interface requiredUserInputFieldsQuery {
  /**
   * These user fields should be marked as required in the UI
   */
  requiredUserInputFields: requiredUserInputFieldsQuery_requiredUserInputFields;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: settings
// ====================================================

export interface settings_system_parsedContactNumber {
  __typename: "ParsedPhoneNumber";
  formattedNumber: string;
}

export interface settings_system_season {
  __typename: "Season";
  startDate: CORE_IsoDateTime | null;
}

export interface settings_system_workingHours {
  __typename: "WorkingHours";
  startDate: CORE_IsoDateTime | null;
  endDate: CORE_IsoDateTime | null;
}

export interface settings_system_paymentProvider {
  __typename: "PaymentProviderStripe" | "PaymentProviderPayex";
}

export interface settings_system_configuration {
  __typename: "SystemConfiguration";
  /**
   * Show the checkbox for sharing data with third parties on the terms screen
   */
  showAgreedToSendDataToThirdPartiesCheckbox: boolean | null;
}

export interface settings_system_legalConsents {
  __typename: "LegalConsent";
  /**
   * Type of consent
   */
  type: LegalConsentType;
  /**
   * What kind of enforcement is used for it
   */
  enforcement: ConsentEnforcement;
}

export interface settings_system {
  __typename: "System";
  id: string;
  name: string;
  /**
   * Localised city name
   */
  cityName: string;
  type: SystemType;
  phoneCountryCode: string | null;
  parsedContactNumber: settings_system_parsedContactNumber | null;
  contactEmail: string | null;
  iosAppUrl: string | null;
  androidAppUrl: string | null;
  businessUrl: string | null;
  closedReason: string | null;
  inSeason: boolean | null;
  season: settings_system_season | null;
  publicDomain: string | null;
  standardRentalMins: number | null;
  sponsor: Sponsor | null;
  GBFS: boolean | null;
  publicTripHistory: boolean | null;
  intercomHelpSite: string | null;
  /**
   * Enablement level of the User Wallet feature
   */
  walletEnablement: WalletEnablement;
  /**
   * If enabled new app users should pay a fee
   */
  registrationFeeEnabled: boolean;
  /**
   * Defines the amount of the registration fee
   */
  registrationFeeAmount: CORE_Money;
  /**
   * If disabled, users cannot register a new account with this system
   */
  registrationEnabled: boolean;
  /**
   * Systems working hours for today
   */
  workingHours: settings_system_workingHours | null;
  /**
   * Payment provider used for checkout or charge
   */
  paymentProvider: settings_system_paymentProvider | null;
  /**
   * Various UI configurations
   */
  configuration: settings_system_configuration;
  /**
   * Which checkboxes to show on app registration screen
   */
  legalConsents: settings_system_legalConsents[];
  rfidAssignMethod: UserRfidAssignMethod[] | null;
  /**
   * Payment methods available for the system
   */
  availablePaymentMethods: PaymentMethodType[];
}

export interface settings_settings_primaryCta {
  __typename: "SanitySlug";
  current: string;
}

export interface settings_settings_privacyPageSlug {
  __typename: "SanitySlug";
  current: string;
}

export interface settings_settings_header_ctaButton_page {
  __typename: "SanitySettingsNavigationPage";
  _type: string;
}

export interface settings_settings_header_ctaButton {
  __typename: "SanitySettingsNavigation";
  title: string | null;
  page: settings_settings_header_ctaButton_page | null;
  externalLink: string | null;
  showIntercom: boolean | null;
}

export interface settings_settings_header {
  __typename: "SanityHeader";
  ctaButton: settings_settings_header_ctaButton | null;
}

export interface settings_settings_footer_navColumns_items_page_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface settings_settings_footer_navColumns_items_page {
  __typename: "SanitySettingsNavigationPage";
  _id: string;
  _type: string;
  slug: settings_settings_footer_navColumns_items_page_slug | null;
}

export interface settings_settings_footer_navColumns_items {
  __typename: "SanitySettingsNavigation";
  page: settings_settings_footer_navColumns_items_page | null;
  externalLink: string | null;
  showIntercom: boolean | null;
  title: string | null;
  _key: string | null;
}

export interface settings_settings_footer_navColumns {
  __typename: "SanityFooterNavColumn";
  _key: string;
  title: string | null;
  items: (settings_settings_footer_navColumns_items | null)[] | null;
}

export interface settings_settings_footer_socialMediaLinks {
  __typename: "SanitySocialMediaLink";
  _key: string;
  name: SocialMediaName | null;
  url: string | null;
}

export interface settings_settings_footer {
  __typename: "SanityFooter";
  aboutOperator: (CORE_JSON | null)[] | null;
  helpText: (CORE_JSON | null)[] | null;
  navColumns: (settings_settings_footer_navColumns | null)[] | null;
  socialMediaLinks: settings_settings_footer_socialMediaLinks[] | null;
}

export interface settings_settings_navigation_page_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface settings_settings_navigation_page {
  __typename: "SanitySettingsNavigationPage";
  _id: string;
  _type: string;
  slug: settings_settings_navigation_page_slug | null;
}

export interface settings_settings_navigation {
  __typename: "SanitySettingsNavigation";
  page: settings_settings_navigation_page | null;
  externalLink: string | null;
  showIntercom: boolean | null;
  title: string | null;
  _key: string | null;
}

export interface settings_settings {
  __typename: "SanitySettings";
  title: string;
  primaryCta: settings_settings_primaryCta | null;
  privacyPageSlug: settings_settings_privacyPageSlug | null;
  header: settings_settings_header | null;
  footer: settings_settings_footer | null;
  navigation: settings_settings_navigation[] | null;
  /**
   * BlockContent to be used in the cookie notification. If null, cookie notification is not shown on public-web
   */
  cookieNotification: CORE_JSON | null;
  strictlyNecessaryCookiesContent: CORE_JSON | null;
  performanceCookiesContent: CORE_JSON | null;
  functionalCookiesContent: CORE_JSON | null;
  targetingCookiesContent: CORE_JSON | null;
}

export interface settings {
  system: settings_system | null;
  settings: settings_settings;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: startPage
// ====================================================

export interface startPage_products_image {
  __typename: "SanityImage";
  url: string | null;
}

export interface startPage_products_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface startPage_products {
  __typename: "Product";
  id: string;
  /**
   * Localised product description
   */
  descriptionText: string | null;
  name: string | null;
  image: startPage_products_image | null;
  slug: startPage_products_slug | null;
  price: CORE_Money | null;
  campaign: string | null;
  purchasable: boolean | null;
}

export interface startPage_giftableProducts_image {
  __typename: "SanityImage";
  url: string | null;
}

export interface startPage_giftableProducts_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface startPage_giftableProducts {
  __typename: "Product";
  id: string;
  /**
   * Localised product description
   */
  descriptionText: string | null;
  name: string | null;
  image: startPage_giftableProducts_image | null;
  slug: startPage_giftableProducts_slug | null;
  price: CORE_Money | null;
  campaign: string | null;
  purchasable: boolean | null;
}

export interface startPage_system {
  __typename: "System";
  id: string;
  inSeason: boolean | null;
  name: string;
  sponsor: Sponsor | null;
  GBFS: boolean | null;
  publicTripHistory: boolean | null;
}

export interface startPage_startPage_opengraph {
  __typename: "SanityOpenGraph";
  title: string | null;
  description: string | null;
  images: (string | null)[] | null;
}

export interface startPage_startPage_features {
  __typename: "StartPageFeature";
  _key: string | null;
  title: string | null;
  description: CORE_JSON | null;
  image: string | null;
}

export interface startPage_startPage_heroImageLg {
  __typename: "SanityImage";
  url: string | null;
}

export interface startPage_startPage_heroImageMd {
  __typename: "SanityImage";
  url: string | null;
}

export interface startPage_startPage_heroImageSm {
  __typename: "SanityImage";
  url: string | null;
}

export interface startPage_startPage_featureBlocks_SanityFeatureModule_figureModule {
  __typename: "SanityFeatureFigureVideoGuide";
  _key: string;
  _type: string | null;
  video: string | null;
}

export interface startPage_startPage_featureBlocks_SanityFeatureModule {
  __typename: "SanityFeatureModule";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
  figureModule: startPage_startPage_featureBlocks_SanityFeatureModule_figureModule[] | null;
  hideFromApps: boolean | null;
}

export interface startPage_startPage_featureBlocks_SanityMultiFeatureModule_featureModules_figureModule {
  __typename: "SanityFeatureFigureVideoGuide";
  _key: string;
  _type: string | null;
  video: string | null;
}

export interface startPage_startPage_featureBlocks_SanityMultiFeatureModule_featureModules {
  __typename: "SanityMultiFigureModule";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
  figureModule: startPage_startPage_featureBlocks_SanityMultiFeatureModule_featureModules_figureModule[] | null;
}

export interface startPage_startPage_featureBlocks_SanityMultiFeatureModule {
  __typename: "SanityMultiFeatureModule";
  _key: string;
  title: string | null;
  featureModules: startPage_startPage_featureBlocks_SanityMultiFeatureModule_featureModules[] | null;
}

export interface startPage_startPage_featureBlocks_SanityFeatureModuleTripDuration {
  __typename: "SanityFeatureModuleTripDuration";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
}

export interface startPage_startPage_featureBlocks_SanityFeatureVideo_poster1x1 {
  __typename: "SanityImage";
  url: string | null;
}

export interface startPage_startPage_featureBlocks_SanityFeatureVideo_poster16x9 {
  __typename: "SanityImage";
  url: string | null;
}

export interface startPage_startPage_featureBlocks_SanityFeatureVideo {
  __typename: "SanityFeatureVideo";
  _key: string;
  title: string | null;
  videoUrl: string | null;
  autoplay: boolean | null;
  poster1x1: startPage_startPage_featureBlocks_SanityFeatureVideo_poster1x1 | null;
  poster16x9: startPage_startPage_featureBlocks_SanityFeatureVideo_poster16x9 | null;
}

export interface startPage_startPage_featureBlocks_SanityFeatureImage_image_metadata_dimensions {
  __typename: "SanityImageMetaDataDimensions";
  width: number | null;
  height: number | null;
}

export interface startPage_startPage_featureBlocks_SanityFeatureImage_image_metadata {
  __typename: "SanityImageMetaData";
  dimensions: startPage_startPage_featureBlocks_SanityFeatureImage_image_metadata_dimensions | null;
}

export interface startPage_startPage_featureBlocks_SanityFeatureImage_image {
  __typename: "SanityImage";
  url: string | null;
  metadata: startPage_startPage_featureBlocks_SanityFeatureImage_image_metadata | null;
}

export interface startPage_startPage_featureBlocks_SanityFeatureImage_link_page_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface startPage_startPage_featureBlocks_SanityFeatureImage_link_page {
  __typename: "SanityLinkPage";
  _id: string;
  _type: string;
  slug: startPage_startPage_featureBlocks_SanityFeatureImage_link_page_slug | null;
}

export interface startPage_startPage_featureBlocks_SanityFeatureImage_link {
  __typename: "SanityLinkOnly";
  _key: string | null;
  page: startPage_startPage_featureBlocks_SanityFeatureImage_link_page | null;
  externalLink: string | null;
  showIntercom: boolean | null;
}

export interface startPage_startPage_featureBlocks_SanityFeatureImage {
  __typename: "SanityFeatureImage";
  _key: string;
  /**
   * Internal block name
   */
  blockName: string | null;
  alt: string | null;
  image: startPage_startPage_featureBlocks_SanityFeatureImage_image | null;
  variant: SanityFeatureModuleVariant | null;
  hideFromApps: boolean | null;
  link: startPage_startPage_featureBlocks_SanityFeatureImage_link | null;
}

export interface startPage_startPage_featureBlocks_SanityHowItWorksFeatureBlock_steps {
  __typename: "SanityHowWorksStep";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
}

export interface startPage_startPage_featureBlocks_SanityHowItWorksFeatureBlock {
  __typename: "SanityHowItWorksFeatureBlock";
  _key: string;
  steps: startPage_startPage_featureBlocks_SanityHowItWorksFeatureBlock_steps[] | null;
  demonstrationVideo: string | null;
}

export interface startPage_startPage_featureBlocks_SanityStationMapFeatureBlock {
  __typename: "SanityStationMapFeatureBlock";
  _key: string;
  description: CORE_JSON | null;
  variant: SanityFeatureModuleVariant | null;
  hideFromApps: boolean | null;
}

export interface startPage_startPage_featureBlocks_SanityTripStatsFeatureBlock_phraseGroups {
  __typename: "SanityTripStatsFeatureBlockTripCountPhraseGroup";
  _key: string;
  phrases: (string | null)[] | null;
  threshold: number;
}

export interface startPage_startPage_featureBlocks_SanityTripStatsFeatureBlock {
  __typename: "SanityTripStatsFeatureBlock";
  _key: string;
  variant: SanityFeatureModuleVariant | null;
  hideFromApps: boolean | null;
  phraseGroups: (startPage_startPage_featureBlocks_SanityTripStatsFeatureBlock_phraseGroups | null)[] | null;
}

export interface startPage_startPage_featureBlocks_SanityPageFeatureBlock {
  __typename: "SanityPageFeatureBlock";
  _key: string;
  title: string | null;
  pageRefType: string | null;
  variant: SanityFeatureModuleVariant | null;
  hideFromApps: boolean | null;
}

export interface startPage_startPage_featureBlocks_SanityInfoBlock_link_page_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface startPage_startPage_featureBlocks_SanityInfoBlock_link_page {
  __typename: "SanitySettingsNavigationPage";
  _id: string;
  _type: string;
  slug: startPage_startPage_featureBlocks_SanityInfoBlock_link_page_slug | null;
}

export interface startPage_startPage_featureBlocks_SanityInfoBlock_link {
  __typename: "SanitySettingsNavigation";
  page: startPage_startPage_featureBlocks_SanityInfoBlock_link_page | null;
  externalLink: string | null;
  showIntercom: boolean | null;
  title: string | null;
  _key: string | null;
}

export interface startPage_startPage_featureBlocks_SanityInfoBlock {
  __typename: "SanityInfoBlock";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
  emotion: string | null;
  variant: SanityFeatureModuleVariant | null;
  hideFromApps: boolean | null;
  link: startPage_startPage_featureBlocks_SanityInfoBlock_link | null;
}

export type startPage_startPage_featureBlocks = startPage_startPage_featureBlocks_SanityFeatureModule | startPage_startPage_featureBlocks_SanityMultiFeatureModule | startPage_startPage_featureBlocks_SanityFeatureModuleTripDuration | startPage_startPage_featureBlocks_SanityFeatureVideo | startPage_startPage_featureBlocks_SanityFeatureImage | startPage_startPage_featureBlocks_SanityHowItWorksFeatureBlock | startPage_startPage_featureBlocks_SanityStationMapFeatureBlock | startPage_startPage_featureBlocks_SanityTripStatsFeatureBlock | startPage_startPage_featureBlocks_SanityPageFeatureBlock | startPage_startPage_featureBlocks_SanityInfoBlock;

export interface startPage_startPage {
  __typename: "SanityStartPage";
  opengraph: startPage_startPage_opengraph | null;
  heading: string | null;
  primaryCtaButtonText: string | null;
  features: (startPage_startPage_features | null)[] | null;
  heroVideo: string | null;
  heroImageLg: startPage_startPage_heroImageLg | null;
  heroImageMd: startPage_startPage_heroImageMd | null;
  heroImageSm: startPage_startPage_heroImageSm | null;
  featureBlocks: startPage_startPage_featureBlocks[] | null;
}

export interface startPage_settings {
  __typename: "SanitySettings";
  title: string;
}

export interface startPage {
  products: startPage_products[] | null;
  giftableProducts: startPage_giftableProducts[] | null;
  system: startPage_system | null;
  startPage: startPage_startPage | null;
  settings: startPage_settings;
}

export interface startPageVariables {
  systemId?: string | null;
  locale?: string | null;
  productImageOptions?: SanityImageOptions | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: staticDataQuery
// ====================================================

export interface staticDataQuery_system {
  __typename: "System";
  id: string;
  timezone: string | null;
  colourKey: string | null;
  currency: string | null;
  defaultLanguageCode: LanguageCode | null;
  languageCodes: LanguageCode[] | null;
  sanityProjectId: string | null;
  sanityDataset: string | null;
  /**
   * Localised city name
   */
  cityName: string;
  contactEmail: string | null;
  /**
   * Does the system have any station clients which accepts PIN code?
   */
  usersCanUnlockWithPinCode: boolean;
  /**
   * If disabled, users cannot register a new account with this system
   */
  registrationEnabled: boolean;
}

export interface staticDataQuery {
  system: staticDataQuery_system | null;
  phoneVerificationCodeLength: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: systemAlertsQuery
// ====================================================

export interface systemAlertsQuery_systemClosureAlerts_dockGroups {
  __typename: "DockGroup";
  id: string;
  title: string | null;
}

export interface systemAlertsQuery_systemClosureAlerts_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface systemAlertsQuery_systemClosureAlerts_updates {
  __typename: "SystemAlertUpdate";
  id: string;
  createdAt: CORE_IsoDateTime;
  title: string | null;
  description: string | null;
}

export interface systemAlertsQuery_systemClosureAlerts {
  __typename: "SystemAlert";
  id: string;
  resolvedAt: CORE_IsoDateTime | null;
  dockGroups: (systemAlertsQuery_systemClosureAlerts_dockGroups | null)[] | null;
  systemAlertType: SystemAlertType;
  incidentStartTime: CORE_IsoDateTime;
  incidentEndTime: CORE_IsoDateTime | null;
  title: string | null;
  description: string | null;
  visibility: SystemAlertVisibility;
  slug: systemAlertsQuery_systemClosureAlerts_slug | null;
  updates: (systemAlertsQuery_systemClosureAlerts_updates | null)[] | null;
}

export interface systemAlertsQuery_systemAlerts_dockGroups {
  __typename: "DockGroup";
  id: string;
  title: string | null;
}

export interface systemAlertsQuery_systemAlerts_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface systemAlertsQuery_systemAlerts_updates {
  __typename: "SystemAlertUpdate";
  id: string;
  createdAt: CORE_IsoDateTime;
  title: string | null;
  description: string | null;
}

export interface systemAlertsQuery_systemAlerts {
  __typename: "SystemAlert";
  id: string;
  resolvedAt: CORE_IsoDateTime | null;
  dockGroups: (systemAlertsQuery_systemAlerts_dockGroups | null)[] | null;
  systemAlertType: SystemAlertType;
  incidentStartTime: CORE_IsoDateTime;
  incidentEndTime: CORE_IsoDateTime | null;
  title: string | null;
  description: string | null;
  visibility: SystemAlertVisibility;
  slug: systemAlertsQuery_systemAlerts_slug | null;
  updates: (systemAlertsQuery_systemAlerts_updates | null)[] | null;
}

export interface systemAlertsQuery {
  systemClosureAlerts: systemAlertsQuery_systemClosureAlerts[] | null;
  systemAlerts: systemAlertsQuery_systemAlerts[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: currentUserQuery
// ====================================================

export interface currentUserQuery_currentUser_currentSubscription_product {
  __typename: "Product";
  id: string;
  name: string | null;
  durationMethod: ProductDurationMethod | null;
  durationMins: number | null;
  rentalMeterType: RentalMeterType | null;
  price: CORE_Money | null;
  validTo: CORE_IsoDateTime | null;
  renewOptMethod: ProductOptMethod;
  /**
   * Can the product be renewed?
   */
  isRenewable: boolean;
}

export interface currentUserQuery_currentUser_currentSubscription {
  __typename: "ProductSubscription";
  id: string;
  product: currentUserQuery_currentUser_currentSubscription_product | null;
  state: ProductSubscriptionStatus | null;
  validFrom: CORE_IsoDateTime | null;
  validTo: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
}

export interface currentUserQuery_currentUser_parsedPhoneNumber {
  __typename: "ParsedPhoneNumber";
  countryCode: string;
  nationalNumber: string;
  formattedNumber: string;
}

export interface currentUserQuery_currentUser_paymentMethod_Blik {
  __typename: "Blik" | "AcssDebit" | "Affirm" | "AfterpayClearpay" | "Alipay" | "AuBecsDebit" | "BacsDebit" | "Bancontact" | "Cashapp" | "CustomerBalance" | "Eps" | "Fpx" | "Giropay" | "Grabpay" | "Ideal" | "Klarna" | "Konbini" | "Link" | "Oxxo" | "P24" | "Paynow" | "Paypal" | "Pix" | "Promptpay" | "RevolutPay" | "SepaDebit" | "Sofort" | "Swish" | "UsBankAccount" | "WechatPay" | "Zip";
}

export interface currentUserQuery_currentUser_paymentMethod_Card {
  __typename: "Card";
  id: string;
  /**
   * Represents expiration date in MM/YY format
   */
  expiresAt: string;
  expired: boolean;
  last4: string;
  brand: CardBrand;
  /**
   * Display version of brand, eg american_express => American Express
   */
  displayBrand: string;
  /**
   * Indicates whether can payment method be removed or not, due to debt or active trip
   */
  removable: boolean;
}

export type currentUserQuery_currentUser_paymentMethod = currentUserQuery_currentUser_paymentMethod_Blik | currentUserQuery_currentUser_paymentMethod_Card;

export interface currentUserQuery_currentUser_productRenewals_ProductRenewalPending_product {
  __typename: "Product";
  id: string;
}

export interface currentUserQuery_currentUser_productRenewals_ProductRenewalPending {
  __typename: "ProductRenewalPending";
  product: currentUserQuery_currentUser_productRenewals_ProductRenewalPending_product;
}

export interface currentUserQuery_currentUser_productRenewals_ProductRenewalScheduled_product {
  __typename: "Product";
  id: string;
  price: CORE_Money | null;
}

export interface currentUserQuery_currentUser_productRenewals_ProductRenewalScheduled {
  __typename: "ProductRenewalScheduled";
  product: currentUserQuery_currentUser_productRenewals_ProductRenewalScheduled_product;
  scheduledAt: CORE_IsoDateTime;
}

export interface currentUserQuery_currentUser_productRenewals_ProductRenewalFailed_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface currentUserQuery_currentUser_productRenewals_ProductRenewalFailed {
  __typename: "ProductRenewalFailed";
  product: currentUserQuery_currentUser_productRenewals_ProductRenewalFailed_product;
}

export type currentUserQuery_currentUser_productRenewals = currentUserQuery_currentUser_productRenewals_ProductRenewalPending | currentUserQuery_currentUser_productRenewals_ProductRenewalScheduled | currentUserQuery_currentUser_productRenewals_ProductRenewalFailed;

export interface currentUserQuery_currentUser_wallet {
  __typename: "UserWallet";
  enabled: boolean;
  balance: CORE_Money;
  currency: string;
}

export interface currentUserQuery_currentUser_currentTrip {
  __typename: "Trip";
  id: string;
}

export interface currentUserQuery_currentUser {
  __typename: "User";
  id: string;
  name: string | null;
  email: string | null;
  gender: Gender | null;
  birthYear: string | null;
  preferredLanguageCode: string | null;
  postalCode: string | null;
  havePinCode: boolean | null;
  currentSubscription: currentUserQuery_currentUser_currentSubscription | null;
  phoneNumber: string;
  parsedPhoneNumber: currentUserQuery_currentUser_parsedPhoneNumber;
  penaltyStatus: PenaltyStatus;
  agreedToReceiveEmail: boolean | null;
  agreedToTerms: boolean;
  agreedToReceiveExternalPromotions: boolean | null;
  agreedToRegistrationAgreementWithdrawal: boolean;
  agreedToSubscriptionAgreementWithdrawal: boolean;
  agreedToSendDataToThirdParties: boolean | null;
  paymentMethod: currentUserQuery_currentUser_paymentMethod | null;
  requiresAddingPaymentCard: boolean | null;
  hasUnpaidOrders: boolean | null;
  /**
   * List of product renewals
   */
  productRenewals: currentUserQuery_currentUser_productRenewals[];
  superpowers: boolean | null;
  rfid: string | null;
  wallet: currentUserQuery_currentUser_wallet | null;
  currentTrip: currentUserQuery_currentUser_currentTrip | null;
}

export interface currentUserQuery {
  intercomSettings: CORE_JSON | null;
  currentUser: currentUserQuery_currentUser | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: sanityQuery
// ====================================================

export interface sanityQuery {
  sanityQuery: CORE_JSON | null;
}

export interface sanityQueryVariables {
  query: string;
  params?: CORE_JSON | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: systemConnectionPriceMatrixesQuery
// ====================================================

export interface systemConnectionPriceMatrixesQuery_system_connectionPriceMatrixes_priceMatrix_matrix {
  __typename: "PriceMatrixItem";
  minutes: number;
  price: CORE_Money;
  type: PriceMatrixItemType;
}

export interface systemConnectionPriceMatrixesQuery_system_connectionPriceMatrixes_priceMatrix {
  __typename: "PriceMatrix";
  matrix: systemConnectionPriceMatrixesQuery_system_connectionPriceMatrixes_priceMatrix_matrix[];
}

export interface systemConnectionPriceMatrixesQuery_system_connectionPriceMatrixes {
  __typename: "ConnectionPriceMatrix";
  id: string;
  priceMatrix: systemConnectionPriceMatrixesQuery_system_connectionPriceMatrixes_priceMatrix;
}

export interface systemConnectionPriceMatrixesQuery_system {
  __typename: "System";
  id: string;
  connectionPriceMatrixes: systemConnectionPriceMatrixesQuery_system_connectionPriceMatrixes[] | null;
}

export interface systemConnectionPriceMatrixesQuery {
  system: systemConnectionPriceMatrixesQuery_system | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: manifestJson
// ====================================================

export interface manifestJson_system {
  __typename: "System";
  id: string;
  name: string;
  androidAppUrl: string | null;
  colourKey: string | null;
}

export interface manifestJson {
  system: manifestJson_system | null;
}

export interface manifestJsonVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: robotsTxtQuery
// ====================================================

export interface robotsTxtQuery_system {
  __typename: "System";
  id: string;
  publicDomain: string | null;
}

export interface robotsTxtQuery {
  system: robotsTxtQuery_system | null;
}

export interface robotsTxtQueryVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: sitemapQuery
// ====================================================

export interface sitemapQuery_sanityArticles_slugsByLocale {
  __typename: "SanityPageMetaSlug";
  locale: string | null;
  slug: string | null;
}

export interface sitemapQuery_sanityArticles {
  __typename: "SanitySitemapArticles";
  slugsByLocale: sitemapQuery_sanityArticles_slugsByLocale[] | null;
}

export interface sitemapQuery_system {
  __typename: "System";
  id: string;
  defaultLanguageCode: LanguageCode | null;
  publicDomain: string | null;
  languageCodes: LanguageCode[] | null;
  GBFS: boolean | null;
  publicTripHistory: boolean | null;
}

export interface sitemapQuery {
  sanityArticles: (sitemapQuery_sanityArticles | null)[] | null;
  system: sitemapQuery_system | null;
}

export interface sitemapQueryVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: intercomSettingsQuery
// ====================================================

export interface intercomSettingsQuery {
  intercomSettings: CORE_JSON | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: appLandingPageQuery
// ====================================================

export interface appLandingPageQuery_system_appInfo {
  __typename: "AppInfo";
  os: AppOs | null;
  url: string | null;
}

export interface appLandingPageQuery_system {
  __typename: "System";
  id: string;
  appInfo: appLandingPageQuery_system_appInfo | null;
}

export interface appLandingPageQuery {
  system: appLandingPageQuery_system | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: buyGiftCardPageQuery
// ====================================================

export interface buyGiftCardPageQuery_products_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface buyGiftCardPageQuery_products_connectionPriceMatrixes_priceMatrix_matrix {
  __typename: "PriceMatrixItem";
  minutes: number;
  price: CORE_Money;
  quantity: number;
  type: PriceMatrixItemType;
}

export interface buyGiftCardPageQuery_products_connectionPriceMatrixes_priceMatrix {
  __typename: "PriceMatrix";
  lastStepInfinite: boolean;
  matrix: buyGiftCardPageQuery_products_connectionPriceMatrixes_priceMatrix_matrix[];
}

export interface buyGiftCardPageQuery_products_connectionPriceMatrixes {
  __typename: "ConnectionPriceMatrix";
  id: string;
  type: ConnectionPriceMatrixType;
  priceMatrix: buyGiftCardPageQuery_products_connectionPriceMatrixes_priceMatrix;
  vehicleCategory: VehicleCategory2;
}

export interface buyGiftCardPageQuery_products {
  __typename: "Product";
  id: string;
  /**
   * Localised product description
   */
  descriptionText: string | null;
  name: string | null;
  price: CORE_Money | null;
  extendedRentalPeriodCharge: CORE_Money | null;
  tripStartCharge: CORE_Money | null;
  rentalMeterType: RentalMeterType | null;
  campaign: string | null;
  slug: buyGiftCardPageQuery_products_slug | null;
  connectionPriceMatrixes: buyGiftCardPageQuery_products_connectionPriceMatrixes[] | null;
}

export interface buyGiftCardPageQuery_productBySlug_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface buyGiftCardPageQuery_productBySlug_connectionPriceMatrixes_priceMatrix_matrix {
  __typename: "PriceMatrixItem";
  minutes: number;
  price: CORE_Money;
  quantity: number;
  type: PriceMatrixItemType;
}

export interface buyGiftCardPageQuery_productBySlug_connectionPriceMatrixes_priceMatrix {
  __typename: "PriceMatrix";
  lastStepInfinite: boolean;
  matrix: buyGiftCardPageQuery_productBySlug_connectionPriceMatrixes_priceMatrix_matrix[];
}

export interface buyGiftCardPageQuery_productBySlug_connectionPriceMatrixes {
  __typename: "ConnectionPriceMatrix";
  id: string;
  type: ConnectionPriceMatrixType;
  priceMatrix: buyGiftCardPageQuery_productBySlug_connectionPriceMatrixes_priceMatrix;
  vehicleCategory: VehicleCategory2;
}

export interface buyGiftCardPageQuery_productBySlug {
  __typename: "Product";
  id: string;
  /**
   * Localised product description
   */
  descriptionText: string | null;
  name: string | null;
  price: CORE_Money | null;
  extendedRentalPeriodCharge: CORE_Money | null;
  tripStartCharge: CORE_Money | null;
  rentalMeterType: RentalMeterType | null;
  campaign: string | null;
  slug: buyGiftCardPageQuery_productBySlug_slug | null;
  connectionPriceMatrixes: buyGiftCardPageQuery_productBySlug_connectionPriceMatrixes[] | null;
}

export interface buyGiftCardPageQuery_buyGiftCardPage {
  __typename: "BuyGiftCardPage";
  title: string;
  heading: string | null;
  emailCaption: string | null;
}

export interface buyGiftCardPageQuery {
  products: buyGiftCardPageQuery_products[] | null;
  productBySlug: buyGiftCardPageQuery_productBySlug | null;
  buyGiftCardPage: buyGiftCardPageQuery_buyGiftCardPage | null;
}

export interface buyGiftCardPageQueryVariables {
  systemId?: string | null;
  locale?: string | null;
  productSlug: string;
  fetchProduct: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: placeAnonymousValueCodeOrderMutation
// ====================================================

export interface placeAnonymousValueCodeOrderMutation_placeAnonymousValueCodeOrder_Order {
  __typename: "Order";
  /**
   * Order ID
   */
  id: string;
  /**
   * Order token, external ID, to use publicly in URLs
   */
  token: string;
}

export interface placeAnonymousValueCodeOrderMutation_placeAnonymousValueCodeOrder_AnonymousValueCodeOrderError {
  __typename: "AnonymousValueCodeOrderError";
  errorCode: AnonymousValueCodeOrderErrorCode;
}

export type placeAnonymousValueCodeOrderMutation_placeAnonymousValueCodeOrder = placeAnonymousValueCodeOrderMutation_placeAnonymousValueCodeOrder_Order | placeAnonymousValueCodeOrderMutation_placeAnonymousValueCodeOrder_AnonymousValueCodeOrderError;

export interface placeAnonymousValueCodeOrderMutation {
  /**
   * Creates value code order without user
   * Order can be payed by payOrder mutation using only checkout flow
   * systemId header is required
   * Authentication is not required
   */
  placeAnonymousValueCodeOrder: placeAnonymousValueCodeOrderMutation_placeAnonymousValueCodeOrder;
}

export interface placeAnonymousValueCodeOrderMutationVariables {
  orderLines: OrderLineInput[];
  customerEmail: string;
  receiptEmail?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: placeValueCodeOrderMutation
// ====================================================

export interface placeValueCodeOrderMutation_placeValueCodeOrder_Order {
  __typename: "Order";
  /**
   * Order ID
   */
  id: string;
  /**
   * Order token, external ID, to use publicly in URLs
   */
  token: string;
}

export interface placeValueCodeOrderMutation_placeValueCodeOrder_ValueCodeOrderError {
  __typename: "ValueCodeOrderError";
  errorCode: ValueCodeOrderErrorCode;
}

export type placeValueCodeOrderMutation_placeValueCodeOrder = placeValueCodeOrderMutation_placeValueCodeOrder_Order | placeValueCodeOrderMutation_placeValueCodeOrder_ValueCodeOrderError;

export interface placeValueCodeOrderMutation {
  /**
   * Creates value code order for current user
   * Order can be paid by payOrder mutation using checkout and charge flows
   * User token is required
   */
  placeValueCodeOrder: placeValueCodeOrderMutation_placeValueCodeOrder;
}

export interface placeValueCodeOrderMutationVariables {
  orderLines: OrderLineInput[];
  customerEmail: string;
  receiptEmail?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: payGiftCardOrderCheckoutMutation
// ====================================================

export interface payGiftCardOrderCheckoutMutation_payOrderCheckout_CheckoutSessionStripe {
  __typename: "CheckoutSessionStripe";
  /**
   * sessionId can be used to redirect the user directly using Stripe JS SDK
   */
  sessionId: string;
  publishableKey: string;
}

export interface payGiftCardOrderCheckoutMutation_payOrderCheckout_CheckoutSessionPayex {
  __typename: "CheckoutSessionPayex";
  redirectUrl: string;
}

export type payGiftCardOrderCheckoutMutation_payOrderCheckout = payGiftCardOrderCheckoutMutation_payOrderCheckout_CheckoutSessionStripe | payGiftCardOrderCheckoutMutation_payOrderCheckout_CheckoutSessionPayex;

export interface payGiftCardOrderCheckoutMutation {
  /**
   * User has to provide payment details on payment provider's page,
   * and is then redirected to public-web /_internal/stripe-checkout-redirect,
   * where the order status must be confirmed using payOrderCheckoutCompletion.
   * If user's token was provided payment method will be saved after sucessful payment.
   */
  payOrderCheckout: payGiftCardOrderCheckoutMutation_payOrderCheckout;
}

export interface payGiftCardOrderCheckoutMutationVariables {
  orderId: string;
  paths: PaymentPaths;
  paymentMethodType?: PaymentMethodType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: payGiftCardOrderChargeMutation
// ====================================================

export interface payGiftCardOrderChargeMutation_payOrderCharge_ChargeCompletedSubscription_subscription_product {
  __typename: "Product";
  id: string;
  name: string | null;
  durationMethod: ProductDurationMethod | null;
  durationMins: number | null;
  rentalMeterType: RentalMeterType | null;
  price: CORE_Money | null;
  validTo: CORE_IsoDateTime | null;
  renewOptMethod: ProductOptMethod;
  /**
   * Can the product be renewed?
   */
  isRenewable: boolean;
}

export interface payGiftCardOrderChargeMutation_payOrderCharge_ChargeCompletedSubscription_subscription {
  __typename: "ProductSubscription";
  id: string;
  product: payGiftCardOrderChargeMutation_payOrderCharge_ChargeCompletedSubscription_subscription_product | null;
  state: ProductSubscriptionStatus | null;
  validFrom: CORE_IsoDateTime | null;
  validTo: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
}

export interface payGiftCardOrderChargeMutation_payOrderCharge_ChargeCompletedSubscription {
  __typename: "ChargeCompletedSubscription";
  subscription: payGiftCardOrderChargeMutation_payOrderCharge_ChargeCompletedSubscription_subscription;
}

export interface payGiftCardOrderChargeMutation_payOrderCharge_ConfirmationSessionStripe_paymentDocument_InvoiceTrip {
  __typename: "InvoiceTrip";
  token: string;
}

export interface payGiftCardOrderChargeMutation_payOrderCharge_ConfirmationSessionStripe_paymentDocument_InvoiceFine {
  __typename: "InvoiceFine";
  token: string;
}

export interface payGiftCardOrderChargeMutation_payOrderCharge_ConfirmationSessionStripe_paymentDocument_Order {
  __typename: "Order";
  /**
   * Order token, external ID, to use publicly in URLs
   */
  token: string;
}

export type payGiftCardOrderChargeMutation_payOrderCharge_ConfirmationSessionStripe_paymentDocument = payGiftCardOrderChargeMutation_payOrderCharge_ConfirmationSessionStripe_paymentDocument_InvoiceTrip | payGiftCardOrderChargeMutation_payOrderCharge_ConfirmationSessionStripe_paymentDocument_InvoiceFine | payGiftCardOrderChargeMutation_payOrderCharge_ConfirmationSessionStripe_paymentDocument_Order;

export interface payGiftCardOrderChargeMutation_payOrderCharge_ConfirmationSessionStripe {
  __typename: "ConfirmationSessionStripe";
  /**
   * clientSecret can be used to show the confirmationdirectly using Stripe JS SDK
   */
  clientSecret: string;
  /**
   * paymentMethodRef can be used to show the confirmationdirectly using Stripe JS SDK
   */
  paymentMethodRef: string;
  /**
   * publishableKey can be used to show the confirmationdirectly using Stripe JS SDK
   */
  publishableKey: string;
  paymentDocument: payGiftCardOrderChargeMutation_payOrderCharge_ConfirmationSessionStripe_paymentDocument;
}

export interface payGiftCardOrderChargeMutation_payOrderCharge_ChargeCompleted_paymentDocument_InvoiceTrip {
  __typename: "InvoiceTrip";
  token: string;
}

export interface payGiftCardOrderChargeMutation_payOrderCharge_ChargeCompleted_paymentDocument_InvoiceFine {
  __typename: "InvoiceFine";
  token: string;
}

export interface payGiftCardOrderChargeMutation_payOrderCharge_ChargeCompleted_paymentDocument_Order {
  __typename: "Order";
  /**
   * Order token, external ID, to use publicly in URLs
   */
  token: string;
}

export type payGiftCardOrderChargeMutation_payOrderCharge_ChargeCompleted_paymentDocument = payGiftCardOrderChargeMutation_payOrderCharge_ChargeCompleted_paymentDocument_InvoiceTrip | payGiftCardOrderChargeMutation_payOrderCharge_ChargeCompleted_paymentDocument_InvoiceFine | payGiftCardOrderChargeMutation_payOrderCharge_ChargeCompleted_paymentDocument_Order;

export interface payGiftCardOrderChargeMutation_payOrderCharge_ChargeCompleted {
  __typename: "ChargeCompleted";
  paymentDocument: payGiftCardOrderChargeMutation_payOrderCharge_ChargeCompleted_paymentDocument;
}

export interface payGiftCardOrderChargeMutation_payOrderCharge_PaymentIntentError {
  __typename: "PaymentIntentError";
  description: string;
  errorCode: PaymentIntentErrorCode;
  message: string;
}

export type payGiftCardOrderChargeMutation_payOrderCharge = payGiftCardOrderChargeMutation_payOrderCharge_ChargeCompletedSubscription | payGiftCardOrderChargeMutation_payOrderCharge_ConfirmationSessionStripe | payGiftCardOrderChargeMutation_payOrderCharge_ChargeCompleted | payGiftCardOrderChargeMutation_payOrderCharge_PaymentIntentError;

export interface payGiftCardOrderChargeMutation {
  /**
   * Payment provider charges provided by user payment method, no actions required from user, except 3ds secure checking
   * If payment method id is not provided, default payment method will be used
   * User token is required
   */
  payOrderCharge: payGiftCardOrderChargeMutation_payOrderCharge;
}

export interface payGiftCardOrderChargeMutationVariables {
  orderId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: productBySlugQuery
// ====================================================

export interface productBySlugQuery_productBySlug_image_metadata_dimensions {
  __typename: "SanityImageMetaDataDimensions";
  width: number | null;
  height: number | null;
}

export interface productBySlugQuery_productBySlug_image_metadata {
  __typename: "SanityImageMetaData";
  dimensions: productBySlugQuery_productBySlug_image_metadata_dimensions | null;
}

export interface productBySlugQuery_productBySlug_image {
  __typename: "SanityImage";
  url: string | null;
  metadata: productBySlugQuery_productBySlug_image_metadata | null;
}

export interface productBySlugQuery_productBySlug_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface productBySlugQuery_productBySlug_connectionPriceMatrixes_priceMatrix_matrix {
  __typename: "PriceMatrixItem";
  minutes: number;
  price: CORE_Money;
  quantity: number;
  type: PriceMatrixItemType;
}

export interface productBySlugQuery_productBySlug_connectionPriceMatrixes_priceMatrix {
  __typename: "PriceMatrix";
  lastStepInfinite: boolean;
  matrix: productBySlugQuery_productBySlug_connectionPriceMatrixes_priceMatrix_matrix[];
}

export interface productBySlugQuery_productBySlug_connectionPriceMatrixes {
  __typename: "ConnectionPriceMatrix";
  id: string;
  type: ConnectionPriceMatrixType;
  priceMatrix: productBySlugQuery_productBySlug_connectionPriceMatrixes_priceMatrix;
  vehicleCategory: VehicleCategory2;
}

export interface productBySlugQuery_productBySlug_partners {
  __typename: "Partner";
  id: PartnerId;
}

export interface productBySlugQuery_productBySlug {
  __typename: "Product";
  id: string;
  /**
   * Localised product description
   */
  descriptionText: string | null;
  name: string | null;
  image: productBySlugQuery_productBySlug_image | null;
  slug: productBySlugQuery_productBySlug_slug | null;
  price: CORE_Money | null;
  extendedRentalPeriodCharge: CORE_Money | null;
  tripStartCharge: CORE_Money | null;
  rentalMeterType: RentalMeterType | null;
  campaign: string | null;
  purchasable: boolean | null;
  purchasableFrom: CORE_IsoDateTime | null;
  purchasableTo: CORE_IsoDateTime | null;
  connectionPriceMatrixes: productBySlugQuery_productBySlug_connectionPriceMatrixes[] | null;
  renewOptMethod: ProductOptMethod;
  durationMethod: ProductDurationMethod | null;
  durationMins: number | null;
  /**
   * Partners of product
   */
  partners: productBySlugQuery_productBySlug_partners[] | null;
}

export interface productBySlugQuery_system_nextSeason {
  __typename: "Season";
  startDate: CORE_IsoDateTime | null;
}

export interface productBySlugQuery_system {
  __typename: "System";
  id: string;
  inSeason: boolean | null;
  nextSeason: productBySlugQuery_system_nextSeason | null;
  /**
   * If enabled new app users should pay a fee
   */
  registrationFeeEnabled: boolean;
}

export interface productBySlugQuery {
  productBySlug: productBySlugQuery_productBySlug | null;
  system: productBySlugQuery_system | null;
}

export interface productBySlugQueryVariables {
  slug: string;
  productImageOptions?: SanityImageOptions | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: placeSubscriptionOrderMutation
// ====================================================

export interface placeSubscriptionOrderMutation_placeSubscriptionOrder_Order_discount_partner {
  __typename: "Partner";
  id: PartnerId;
  name: string;
}

export interface placeSubscriptionOrderMutation_placeSubscriptionOrder_Order_discount_discountAmount_RelativeDiscountAmount {
  __typename: "RelativeDiscountAmount";
  amount: CORE_PositiveFloat;
}

export interface placeSubscriptionOrderMutation_placeSubscriptionOrder_Order_discount_discountAmount_AbsoluteDiscountAmount {
  __typename: "AbsoluteDiscountAmount";
  amount: CORE_PositiveFloat;
}

export type placeSubscriptionOrderMutation_placeSubscriptionOrder_Order_discount_discountAmount = placeSubscriptionOrderMutation_placeSubscriptionOrder_Order_discount_discountAmount_RelativeDiscountAmount | placeSubscriptionOrderMutation_placeSubscriptionOrder_Order_discount_discountAmount_AbsoluteDiscountAmount;

export interface placeSubscriptionOrderMutation_placeSubscriptionOrder_Order_discount {
  __typename: "Discount";
  /**
   * Defines localized discount campaign name
   */
  name: string;
  /**
   * Defines how many products will be discounted
   */
  quantityDiscounted: CORE_PositiveInt;
  /**
   * Price with discount
   */
  totalAmountDiscounted: CORE_Money;
  /**
   * Defines associated with discount partner
   */
  partner: placeSubscriptionOrderMutation_placeSubscriptionOrder_Order_discount_partner | null;
  /**
   * Defines discount amount
   */
  discountAmount: placeSubscriptionOrderMutation_placeSubscriptionOrder_Order_discount_discountAmount;
}

export interface placeSubscriptionOrderMutation_placeSubscriptionOrder_Order_orderLines {
  __typename: "OrderLine";
  /**
   * Order line ID
   */
  id: string;
  /**
   * Order line type
   */
  type: OrderLineType | null;
  /**
   * Product amount
   */
  amount: CORE_Money;
}

export interface placeSubscriptionOrderMutation_placeSubscriptionOrder_Order {
  __typename: "Order";
  /**
   * Order ID
   */
  id: string;
  /**
   * Order status
   */
  status: OrderStatus | null;
  /**
   * Order type
   */
  type: OrderType;
  /**
   * Total order amount
   */
  amount: CORE_Money | null;
  /**
   * Total order vat amount
   */
  vatAmount: CORE_Money | null;
  /**
   * Average vat rate
   */
  vatRate: number | null;
  /**
   * Currency for the order amount
   */
  currency: string;
  /**
   * Order token, external ID, to use publicly in URLs
   */
  token: string;
  /**
   * Completed at datetime
   */
  completedAt: CORE_IsoDateTime | null;
  /**
   * Applied discount
   */
  discount: placeSubscriptionOrderMutation_placeSubscriptionOrder_Order_discount | null;
  /**
   * Order lines
   */
  orderLines: (placeSubscriptionOrderMutation_placeSubscriptionOrder_Order_orderLines | null)[];
}

export interface placeSubscriptionOrderMutation_placeSubscriptionOrder_SubscriptionOrderError {
  __typename: "SubscriptionOrderError";
  description: string;
  errorCode: SubscriptionOrderErrorCode;
  message: string;
}

export type placeSubscriptionOrderMutation_placeSubscriptionOrder = placeSubscriptionOrderMutation_placeSubscriptionOrder_Order | placeSubscriptionOrderMutation_placeSubscriptionOrder_SubscriptionOrderError;

export interface placeSubscriptionOrderMutation {
  /**
   * Creates subscription order for current user if user does not have active subscription
   * Order can be paid by payOrder mutation using checkout and charge flows
   * User token is required
   */
  placeSubscriptionOrder: placeSubscriptionOrderMutation_placeSubscriptionOrder;
}

export interface placeSubscriptionOrderMutationVariables {
  productId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: payOrderCheckoutMutation
// ====================================================

export interface payOrderCheckoutMutation_payOrderCheckout_CheckoutSessionStripe {
  __typename: "CheckoutSessionStripe";
  /**
   * sessionId can be used to redirect the user directly using Stripe JS SDK
   */
  sessionId: string;
  publishableKey: string;
}

export interface payOrderCheckoutMutation_payOrderCheckout_CheckoutSessionPayex {
  __typename: "CheckoutSessionPayex";
  redirectUrl: string;
}

export type payOrderCheckoutMutation_payOrderCheckout = payOrderCheckoutMutation_payOrderCheckout_CheckoutSessionStripe | payOrderCheckoutMutation_payOrderCheckout_CheckoutSessionPayex;

export interface payOrderCheckoutMutation {
  /**
   * User has to provide payment details on payment provider's page,
   * and is then redirected to public-web /_internal/stripe-checkout-redirect,
   * where the order status must be confirmed using payOrderCheckoutCompletion.
   * If user's token was provided payment method will be saved after sucessful payment.
   */
  payOrderCheckout: payOrderCheckoutMutation_payOrderCheckout;
}

export interface payOrderCheckoutMutationVariables {
  orderId: string;
  paths: PaymentPaths;
  autoRenewProduct: boolean;
  paymentMethodType?: PaymentMethodType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: payOrderChargeMutation
// ====================================================

export interface payOrderChargeMutation_payOrderCharge_ChargeCompletedSubscription_subscription_product {
  __typename: "Product";
  id: string;
  name: string | null;
  durationMethod: ProductDurationMethod | null;
  durationMins: number | null;
  rentalMeterType: RentalMeterType | null;
  price: CORE_Money | null;
  validTo: CORE_IsoDateTime | null;
  renewOptMethod: ProductOptMethod;
  /**
   * Can the product be renewed?
   */
  isRenewable: boolean;
}

export interface payOrderChargeMutation_payOrderCharge_ChargeCompletedSubscription_subscription {
  __typename: "ProductSubscription";
  id: string;
  product: payOrderChargeMutation_payOrderCharge_ChargeCompletedSubscription_subscription_product | null;
  state: ProductSubscriptionStatus | null;
  validFrom: CORE_IsoDateTime | null;
  validTo: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
}

export interface payOrderChargeMutation_payOrderCharge_ChargeCompletedSubscription {
  __typename: "ChargeCompletedSubscription";
  subscription: payOrderChargeMutation_payOrderCharge_ChargeCompletedSubscription_subscription;
}

export interface payOrderChargeMutation_payOrderCharge_ConfirmationSessionStripe_paymentDocument_InvoiceTrip {
  __typename: "InvoiceTrip";
  token: string;
}

export interface payOrderChargeMutation_payOrderCharge_ConfirmationSessionStripe_paymentDocument_InvoiceFine {
  __typename: "InvoiceFine";
  token: string;
}

export interface payOrderChargeMutation_payOrderCharge_ConfirmationSessionStripe_paymentDocument_Order {
  __typename: "Order";
  /**
   * Order token, external ID, to use publicly in URLs
   */
  token: string;
}

export type payOrderChargeMutation_payOrderCharge_ConfirmationSessionStripe_paymentDocument = payOrderChargeMutation_payOrderCharge_ConfirmationSessionStripe_paymentDocument_InvoiceTrip | payOrderChargeMutation_payOrderCharge_ConfirmationSessionStripe_paymentDocument_InvoiceFine | payOrderChargeMutation_payOrderCharge_ConfirmationSessionStripe_paymentDocument_Order;

export interface payOrderChargeMutation_payOrderCharge_ConfirmationSessionStripe {
  __typename: "ConfirmationSessionStripe";
  /**
   * clientSecret can be used to show the confirmationdirectly using Stripe JS SDK
   */
  clientSecret: string;
  /**
   * paymentMethodRef can be used to show the confirmationdirectly using Stripe JS SDK
   */
  paymentMethodRef: string;
  /**
   * publishableKey can be used to show the confirmationdirectly using Stripe JS SDK
   */
  publishableKey: string;
  paymentDocument: payOrderChargeMutation_payOrderCharge_ConfirmationSessionStripe_paymentDocument;
}

export interface payOrderChargeMutation_payOrderCharge_ChargeCompleted_paymentDocument_InvoiceTrip {
  __typename: "InvoiceTrip";
  token: string;
}

export interface payOrderChargeMutation_payOrderCharge_ChargeCompleted_paymentDocument_InvoiceFine {
  __typename: "InvoiceFine";
  token: string;
}

export interface payOrderChargeMutation_payOrderCharge_ChargeCompleted_paymentDocument_Order {
  __typename: "Order";
  /**
   * Order token, external ID, to use publicly in URLs
   */
  token: string;
}

export type payOrderChargeMutation_payOrderCharge_ChargeCompleted_paymentDocument = payOrderChargeMutation_payOrderCharge_ChargeCompleted_paymentDocument_InvoiceTrip | payOrderChargeMutation_payOrderCharge_ChargeCompleted_paymentDocument_InvoiceFine | payOrderChargeMutation_payOrderCharge_ChargeCompleted_paymentDocument_Order;

export interface payOrderChargeMutation_payOrderCharge_ChargeCompleted {
  __typename: "ChargeCompleted";
  paymentDocument: payOrderChargeMutation_payOrderCharge_ChargeCompleted_paymentDocument;
}

export interface payOrderChargeMutation_payOrderCharge_PaymentIntentError {
  __typename: "PaymentIntentError";
  description: string;
  errorCode: PaymentIntentErrorCode;
  message: string;
}

export type payOrderChargeMutation_payOrderCharge = payOrderChargeMutation_payOrderCharge_ChargeCompletedSubscription | payOrderChargeMutation_payOrderCharge_ConfirmationSessionStripe | payOrderChargeMutation_payOrderCharge_ChargeCompleted | payOrderChargeMutation_payOrderCharge_PaymentIntentError;

export interface payOrderChargeMutation {
  /**
   * Payment provider charges provided by user payment method, no actions required from user, except 3ds secure checking
   * If payment method id is not provided, default payment method will be used
   * User token is required
   */
  payOrderCharge: payOrderChargeMutation_payOrderCharge;
}

export interface payOrderChargeMutationVariables {
  orderId: string;
  autoRenewProduct: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: applyDiscountCodeMutation
// ====================================================

export interface applyDiscountCodeMutation_applyDiscountCode_Order_discount_partner {
  __typename: "Partner";
  id: PartnerId;
  name: string;
}

export interface applyDiscountCodeMutation_applyDiscountCode_Order_discount_discountAmount_RelativeDiscountAmount {
  __typename: "RelativeDiscountAmount";
  amount: CORE_PositiveFloat;
}

export interface applyDiscountCodeMutation_applyDiscountCode_Order_discount_discountAmount_AbsoluteDiscountAmount {
  __typename: "AbsoluteDiscountAmount";
  amount: CORE_PositiveFloat;
}

export type applyDiscountCodeMutation_applyDiscountCode_Order_discount_discountAmount = applyDiscountCodeMutation_applyDiscountCode_Order_discount_discountAmount_RelativeDiscountAmount | applyDiscountCodeMutation_applyDiscountCode_Order_discount_discountAmount_AbsoluteDiscountAmount;

export interface applyDiscountCodeMutation_applyDiscountCode_Order_discount {
  __typename: "Discount";
  /**
   * Defines localized discount campaign name
   */
  name: string;
  /**
   * Defines how many products will be discounted
   */
  quantityDiscounted: CORE_PositiveInt;
  /**
   * Price with discount
   */
  totalAmountDiscounted: CORE_Money;
  /**
   * Defines associated with discount partner
   */
  partner: applyDiscountCodeMutation_applyDiscountCode_Order_discount_partner | null;
  /**
   * Defines discount amount
   */
  discountAmount: applyDiscountCodeMutation_applyDiscountCode_Order_discount_discountAmount;
}

export interface applyDiscountCodeMutation_applyDiscountCode_Order {
  __typename: "Order";
  /**
   * Order ID
   */
  id: string;
  /**
   * Order status
   */
  status: OrderStatus | null;
  /**
   * Order type
   */
  type: OrderType;
  /**
   * Total order amount
   */
  amount: CORE_Money | null;
  /**
   * Total order vat amount
   */
  vatAmount: CORE_Money | null;
  /**
   * Average vat rate
   */
  vatRate: number | null;
  /**
   * Currency for the order amount
   */
  currency: string;
  /**
   * Order token, external ID, to use publicly in URLs
   */
  token: string;
  /**
   * Completed at datetime
   */
  completedAt: CORE_IsoDateTime | null;
  /**
   * Applied discount
   */
  discount: applyDiscountCodeMutation_applyDiscountCode_Order_discount | null;
}

export interface applyDiscountCodeMutation_applyDiscountCode_DiscountCodeError {
  __typename: "DiscountCodeError";
  errorCode: DiscountCodeErrorCode;
  /**
   * Localised error message
   */
  message: string;
}

export type applyDiscountCodeMutation_applyDiscountCode = applyDiscountCodeMutation_applyDiscountCode_Order | applyDiscountCodeMutation_applyDiscountCode_DiscountCodeError;

export interface applyDiscountCodeMutation {
  /**
   * Applies discount code to existing unpaid order if possible
   * Mutates order
   * Applying discount code to discounted order replaces existing one
   */
  applyDiscountCode: applyDiscountCodeMutation_applyDiscountCode;
}

export interface applyDiscountCodeMutationVariables {
  orderId: string;
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: revokeDiscountCodeMutation
// ====================================================

export interface revokeDiscountCodeMutation_revokeDiscountCode_discount_partner {
  __typename: "Partner";
  id: PartnerId;
  name: string;
}

export interface revokeDiscountCodeMutation_revokeDiscountCode_discount_discountAmount_RelativeDiscountAmount {
  __typename: "RelativeDiscountAmount";
  amount: CORE_PositiveFloat;
}

export interface revokeDiscountCodeMutation_revokeDiscountCode_discount_discountAmount_AbsoluteDiscountAmount {
  __typename: "AbsoluteDiscountAmount";
  amount: CORE_PositiveFloat;
}

export type revokeDiscountCodeMutation_revokeDiscountCode_discount_discountAmount = revokeDiscountCodeMutation_revokeDiscountCode_discount_discountAmount_RelativeDiscountAmount | revokeDiscountCodeMutation_revokeDiscountCode_discount_discountAmount_AbsoluteDiscountAmount;

export interface revokeDiscountCodeMutation_revokeDiscountCode_discount {
  __typename: "Discount";
  /**
   * Defines localized discount campaign name
   */
  name: string;
  /**
   * Defines how many products will be discounted
   */
  quantityDiscounted: CORE_PositiveInt;
  /**
   * Price with discount
   */
  totalAmountDiscounted: CORE_Money;
  /**
   * Defines associated with discount partner
   */
  partner: revokeDiscountCodeMutation_revokeDiscountCode_discount_partner | null;
  /**
   * Defines discount amount
   */
  discountAmount: revokeDiscountCodeMutation_revokeDiscountCode_discount_discountAmount;
}

export interface revokeDiscountCodeMutation_revokeDiscountCode {
  __typename: "Order";
  /**
   * Order ID
   */
  id: string;
  /**
   * Order status
   */
  status: OrderStatus | null;
  /**
   * Order type
   */
  type: OrderType;
  /**
   * Total order amount
   */
  amount: CORE_Money | null;
  /**
   * Total order vat amount
   */
  vatAmount: CORE_Money | null;
  /**
   * Average vat rate
   */
  vatRate: number | null;
  /**
   * Currency for the order amount
   */
  currency: string;
  /**
   * Order token, external ID, to use publicly in URLs
   */
  token: string;
  /**
   * Completed at datetime
   */
  completedAt: CORE_IsoDateTime | null;
  /**
   * Applied discount
   */
  discount: revokeDiscountCodeMutation_revokeDiscountCode_discount | null;
}

export interface revokeDiscountCodeMutation {
  /**
   * Revokes applied discount code and returns updated order
   * If there is another eligible discount, it will be applied
   */
  revokeDiscountCode: revokeDiscountCodeMutation_revokeDiscountCode;
}

export interface revokeDiscountCodeMutationVariables {
  orderId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: featureModuleTripDuration
// ====================================================

export interface featureModuleTripDuration_systemStats {
  __typename: "SystemStats";
  /**
   * The median duration of trips this year in seconds
   */
  medianDurationThisYear: number;
}

export interface featureModuleTripDuration {
  /**
   * Statistics for the system
   */
  systemStats: featureModuleTripDuration_systemStats;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: dockGroupsQuery
// ====================================================

export interface dockGroupsQuery_dockGroups_coord {
  __typename: "Coord";
  lat: number | null;
  lng: number | null;
}

export interface dockGroupsQuery_dockGroups_availabilityInfo {
  __typename: "DockGroupAvailability";
  availableDocks: number | null;
  availableVehicles: number | null;
}

export interface dockGroupsQuery_dockGroups {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  subTitle: string | null;
  state: DockGroupState;
  coord: dockGroupsQuery_dockGroups_coord | null;
  availabilityInfo: dockGroupsQuery_dockGroups_availabilityInfo | null;
}

export interface dockGroupsQuery_system_coords {
  __typename: "Coord";
  lat: number | null;
  lng: number | null;
}

export interface dockGroupsQuery_system {
  __typename: "System";
  id: string;
  GBFS: boolean | null;
  publicTripHistory: boolean | null;
  coords: (dockGroupsQuery_system_coords | null)[] | null;
}

export interface dockGroupsQuery {
  dockGroups: (dockGroupsQuery_dockGroups | null)[];
  system: dockGroupsQuery_system | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: tripsTodayQuery
// ====================================================

export interface tripsTodayQuery_systemStats {
  __typename: "SystemStats";
  /**
   * The number of trips today
   */
  tripsToday: number;
}

export interface tripsTodayQuery {
  /**
   * Statistics for the system
   */
  systemStats: tripsTodayQuery_systemStats;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: invoiceQuery
// ====================================================

export interface invoiceQuery_invoice_InvoiceFine_items_InvoiceItemAdjustment {
  __typename: "InvoiceItemAdjustment";
  id: string;
  amount: CORE_Money;
}

export interface invoiceQuery_invoice_InvoiceFine_items_InvoiceItemTrip_trip_startDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
}

export interface invoiceQuery_invoice_InvoiceFine_items_InvoiceItemTrip_trip_startDock {
  __typename: "Dock";
  dockGroup: invoiceQuery_invoice_InvoiceFine_items_InvoiceItemTrip_trip_startDock_dockGroup;
}

export interface invoiceQuery_invoice_InvoiceFine_items_InvoiceItemTrip_trip_endDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
}

export interface invoiceQuery_invoice_InvoiceFine_items_InvoiceItemTrip_trip_endDock {
  __typename: "Dock";
  dockGroup: invoiceQuery_invoice_InvoiceFine_items_InvoiceItemTrip_trip_endDock_dockGroup;
}

export interface invoiceQuery_invoice_InvoiceFine_items_InvoiceItemTrip_trip {
  __typename: "Trip";
  id: string;
  startedAt: CORE_IsoDateTime | null;
  endedAt: CORE_IsoDateTime | null;
  duration: number | null;
  startDock: invoiceQuery_invoice_InvoiceFine_items_InvoiceItemTrip_trip_startDock | null;
  endDock: invoiceQuery_invoice_InvoiceFine_items_InvoiceItemTrip_trip_endDock | null;
}

export interface invoiceQuery_invoice_InvoiceFine_items_InvoiceItemTrip {
  __typename: "InvoiceItemTrip";
  id: string;
  amount: CORE_Money;
  trip: invoiceQuery_invoice_InvoiceFine_items_InvoiceItemTrip_trip;
}

export type invoiceQuery_invoice_InvoiceFine_items = invoiceQuery_invoice_InvoiceFine_items_InvoiceItemAdjustment | invoiceQuery_invoice_InvoiceFine_items_InvoiceItemTrip;

export interface invoiceQuery_invoice_InvoiceFine_completedPaymentTransaction_paymentMethod_Blik {
  __typename: "Blik" | "AcssDebit" | "Affirm" | "AfterpayClearpay" | "Alipay" | "AuBecsDebit" | "BacsDebit" | "Bancontact" | "Cashapp" | "CustomerBalance" | "Eps" | "Fpx" | "Giropay" | "Grabpay" | "Ideal" | "Klarna" | "Konbini" | "Link" | "Oxxo" | "P24" | "Paynow" | "Paypal" | "Pix" | "Promptpay" | "RevolutPay" | "SepaDebit" | "Sofort" | "Swish" | "UsBankAccount" | "WechatPay" | "Zip";
}

export interface invoiceQuery_invoice_InvoiceFine_completedPaymentTransaction_paymentMethod_Card {
  __typename: "Card";
  expMonth: number;
  expYear: number;
  last4: string;
  brand: CardBrand;
  /**
   * Represents expiration date in MM/YY format
   */
  expiresAt: string;
  expired: boolean;
  /**
   * Display version of brand, eg american_express => American Express
   */
  displayBrand: string;
}

export type invoiceQuery_invoice_InvoiceFine_completedPaymentTransaction_paymentMethod = invoiceQuery_invoice_InvoiceFine_completedPaymentTransaction_paymentMethod_Blik | invoiceQuery_invoice_InvoiceFine_completedPaymentTransaction_paymentMethod_Card;

export interface invoiceQuery_invoice_InvoiceFine_completedPaymentTransaction {
  __typename: "PaymentTransaction";
  /**
   * Transaction number at provider
   */
  transactionNumber: string | null;
  paymentMethod: invoiceQuery_invoice_InvoiceFine_completedPaymentTransaction_paymentMethod | null;
}

export interface invoiceQuery_invoice_InvoiceFine {
  __typename: "InvoiceFine";
  id: string;
  systemId: string;
  status: InvoiceStatus;
  currency: string;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime;
  vatRate: number;
  description: string;
  items: invoiceQuery_invoice_InvoiceFine_items[] | null;
  periodStart: CORE_IsoDateTime;
  periodEnd: CORE_IsoDateTime | null;
  totalVat: CORE_Money;
  total: CORE_Money;
  paidAt: CORE_IsoDateTime | null;
  completedPaymentTransaction: invoiceQuery_invoice_InvoiceFine_completedPaymentTransaction | null;
}

export interface invoiceQuery_invoice_InvoiceTrip_items_InvoiceItemAdjustment {
  __typename: "InvoiceItemAdjustment";
  id: string;
  amount: CORE_Money;
}

export interface invoiceQuery_invoice_InvoiceTrip_items_InvoiceItemTrip_trip_startDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
}

export interface invoiceQuery_invoice_InvoiceTrip_items_InvoiceItemTrip_trip_startDock {
  __typename: "Dock";
  dockGroup: invoiceQuery_invoice_InvoiceTrip_items_InvoiceItemTrip_trip_startDock_dockGroup;
}

export interface invoiceQuery_invoice_InvoiceTrip_items_InvoiceItemTrip_trip_endDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
}

export interface invoiceQuery_invoice_InvoiceTrip_items_InvoiceItemTrip_trip_endDock {
  __typename: "Dock";
  dockGroup: invoiceQuery_invoice_InvoiceTrip_items_InvoiceItemTrip_trip_endDock_dockGroup;
}

export interface invoiceQuery_invoice_InvoiceTrip_items_InvoiceItemTrip_trip {
  __typename: "Trip";
  id: string;
  startedAt: CORE_IsoDateTime | null;
  endedAt: CORE_IsoDateTime | null;
  duration: number | null;
  startDock: invoiceQuery_invoice_InvoiceTrip_items_InvoiceItemTrip_trip_startDock | null;
  endDock: invoiceQuery_invoice_InvoiceTrip_items_InvoiceItemTrip_trip_endDock | null;
}

export interface invoiceQuery_invoice_InvoiceTrip_items_InvoiceItemTrip {
  __typename: "InvoiceItemTrip";
  id: string;
  amount: CORE_Money;
  trip: invoiceQuery_invoice_InvoiceTrip_items_InvoiceItemTrip_trip;
}

export type invoiceQuery_invoice_InvoiceTrip_items = invoiceQuery_invoice_InvoiceTrip_items_InvoiceItemAdjustment | invoiceQuery_invoice_InvoiceTrip_items_InvoiceItemTrip;

export interface invoiceQuery_invoice_InvoiceTrip_completedPaymentTransaction_paymentMethod_Blik {
  __typename: "Blik" | "AcssDebit" | "Affirm" | "AfterpayClearpay" | "Alipay" | "AuBecsDebit" | "BacsDebit" | "Bancontact" | "Cashapp" | "CustomerBalance" | "Eps" | "Fpx" | "Giropay" | "Grabpay" | "Ideal" | "Klarna" | "Konbini" | "Link" | "Oxxo" | "P24" | "Paynow" | "Paypal" | "Pix" | "Promptpay" | "RevolutPay" | "SepaDebit" | "Sofort" | "Swish" | "UsBankAccount" | "WechatPay" | "Zip";
}

export interface invoiceQuery_invoice_InvoiceTrip_completedPaymentTransaction_paymentMethod_Card {
  __typename: "Card";
  expMonth: number;
  expYear: number;
  last4: string;
  brand: CardBrand;
  /**
   * Represents expiration date in MM/YY format
   */
  expiresAt: string;
  expired: boolean;
  /**
   * Display version of brand, eg american_express => American Express
   */
  displayBrand: string;
}

export type invoiceQuery_invoice_InvoiceTrip_completedPaymentTransaction_paymentMethod = invoiceQuery_invoice_InvoiceTrip_completedPaymentTransaction_paymentMethod_Blik | invoiceQuery_invoice_InvoiceTrip_completedPaymentTransaction_paymentMethod_Card;

export interface invoiceQuery_invoice_InvoiceTrip_completedPaymentTransaction {
  __typename: "PaymentTransaction";
  /**
   * Transaction number at provider
   */
  transactionNumber: string | null;
  paymentMethod: invoiceQuery_invoice_InvoiceTrip_completedPaymentTransaction_paymentMethod | null;
}

export interface invoiceQuery_invoice_InvoiceTrip {
  __typename: "InvoiceTrip";
  id: string;
  systemId: string;
  status: InvoiceStatus;
  currency: string;
  createdAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime;
  vatRate: number;
  description: string;
  items: invoiceQuery_invoice_InvoiceTrip_items[] | null;
  periodStart: CORE_IsoDateTime;
  periodEnd: CORE_IsoDateTime | null;
  totalVat: CORE_Money;
  total: CORE_Money;
  paidAt: CORE_IsoDateTime | null;
  /**
   * Once total limit is exceeded an invoice transits to open status
   */
  totalLimit: CORE_Money;
  completedPaymentTransaction: invoiceQuery_invoice_InvoiceTrip_completedPaymentTransaction | null;
}

export type invoiceQuery_invoice = invoiceQuery_invoice_InvoiceFine | invoiceQuery_invoice_InvoiceTrip;

export interface invoiceQuery {
  invoice: invoiceQuery_invoice | null;
}

export interface invoiceQueryVariables {
  token: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: connectObosMembershipMutation
// ====================================================

export interface connectObosMembershipMutation_connectObosMembership {
  __typename: "User";
  id: string;
}

export interface connectObosMembershipMutation {
  connectObosMembership: connectObosMembershipMutation_connectObosMembership | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: onboardingQuery
// ====================================================

export interface onboardingQuery_buyProductPage_termsOfConditionItems {
  __typename: "SanityTermOfConditionSummaryItem";
  _key: string;
  title: string | null;
  body: string | null;
  type: SanityTermOfConditionType | null;
}

export interface onboardingQuery_buyProductPage {
  __typename: "BuyProductPage";
  termsOfConditionItems: (onboardingQuery_buyProductPage_termsOfConditionItems | null)[] | null;
}

export interface onboardingQuery {
  buyProductPage: onboardingQuery_buyProductPage | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: openDataQuery
// ====================================================

export interface openDataQuery_openData_showcases_image {
  __typename: "SanityImage";
  url: string | null;
}

export interface openDataQuery_openData_showcases {
  __typename: "OpenDataShowCase";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
  image: openDataQuery_openData_showcases_image | null;
  url: string | null;
}

export interface openDataQuery_openData {
  __typename: "SanityOpenData";
  title: string | null;
  description: CORE_JSON | null;
  licence: CORE_JSON | null;
  showcaseDescription: CORE_JSON | null;
  showcases: (openDataQuery_openData_showcases | null)[] | null;
  realtimeTitle: string | null;
  realtimeDescription: CORE_JSON | null;
  historicalTitle: string | null;
  historicalDescription: CORE_JSON | null;
  appIntegrationTitle: string | null;
  appIntegrationDescription: CORE_JSON | null;
}

export interface openDataQuery_system {
  __typename: "System";
  id: string;
  GBFS: boolean | null;
  publicTripHistory: boolean | null;
  openDate: CORE_IsoDateTime | null;
  /**
   * App deep linking prefix. If null, system does not support deep linking to apps
   */
  appDeepLinkingPrefix: string | null;
}

export interface openDataQuery {
  openData: openDataQuery_openData | null;
  system: openDataQuery_system | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: applyPartnerDiscountMutation
// ====================================================

export interface applyPartnerDiscountMutation_applyPartnerDiscount_Order_discount_partner {
  __typename: "Partner";
  id: PartnerId;
  name: string;
}

export interface applyPartnerDiscountMutation_applyPartnerDiscount_Order_discount_discountAmount_RelativeDiscountAmount {
  __typename: "RelativeDiscountAmount";
  amount: CORE_PositiveFloat;
}

export interface applyPartnerDiscountMutation_applyPartnerDiscount_Order_discount_discountAmount_AbsoluteDiscountAmount {
  __typename: "AbsoluteDiscountAmount";
  amount: CORE_PositiveFloat;
}

export type applyPartnerDiscountMutation_applyPartnerDiscount_Order_discount_discountAmount = applyPartnerDiscountMutation_applyPartnerDiscount_Order_discount_discountAmount_RelativeDiscountAmount | applyPartnerDiscountMutation_applyPartnerDiscount_Order_discount_discountAmount_AbsoluteDiscountAmount;

export interface applyPartnerDiscountMutation_applyPartnerDiscount_Order_discount {
  __typename: "Discount";
  /**
   * Defines localized discount campaign name
   */
  name: string;
  /**
   * Defines how many products will be discounted
   */
  quantityDiscounted: CORE_PositiveInt;
  /**
   * Price with discount
   */
  totalAmountDiscounted: CORE_Money;
  /**
   * Defines associated with discount partner
   */
  partner: applyPartnerDiscountMutation_applyPartnerDiscount_Order_discount_partner | null;
  /**
   * Defines discount amount
   */
  discountAmount: applyPartnerDiscountMutation_applyPartnerDiscount_Order_discount_discountAmount;
}

export interface applyPartnerDiscountMutation_applyPartnerDiscount_Order {
  __typename: "Order";
  /**
   * Order ID
   */
  id: string;
  /**
   * Order status
   */
  status: OrderStatus | null;
  /**
   * Order type
   */
  type: OrderType;
  /**
   * Total order amount
   */
  amount: CORE_Money | null;
  /**
   * Total order vat amount
   */
  vatAmount: CORE_Money | null;
  /**
   * Average vat rate
   */
  vatRate: number | null;
  /**
   * Currency for the order amount
   */
  currency: string;
  /**
   * Order token, external ID, to use publicly in URLs
   */
  token: string;
  /**
   * Completed at datetime
   */
  completedAt: CORE_IsoDateTime | null;
  /**
   * Applied discount
   */
  discount: applyPartnerDiscountMutation_applyPartnerDiscount_Order_discount | null;
}

export interface applyPartnerDiscountMutation_applyPartnerDiscount_PartnerDiscountError {
  __typename: "PartnerDiscountError";
  errorCode: PartnerDiscountErrorCode;
  message: string;
}

export type applyPartnerDiscountMutation_applyPartnerDiscount = applyPartnerDiscountMutation_applyPartnerDiscount_Order | applyPartnerDiscountMutation_applyPartnerDiscount_PartnerDiscountError;

export interface applyPartnerDiscountMutation {
  /**
   * Attaches partner account to current authenticated user and applies discount to order if it's applicable
   */
  applyPartnerDiscount: applyPartnerDiscountMutation_applyPartnerDiscount;
}

export interface applyPartnerDiscountMutationVariables {
  orderId: string;
  partnerId: PartnerId;
  milanATMInput?: PartnerMilanATMInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addPaymentMethodMutation
// ====================================================

export interface addPaymentMethodMutation_addPaymentMethod_SetupSessionStripe {
  __typename: "SetupSessionStripe";
  /**
   * sessionId can be used to redirect the user directly using Stripe JS SDK
   */
  sessionId: string;
  publishableKey: string;
}

export interface addPaymentMethodMutation_addPaymentMethod_SetupSessionPayex {
  __typename: "SetupSessionPayex";
  redirectUrl: string;
}

export type addPaymentMethodMutation_addPaymentMethod = addPaymentMethodMutation_addPaymentMethod_SetupSessionStripe | addPaymentMethodMutation_addPaymentMethod_SetupSessionPayex;

export interface addPaymentMethodMutation {
  /**
   * Initiates payment method setup flow, same as Checkout but without charging
   * User token is required
   */
  addPaymentMethod: addPaymentMethodMutation_addPaymentMethod;
}

export interface addPaymentMethodMutationVariables {
  paths: PaymentPaths;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removePaymentMethodMutation
// ====================================================

export interface removePaymentMethodMutation_removePaymentMethod {
  __typename: "ObjectDeleted" | "PaymentMethodError";
}

export interface removePaymentMethodMutation {
  /**
   * Removes user's payment method
   * User token is required
   */
  removePaymentMethod: removePaymentMethodMutation_removePaymentMethod;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: requestUserRfidPairingByVehicleQrCodeMutation
// ====================================================

export interface requestUserRfidPairingByVehicleQrCodeMutation {
  requestUserRfidPairingByVehicleQrCode: CORE_IsoDateTime | null;
}

export interface requestUserRfidPairingByVehicleQrCodeMutationVariables {
  qrCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cancelUserRfidPairingMutation
// ====================================================

export interface cancelUserRfidPairingMutation {
  cancelUserRfidPairing: boolean;
}

export interface cancelUserRfidPairingMutationVariables {
  qrCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: profilePageQuery
// ====================================================

export interface profilePageQuery_profilePage {
  __typename: "SanityProfilePage";
  title: string;
  heading: string | null;
}

export interface profilePageQuery_systemPartners_partners {
  __typename: "Partner";
  id: PartnerId;
}

export interface profilePageQuery_systemPartners {
  __typename: "System";
  id: string;
  /**
   * Integrated system's partners
   */
  partners: profilePageQuery_systemPartners_partners[];
}

export interface profilePageQuery {
  profilePage: profilePageQuery_profilePage | null;
  systemPartners: profilePageQuery_systemPartners | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: profileCurrentUserQuery
// ====================================================

export interface profileCurrentUserQuery_currentUser_partnerAccounts {
  __typename: "MilanATMCard";
  identificationNumber: number;
  isValid: boolean;
  partnerId: PartnerId;
  serialNumber: string;
}

export interface profileCurrentUserQuery_currentUser {
  __typename: "User";
  id: string;
  /**
   * Does the user have a subscription with a product that allows adding a ATM Partner card?
   */
  canAddAtmCard: boolean;
  partnerAccounts: profileCurrentUserQuery_currentUser_partnerAccounts[] | null;
}

export interface profileCurrentUserQuery {
  currentUser: profileCurrentUserQuery_currentUser | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: attachPartnerAccountMutation
// ====================================================

export interface attachPartnerAccountMutation_attachPartnerAccount_MilanATMCard {
  __typename: "MilanATMCard";
  identificationNumber: number;
  isValid: boolean;
  partnerId: PartnerId;
  serialNumber: string;
}

export interface attachPartnerAccountMutation_attachPartnerAccount_AttachPartnerAccountError {
  __typename: "AttachPartnerAccountError";
  errorCode: AttachPartnerAccountErrorCode;
  message: string;
}

export type attachPartnerAccountMutation_attachPartnerAccount = attachPartnerAccountMutation_attachPartnerAccount_MilanATMCard | attachPartnerAccountMutation_attachPartnerAccount_AttachPartnerAccountError;

export interface attachPartnerAccountMutation {
  /**
   * Attaches partner account to current authenticated user
   * Details must contain necessary data to attach account ";" separated
   */
  attachPartnerAccount: attachPartnerAccountMutation_attachPartnerAccount;
}

export interface attachPartnerAccountMutationVariables {
  partnerId: PartnerId;
  milanATMInput?: PartnerMilanATMInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: detachPartnerAccountMutation
// ====================================================

export interface detachPartnerAccountMutation_detachPartnerAccount_DetachPartnerAccountError {
  __typename: "DetachPartnerAccountError";
  errorCode: DetachPartnerAccountErrorCode;
  message: string;
}

export interface detachPartnerAccountMutation_detachPartnerAccount_PartnerAccountDetached {
  __typename: "PartnerAccountDetached";
  partnerId: PartnerId;
}

export type detachPartnerAccountMutation_detachPartnerAccount = detachPartnerAccountMutation_detachPartnerAccount_DetachPartnerAccountError | detachPartnerAccountMutation_detachPartnerAccount_PartnerAccountDetached;

export interface detachPartnerAccountMutation {
  /**
   * Detaches partner account from current authenticated user
   */
  detachPartnerAccount: detachPartnerAccountMutation_detachPartnerAccount;
}

export interface detachPartnerAccountMutationVariables {
  partnerId: PartnerId;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateProductSubscriptionMutation
// ====================================================

export interface updateProductSubscriptionMutation_updateProductRenewal_User_productRenewals_ProductRenewalPending_product {
  __typename: "Product";
  id: string;
}

export interface updateProductSubscriptionMutation_updateProductRenewal_User_productRenewals_ProductRenewalPending {
  __typename: "ProductRenewalPending";
  product: updateProductSubscriptionMutation_updateProductRenewal_User_productRenewals_ProductRenewalPending_product;
}

export interface updateProductSubscriptionMutation_updateProductRenewal_User_productRenewals_ProductRenewalScheduled_product {
  __typename: "Product";
  id: string;
  price: CORE_Money | null;
}

export interface updateProductSubscriptionMutation_updateProductRenewal_User_productRenewals_ProductRenewalScheduled {
  __typename: "ProductRenewalScheduled";
  product: updateProductSubscriptionMutation_updateProductRenewal_User_productRenewals_ProductRenewalScheduled_product;
  scheduledAt: CORE_IsoDateTime;
}

export interface updateProductSubscriptionMutation_updateProductRenewal_User_productRenewals_ProductRenewalFailed_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface updateProductSubscriptionMutation_updateProductRenewal_User_productRenewals_ProductRenewalFailed {
  __typename: "ProductRenewalFailed";
  product: updateProductSubscriptionMutation_updateProductRenewal_User_productRenewals_ProductRenewalFailed_product;
}

export type updateProductSubscriptionMutation_updateProductRenewal_User_productRenewals = updateProductSubscriptionMutation_updateProductRenewal_User_productRenewals_ProductRenewalPending | updateProductSubscriptionMutation_updateProductRenewal_User_productRenewals_ProductRenewalScheduled | updateProductSubscriptionMutation_updateProductRenewal_User_productRenewals_ProductRenewalFailed;

export interface updateProductSubscriptionMutation_updateProductRenewal_User {
  __typename: "User";
  id: string;
  /**
   * List of product renewals
   */
  productRenewals: updateProductSubscriptionMutation_updateProductRenewal_User_productRenewals[];
}

export interface updateProductSubscriptionMutation_updateProductRenewal_UpdateProductRenewalError {
  __typename: "UpdateProductRenewalError";
  errorCode: UpdateProductRenewalErrorCode;
}

export type updateProductSubscriptionMutation_updateProductRenewal = updateProductSubscriptionMutation_updateProductRenewal_User | updateProductSubscriptionMutation_updateProductRenewal_UpdateProductRenewalError;

export interface updateProductSubscriptionMutation {
  /**
   * Update product renewal
   */
  updateProductRenewal: updateProductSubscriptionMutation_updateProductRenewal;
}

export interface updateProductSubscriptionMutationVariables {
  productId: string;
  isEnabled: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: placeWalletDepositOrder
// ====================================================

export interface placeWalletDepositOrder_placeWalletDepositOrder_Order_orderLines {
  __typename: "OrderLine";
  /**
   * Quantity of product or price matrix items
   */
  quantity: number;
  /**
   * Product amount
   */
  amount: CORE_Money;
  /**
   * Total amount is computed by quantity times amount
   */
  totalAmount: CORE_Money;
  /**
   * Vat rate for the product
   */
  vatRate: number | null;
  /**
   * Order line type
   */
  type: OrderLineType | null;
  /**
   * Product ID
   */
  productId: string | null;
}

export interface placeWalletDepositOrder_placeWalletDepositOrder_Order_walletTransactions {
  __typename: "WalletTransaction";
  id: string;
}

export interface placeWalletDepositOrder_placeWalletDepositOrder_Order {
  __typename: "Order";
  /**
   * Order ID
   */
  id: string;
  /**
   * Order status
   */
  status: OrderStatus | null;
  /**
   * Order type
   */
  type: OrderType;
  /**
   * Total order amount
   */
  amount: CORE_Money | null;
  /**
   * Total order vat amount
   */
  vatAmount: CORE_Money | null;
  /**
   * Average vat rate
   */
  vatRate: number | null;
  /**
   * Currency for the order amount
   */
  currency: string;
  /**
   * Source for the purchase
   */
  source: string | null;
  /**
   * Completed at datetime
   */
  completedAt: CORE_IsoDateTime | null;
  /**
   * Order token, external ID, to use publicly in URLs
   */
  token: string;
  /**
   * Order lines
   */
  orderLines: (placeWalletDepositOrder_placeWalletDepositOrder_Order_orderLines | null)[];
  /**
   * Linked Wallet transactions; not empty if this is a "wallet deposit" order, or if the order was paid via Wallet balance
   */
  walletTransactions: placeWalletDepositOrder_placeWalletDepositOrder_Order_walletTransactions[];
}

export interface placeWalletDepositOrder_placeWalletDepositOrder_WalletDepositOrderError {
  __typename: "WalletDepositOrderError";
  message: string;
  errorCode: WalletDepositOrderErrorCode;
  description: string;
}

export type placeWalletDepositOrder_placeWalletDepositOrder = placeWalletDepositOrder_placeWalletDepositOrder_Order | placeWalletDepositOrder_placeWalletDepositOrder_WalletDepositOrderError;

export interface placeWalletDepositOrder {
  /**
   * Creates a "wallet deposit" order to top-up current user Wallet
   * Order can be paid by payOrder mutation using checkout and charge flows
   * User token is required
   */
  placeWalletDepositOrder: placeWalletDepositOrder_placeWalletDepositOrder;
}

export interface placeWalletDepositOrderVariables {
  amount: CORE_Money;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: disableSubscription
// ====================================================

export interface disableSubscription_disableSubscription {
  __typename: "ProductSubscription";
  id: string;
  state: ProductSubscriptionStatus | null;
}

export interface disableSubscription {
  disableSubscription: disableSubscription_disableSubscription | null;
}

export interface disableSubscriptionVariables {
  subscriptionId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: invoicesQuery
// ====================================================

export interface invoicesQuery_currentUser_paymentMethod {
  __typename: "Card" | "Blik" | "AcssDebit" | "Affirm" | "AfterpayClearpay" | "Alipay" | "AuBecsDebit" | "BacsDebit" | "Bancontact" | "Cashapp" | "CustomerBalance" | "Eps" | "Fpx" | "Giropay" | "Grabpay" | "Ideal" | "Klarna" | "Konbini" | "Link" | "Oxxo" | "P24" | "Paynow" | "Paypal" | "Pix" | "Promptpay" | "RevolutPay" | "SepaDebit" | "Sofort" | "Swish" | "UsBankAccount" | "WechatPay" | "Zip";
}

export interface invoicesQuery_currentUser_paginatedInvoices_paginationInfo {
  __typename: "OffsetPaginationInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  totalRowCount: number;
}

export interface invoicesQuery_currentUser_paginatedInvoices_rows_InvoiceFine {
  __typename: "InvoiceFine";
  token: string;
}

export interface invoicesQuery_currentUser_paginatedInvoices_rows_InvoiceTrip_items_InvoiceItemAdjustment {
  __typename: "InvoiceItemAdjustment";
}

export interface invoicesQuery_currentUser_paginatedInvoices_rows_InvoiceTrip_items_InvoiceItemTrip {
  __typename: "InvoiceItemTrip";
  id: string;
}

export type invoicesQuery_currentUser_paginatedInvoices_rows_InvoiceTrip_items = invoicesQuery_currentUser_paginatedInvoices_rows_InvoiceTrip_items_InvoiceItemAdjustment | invoicesQuery_currentUser_paginatedInvoices_rows_InvoiceTrip_items_InvoiceItemTrip;

export interface invoicesQuery_currentUser_paginatedInvoices_rows_InvoiceTrip {
  __typename: "InvoiceTrip";
  token: string;
  id: string;
  systemId: string;
  status: InvoiceStatus;
  currency: string;
  createdAt: CORE_IsoDateTime;
  paidAt: CORE_IsoDateTime | null;
  periodStart: CORE_IsoDateTime;
  periodEnd: CORE_IsoDateTime;
  payable: boolean;
  total: CORE_Money;
  /**
   * Once total limit is exceeded an invoice transits to open status
   */
  totalLimit: CORE_Money;
  items: invoicesQuery_currentUser_paginatedInvoices_rows_InvoiceTrip_items[] | null;
}

export type invoicesQuery_currentUser_paginatedInvoices_rows = invoicesQuery_currentUser_paginatedInvoices_rows_InvoiceFine | invoicesQuery_currentUser_paginatedInvoices_rows_InvoiceTrip;

export interface invoicesQuery_currentUser_paginatedInvoices {
  __typename: "PaginatedInvoices";
  paginationInfo: invoicesQuery_currentUser_paginatedInvoices_paginationInfo;
  rows: invoicesQuery_currentUser_paginatedInvoices_rows[];
}

export interface invoicesQuery_currentUser {
  __typename: "User";
  id: string;
  paymentMethod: invoicesQuery_currentUser_paymentMethod | null;
  /**
   * All user's invoices
   */
  paginatedInvoices: invoicesQuery_currentUser_paginatedInvoices;
}

export interface invoicesQuery {
  currentUser: invoicesQuery_currentUser | null;
}

export interface invoicesQueryVariables {
  limit?: number | null;
  offset?: number | null;
  orderBy?: InvoiceSortField | null;
  orderDirection?: OrderDirection | null;
  after?: CORE_IsoDateTime | null;
  before?: CORE_IsoDateTime | null;
  statuses?: InvoiceStatus[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: payInvoice
// ====================================================

export interface payInvoice_payInvoiceCharge_ChargeCompletedSubscription_subscription_product {
  __typename: "Product";
  id: string;
  name: string | null;
  durationMethod: ProductDurationMethod | null;
  durationMins: number | null;
  rentalMeterType: RentalMeterType | null;
  price: CORE_Money | null;
  validTo: CORE_IsoDateTime | null;
  renewOptMethod: ProductOptMethod;
  /**
   * Can the product be renewed?
   */
  isRenewable: boolean;
}

export interface payInvoice_payInvoiceCharge_ChargeCompletedSubscription_subscription {
  __typename: "ProductSubscription";
  id: string;
  product: payInvoice_payInvoiceCharge_ChargeCompletedSubscription_subscription_product | null;
  state: ProductSubscriptionStatus | null;
  validFrom: CORE_IsoDateTime | null;
  validTo: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
}

export interface payInvoice_payInvoiceCharge_ChargeCompletedSubscription {
  __typename: "ChargeCompletedSubscription";
  subscription: payInvoice_payInvoiceCharge_ChargeCompletedSubscription_subscription;
}

export interface payInvoice_payInvoiceCharge_ConfirmationSessionStripe_paymentDocument_InvoiceTrip {
  __typename: "InvoiceTrip";
  token: string;
}

export interface payInvoice_payInvoiceCharge_ConfirmationSessionStripe_paymentDocument_InvoiceFine {
  __typename: "InvoiceFine";
  token: string;
}

export interface payInvoice_payInvoiceCharge_ConfirmationSessionStripe_paymentDocument_Order {
  __typename: "Order";
  /**
   * Order token, external ID, to use publicly in URLs
   */
  token: string;
}

export type payInvoice_payInvoiceCharge_ConfirmationSessionStripe_paymentDocument = payInvoice_payInvoiceCharge_ConfirmationSessionStripe_paymentDocument_InvoiceTrip | payInvoice_payInvoiceCharge_ConfirmationSessionStripe_paymentDocument_InvoiceFine | payInvoice_payInvoiceCharge_ConfirmationSessionStripe_paymentDocument_Order;

export interface payInvoice_payInvoiceCharge_ConfirmationSessionStripe {
  __typename: "ConfirmationSessionStripe";
  /**
   * clientSecret can be used to show the confirmationdirectly using Stripe JS SDK
   */
  clientSecret: string;
  /**
   * paymentMethodRef can be used to show the confirmationdirectly using Stripe JS SDK
   */
  paymentMethodRef: string;
  /**
   * publishableKey can be used to show the confirmationdirectly using Stripe JS SDK
   */
  publishableKey: string;
  paymentDocument: payInvoice_payInvoiceCharge_ConfirmationSessionStripe_paymentDocument;
}

export interface payInvoice_payInvoiceCharge_ChargeCompleted_paymentDocument_InvoiceFine {
  __typename: "InvoiceFine" | "Order";
}

export interface payInvoice_payInvoiceCharge_ChargeCompleted_paymentDocument_InvoiceTrip {
  __typename: "InvoiceTrip";
  token: string;
}

export type payInvoice_payInvoiceCharge_ChargeCompleted_paymentDocument = payInvoice_payInvoiceCharge_ChargeCompleted_paymentDocument_InvoiceFine | payInvoice_payInvoiceCharge_ChargeCompleted_paymentDocument_InvoiceTrip;

export interface payInvoice_payInvoiceCharge_ChargeCompleted {
  __typename: "ChargeCompleted";
  paymentDocument: payInvoice_payInvoiceCharge_ChargeCompleted_paymentDocument;
}

export interface payInvoice_payInvoiceCharge_PaymentIntentError {
  __typename: "PaymentIntentError";
  description: string;
  errorCode: PaymentIntentErrorCode;
  message: string;
}

export type payInvoice_payInvoiceCharge = payInvoice_payInvoiceCharge_ChargeCompletedSubscription | payInvoice_payInvoiceCharge_ConfirmationSessionStripe | payInvoice_payInvoiceCharge_ChargeCompleted | payInvoice_payInvoiceCharge_PaymentIntentError;

export interface payInvoice {
  payInvoiceCharge: payInvoice_payInvoiceCharge;
}

export interface payInvoiceVariables {
  invoiceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: penaltiesQuery
// ====================================================

export interface penaltiesQuery_currentUser_currentSubscription_penalties_trip_startDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  title: string | null;
}

export interface penaltiesQuery_currentUser_currentSubscription_penalties_trip_startDock {
  __typename: "Dock";
  id: string;
  dockGroup: penaltiesQuery_currentUser_currentSubscription_penalties_trip_startDock_dockGroup;
}

export interface penaltiesQuery_currentUser_currentSubscription_penalties_trip_endDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  title: string | null;
}

export interface penaltiesQuery_currentUser_currentSubscription_penalties_trip_endDock {
  __typename: "Dock";
  id: string;
  dockGroup: penaltiesQuery_currentUser_currentSubscription_penalties_trip_endDock_dockGroup;
}

export interface penaltiesQuery_currentUser_currentSubscription_penalties_trip {
  __typename: "Trip";
  id: string;
  startDock: penaltiesQuery_currentUser_currentSubscription_penalties_trip_startDock | null;
  endDock: penaltiesQuery_currentUser_currentSubscription_penalties_trip_endDock | null;
  startedAt: CORE_IsoDateTime | null;
  endedAt: CORE_IsoDateTime | null;
  duration: number | null;
}

export interface penaltiesQuery_currentUser_currentSubscription_penalties {
  __typename: "Penalty";
  id: string;
  value: number | null;
  createdAt: CORE_IsoDateTime | null;
  trip: penaltiesQuery_currentUser_currentSubscription_penalties_trip | null;
  resolvedAt: CORE_IsoDateTime | null;
}

export interface penaltiesQuery_currentUser_currentSubscription {
  __typename: "ProductSubscription";
  id: string;
  penalties: (penaltiesQuery_currentUser_currentSubscription_penalties | null)[] | null;
}

export interface penaltiesQuery_currentUser {
  __typename: "User";
  id: string;
  penaltyCount: number | null;
  currentSubscription: penaltiesQuery_currentUser_currentSubscription | null;
}

export interface penaltiesQuery {
  currentUser: penaltiesQuery_currentUser | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updatePinCodeMutation
// ====================================================

export interface updatePinCodeMutation {
  updatePinCode: boolean | null;
}

export interface updatePinCodeMutationVariables {
  pinCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resetPinCodeMutation
// ====================================================

export interface resetPinCodeMutation {
  resetPinCode: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: receiptsQuery
// ====================================================

export interface receiptsQuery_currentUser_completedOrders_orderLines_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface receiptsQuery_currentUser_completedOrders_orderLines {
  __typename: "OrderLine";
  /**
   * Order line ID
   */
  id: string;
  /**
   * Order line type
   */
  type: OrderLineType | null;
  /**
   * Product amount
   */
  amount: CORE_Money;
  /**
   * The product for the order line
   */
  product: receiptsQuery_currentUser_completedOrders_orderLines_product | null;
}

export interface receiptsQuery_currentUser_completedOrders {
  __typename: "Order";
  /**
   * Order ID
   */
  id: string;
  /**
   * Total order amount
   */
  amount: CORE_Money | null;
  /**
   * Completed at datetime
   */
  completedAt: CORE_IsoDateTime | null;
  /**
   * Order token, external ID, to use publicly in URLs
   */
  token: string;
  /**
   * Order type
   */
  type: OrderType;
  /**
   * Order lines
   */
  orderLines: (receiptsQuery_currentUser_completedOrders_orderLines | null)[];
}

export interface receiptsQuery_currentUser {
  __typename: "User";
  id: string;
  completedOrders: (receiptsQuery_currentUser_completedOrders | null)[] | null;
}

export interface receiptsQuery {
  currentUser: receiptsQuery_currentUser | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: singleTripQuery
// ====================================================

export interface singleTripQuery_trip_route {
  __typename: "LineStringObject";
  type: GeoJSONType;
  coordinates: CORE_Coordinates;
}

export interface singleTripQuery_trip_startDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  title: string | null;
}

export interface singleTripQuery_trip_startDock {
  __typename: "Dock";
  id: string;
  dockGroup: singleTripQuery_trip_startDock_dockGroup;
}

export interface singleTripQuery_trip_endDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  title: string | null;
}

export interface singleTripQuery_trip_endDock {
  __typename: "Dock";
  id: string;
  dockGroup: singleTripQuery_trip_endDock_dockGroup;
}

export interface singleTripQuery_trip {
  __typename: "Trip";
  id: string;
  startedAt: CORE_IsoDateTime | null;
  state: TripState;
  endedAt: CORE_IsoDateTime | null;
  duration: number | null;
  /**
   * Distance travelled in meters (if available)
   */
  distance: number | null;
  userRating: CORE_PositiveInt | null;
  route: singleTripQuery_trip_route | null;
  startDock: singleTripQuery_trip_startDock | null;
  endDock: singleTripQuery_trip_endDock | null;
}

export interface singleTripQuery {
  trip: singleTripQuery_trip | null;
}

export interface singleTripQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: tripQuery
// ====================================================

export interface tripQuery_trip_paymentDocument_InvoiceTrip {
  __typename: "InvoiceTrip" | "InvoiceFine";
}

export interface tripQuery_trip_paymentDocument_Order {
  __typename: "Order";
  /**
   * Total order amount
   */
  amount: CORE_Money | null;
  /**
   * Currency for the order amount
   */
  currency: string;
}

export type tripQuery_trip_paymentDocument = tripQuery_trip_paymentDocument_InvoiceTrip | tripQuery_trip_paymentDocument_Order;

export interface tripQuery_trip_startDock_dockGroup {
  __typename: "DockGroup";
  name: string | null;
  title: string | null;
}

export interface tripQuery_trip_startDock {
  __typename: "Dock";
  dockGroup: tripQuery_trip_startDock_dockGroup;
}

export interface tripQuery_trip_endDock_dockGroup {
  __typename: "DockGroup";
  name: string | null;
  title: string | null;
}

export interface tripQuery_trip_endDock {
  __typename: "Dock";
  dockGroup: tripQuery_trip_endDock_dockGroup;
}

export interface tripQuery_trip {
  __typename: "Trip";
  id: string;
  duration: number | null;
  /**
   * Number of penalties associated with this trip
   */
  penaltyCount: number;
  paymentDocument: tripQuery_trip_paymentDocument | null;
  startDock: tripQuery_trip_startDock | null;
  startedAt: CORE_IsoDateTime | null;
  endDock: tripQuery_trip_endDock | null;
  endedAt: CORE_IsoDateTime | null;
}

export interface tripQuery {
  trip: tripQuery_trip | null;
}

export interface tripQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: tripsQuery
// ====================================================

export interface tripsQuery_trips_startDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  title: string | null;
}

export interface tripsQuery_trips_startDock {
  __typename: "Dock";
  id: string;
  dockGroup: tripsQuery_trips_startDock_dockGroup;
}

export interface tripsQuery_trips_endDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  title: string | null;
}

export interface tripsQuery_trips_endDock {
  __typename: "Dock";
  id: string;
  dockGroup: tripsQuery_trips_endDock_dockGroup;
}

export interface tripsQuery_trips {
  __typename: "Trip";
  id: string;
  startedAt: CORE_IsoDateTime | null;
  state: TripState;
  endedAt: CORE_IsoDateTime | null;
  duration: number | null;
  /**
   * Distance travelled in meters (if available)
   */
  distance: number | null;
  userRating: CORE_PositiveInt | null;
  startDock: tripsQuery_trips_startDock | null;
  endDock: tripsQuery_trips_endDock | null;
}

export interface tripsQuery_personalSummary {
  __typename: "PersonalSummary";
  numberOfTrips: number | null;
  stationsVisited: number | null;
}

export interface tripsQuery {
  trips: (tripsQuery_trips | null)[] | null;
  personalSummary: tripsQuery_personalSummary | null;
}

export interface tripsQueryVariables {
  options?: QueryOptions | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: completedUserDataExportRequestSubscription
// ====================================================

export interface completedUserDataExportRequestSubscription_completedUserDataExportRequest {
  __typename: "UserDataExportRequest";
  id: string;
  createdAt: CORE_IsoDateTime;
  requestedAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime | null;
  exportedAt: CORE_IsoDateTime | null;
  expirationTimestamp: CORE_IsoDateTime | null;
  state: UserDataExportRequestState | null;
  retentionPolicy: UserDataExportRequestRetentionPolicy | null;
  format: UserDataExportRequestFormat | null;
}

export interface completedUserDataExportRequestSubscription {
  completedUserDataExportRequest: completedUserDataExportRequestSubscription_completedUserDataExportRequest | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userDataExportRequestsQuery
// ====================================================

export interface userDataExportRequestsQuery_userDataExportRequests {
  __typename: "UserDataExportRequest";
  id: string;
  createdAt: CORE_IsoDateTime;
  requestedAt: CORE_IsoDateTime;
  updatedAt: CORE_IsoDateTime | null;
  exportedAt: CORE_IsoDateTime | null;
  expirationTimestamp: CORE_IsoDateTime | null;
  state: UserDataExportRequestState | null;
  retentionPolicy: UserDataExportRequestRetentionPolicy | null;
  format: UserDataExportRequestFormat | null;
}

export interface userDataExportRequestsQuery {
  userDataExportRequests: (userDataExportRequestsQuery_userDataExportRequests | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createUserDataExportRequestMutation
// ====================================================

export interface createUserDataExportRequestMutation_createUserDataExportRequest {
  __typename: "UserDataExportRequest";
  id: string;
}

export interface createUserDataExportRequestMutation {
  createUserDataExportRequest: createUserDataExportRequestMutation_createUserDataExportRequest | null;
}

export interface createUserDataExportRequestMutationVariables {
  options?: UserDataExportRequestOptionsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userDataLinkQuery
// ====================================================

export interface userDataLinkQuery {
  userDataLink: string;
}

export interface userDataLinkQueryVariables {
  userDataExportRequestId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: orderQuery
// ====================================================

export interface orderQuery_order_valueCodes_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface orderQuery_order_valueCodes {
  __typename: "ValueCode";
  id: string | null;
  token: string;
  product: orderQuery_order_valueCodes_product | null;
}

export interface orderQuery_order_paymentTransactions {
  __typename: "PaymentTransaction";
  /**
   * Payment transaction ID
   */
  id: string;
  /**
   * Transaction number at provider
   */
  transactionNumber: string | null;
  /**
   * Payment transaction status
   */
  status: PaymentTransactionStatus;
}

export interface orderQuery_order_walletTransactions {
  __typename: "WalletTransaction";
  id: string;
  orderId: string;
  createdAt: CORE_IsoDateTime;
  type: WalletTransactionType;
  amount: CORE_Money;
}

export interface orderQuery_order_discount_discountAmount_RelativeDiscountAmount {
  __typename: "RelativeDiscountAmount";
  amount: CORE_PositiveFloat;
  absoluteAmount: CORE_Money;
}

export interface orderQuery_order_discount_discountAmount_AbsoluteDiscountAmount {
  __typename: "AbsoluteDiscountAmount";
  amount: CORE_PositiveFloat;
}

export type orderQuery_order_discount_discountAmount = orderQuery_order_discount_discountAmount_RelativeDiscountAmount | orderQuery_order_discount_discountAmount_AbsoluteDiscountAmount;

export interface orderQuery_order_discount {
  __typename: "Discount";
  /**
   * Defines discount amount
   */
  discountAmount: orderQuery_order_discount_discountAmount;
  /**
   * Price with discount
   */
  totalAmountDiscounted: CORE_Money;
  /**
   * Total vat amount with discount
   */
  totalVatAmountDiscounted: CORE_Money | null;
  /**
   * Represents total amount of discount in absolute values depending on
   * discounted items quantity
   */
  totalDiscountAmount: CORE_Money;
}

export interface orderQuery_order_orderLines_product {
  __typename: "Product";
  id: string;
  name: string | null;
  price: CORE_Money | null;
}

export interface orderQuery_order_orderLines_valueCodes {
  __typename: "ValueCode";
  id: string | null;
  code: string;
  redeemedAt: CORE_IsoDateTime | null;
}

export interface orderQuery_order_orderLines_discount_discountAmount_RelativeDiscountAmount {
  __typename: "RelativeDiscountAmount";
  amount: CORE_PositiveFloat;
  absoluteAmount: CORE_Money;
}

export interface orderQuery_order_orderLines_discount_discountAmount_AbsoluteDiscountAmount {
  __typename: "AbsoluteDiscountAmount";
  amount: CORE_PositiveFloat;
}

export type orderQuery_order_orderLines_discount_discountAmount = orderQuery_order_orderLines_discount_discountAmount_RelativeDiscountAmount | orderQuery_order_orderLines_discount_discountAmount_AbsoluteDiscountAmount;

export interface orderQuery_order_orderLines_discount {
  __typename: "Discount";
  /**
   * Defines discount amount
   */
  discountAmount: orderQuery_order_orderLines_discount_discountAmount;
  /**
   * Defines localized discount campaign name
   */
  name: string;
  /**
   * Defines how many products will be discounted
   */
  quantityDiscounted: CORE_PositiveInt;
  /**
   * Price with discount
   */
  totalAmountDiscounted: CORE_Money;
  /**
   * Represents total amount of discount in absolute values depending on
   * discounted items quantity
   */
  totalDiscountAmount: CORE_Money;
}

export interface orderQuery_order_orderLines {
  __typename: "OrderLine";
  /**
   * Order line ID
   */
  id: string;
  /**
   * Order line type
   */
  type: OrderLineType | null;
  /**
   * Quantity of product or price matrix items
   */
  quantity: number;
  /**
   * Product amount
   */
  amount: CORE_Money;
  /**
   * Total amount is computed by quantity times amount
   */
  totalAmount: CORE_Money;
  /**
   * Product amount without VAT
   */
  amountWithoutVat: CORE_Money;
  /**
   * Localised title for the order line
   * @deprecated(reason: "Use title")
   */
  orderLineTitle: string | null;
  /**
   * The product for the order line
   */
  product: orderQuery_order_orderLines_product | null;
  /**
   * Any value codes connected to the order line
   */
  valueCodes: (orderQuery_order_orderLines_valueCodes | null)[] | null;
  /**
   * Applied discount
   */
  discount: orderQuery_order_orderLines_discount | null;
}

export interface orderQuery_order_user {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface orderQuery_order_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface orderQuery_order_trip_startDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
}

export interface orderQuery_order_trip_startDock {
  __typename: "Dock";
  id: string;
  dockGroup: orderQuery_order_trip_startDock_dockGroup;
}

export interface orderQuery_order_trip_endDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
}

export interface orderQuery_order_trip_endDock {
  __typename: "Dock";
  id: string;
  dockGroup: orderQuery_order_trip_endDock_dockGroup;
}

export interface orderQuery_order_trip_startLocation_location {
  __typename: "Coord";
  lat: number | null;
  lng: number | null;
}

export interface orderQuery_order_trip_startLocation {
  __typename: "VehicleLocation";
  location: orderQuery_order_trip_startLocation_location;
  address: string | null;
}

export interface orderQuery_order_trip_endLocation_location {
  __typename: "Coord";
  lat: number | null;
  lng: number | null;
}

export interface orderQuery_order_trip_endLocation {
  __typename: "VehicleLocation";
  location: orderQuery_order_trip_endLocation_location;
  address: string | null;
}

export interface orderQuery_order_trip {
  __typename: "Trip";
  id: string;
  startedAt: CORE_IsoDateTime | null;
  endedAt: CORE_IsoDateTime | null;
  startDock: orderQuery_order_trip_startDock | null;
  endDock: orderQuery_order_trip_endDock | null;
  startLocation: orderQuery_order_trip_startLocation | null;
  endLocation: orderQuery_order_trip_endLocation | null;
  duration: number | null;
  /**
   * Included free time to the trip (not live updated), represent duration length of first free item from Price Matrix
   * so it's not affected by Trip Additional Minutes at all
   */
  freePeriodMins: number;
  /**
   * Current paid duration (live updated) of the trip
   */
  paidPeriodMins: number;
}

export interface orderQuery_order {
  __typename: "Order";
  /**
   * Order ID
   */
  id: string;
  /**
   * Total order amount
   */
  amount: CORE_Money | null;
  /**
   * Total order amount without VAT
   */
  amountWithoutVat: CORE_Money | null;
  /**
   * Currency for the order amount
   */
  currency: string;
  /**
   * Customer's email
   */
  customerEmail: string | null;
  /**
   * Total order vat amount
   */
  vatAmount: CORE_Money | null;
  /**
   * Order type
   */
  type: OrderType;
  /**
   * Completed at datetime
   */
  completedAt: CORE_IsoDateTime | null;
  /**
   * The masked credit card the order was processed with. Not exposed if the user is not logged in
   */
  creditCardMasked: string | null;
  /**
   * Value codes
   */
  valueCodes: (orderQuery_order_valueCodes | null)[] | null;
  /**
   * Payment transactions
   */
  paymentTransactions: orderQuery_order_paymentTransactions[];
  /**
   * Linked Wallet transactions; not empty if this is a "wallet deposit" order, or if the order was paid via Wallet balance
   */
  walletTransactions: orderQuery_order_walletTransactions[];
  /**
   * Applied discount
   */
  discount: orderQuery_order_discount | null;
  /**
   * Order lines
   */
  orderLines: (orderQuery_order_orderLines | null)[];
  /**
   * User
   */
  user: orderQuery_order_user | null;
  /**
   * B2B Company
   */
  company: orderQuery_order_company | null;
  /**
   * The trip connected to order. Applies for pay2go and extended_rental orders
   */
  trip: orderQuery_order_trip | null;
}

export interface orderQuery {
  order: orderQuery_order | null;
}

export interface orderQueryVariables {
  token: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: validateValueCodeQuery
// ====================================================

export interface validateValueCodeQuery_validateValueCode_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface validateValueCodeQuery_validateValueCode {
  __typename: "Product";
  id: string;
  slug: validateValueCodeQuery_validateValueCode_slug | null;
}

export interface validateValueCodeQuery {
  validateValueCode: validateValueCodeQuery_validateValueCode | null;
}

export interface validateValueCodeQueryVariables {
  valueCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: productQuery
// ====================================================

export interface productQuery_product_image_metadata_dimensions {
  __typename: "SanityImageMetaDataDimensions";
  width: number | null;
  height: number | null;
}

export interface productQuery_product_image_metadata {
  __typename: "SanityImageMetaData";
  dimensions: productQuery_product_image_metadata_dimensions | null;
}

export interface productQuery_product_image {
  __typename: "SanityImage";
  url: string | null;
  metadata: productQuery_product_image_metadata | null;
}

export interface productQuery_product_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface productQuery_product_connectionPriceMatrixes_priceMatrix_matrix {
  __typename: "PriceMatrixItem";
  minutes: number;
  price: CORE_Money;
  quantity: number;
  type: PriceMatrixItemType;
}

export interface productQuery_product_connectionPriceMatrixes_priceMatrix {
  __typename: "PriceMatrix";
  lastStepInfinite: boolean;
  matrix: productQuery_product_connectionPriceMatrixes_priceMatrix_matrix[];
}

export interface productQuery_product_connectionPriceMatrixes {
  __typename: "ConnectionPriceMatrix";
  id: string;
  type: ConnectionPriceMatrixType;
  priceMatrix: productQuery_product_connectionPriceMatrixes_priceMatrix;
  vehicleCategory: VehicleCategory2;
}

export interface productQuery_product_partners {
  __typename: "Partner";
  id: PartnerId;
}

export interface productQuery_product {
  __typename: "Product";
  id: string;
  /**
   * Localised product description
   */
  descriptionText: string | null;
  name: string | null;
  image: productQuery_product_image | null;
  slug: productQuery_product_slug | null;
  price: CORE_Money | null;
  extendedRentalPeriodCharge: CORE_Money | null;
  tripStartCharge: CORE_Money | null;
  rentalMeterType: RentalMeterType | null;
  campaign: string | null;
  purchasable: boolean | null;
  purchasableFrom: CORE_IsoDateTime | null;
  purchasableTo: CORE_IsoDateTime | null;
  connectionPriceMatrixes: productQuery_product_connectionPriceMatrixes[] | null;
  renewOptMethod: ProductOptMethod;
  durationMethod: ProductDurationMethod | null;
  durationMins: number | null;
  /**
   * Partners of product
   */
  partners: productQuery_product_partners[] | null;
}

export interface productQuery {
  product: productQuery_product | null;
}

export interface productQueryVariables {
  id: string;
  productImageOptions?: SanityImageOptions | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: redeemValueCodeExisitingUser
// ====================================================

export interface redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_currentSubscription_product {
  __typename: "Product";
  id: string;
  name: string | null;
  durationMethod: ProductDurationMethod | null;
  durationMins: number | null;
  rentalMeterType: RentalMeterType | null;
  price: CORE_Money | null;
  validTo: CORE_IsoDateTime | null;
  renewOptMethod: ProductOptMethod;
  /**
   * Can the product be renewed?
   */
  isRenewable: boolean;
}

export interface redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_currentSubscription {
  __typename: "ProductSubscription";
  id: string;
  product: redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_currentSubscription_product | null;
  state: ProductSubscriptionStatus | null;
  validFrom: CORE_IsoDateTime | null;
  validTo: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
}

export interface redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_parsedPhoneNumber {
  __typename: "ParsedPhoneNumber";
  countryCode: string;
  nationalNumber: string;
  formattedNumber: string;
}

export interface redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_paymentMethod_Blik {
  __typename: "Blik" | "AcssDebit" | "Affirm" | "AfterpayClearpay" | "Alipay" | "AuBecsDebit" | "BacsDebit" | "Bancontact" | "Cashapp" | "CustomerBalance" | "Eps" | "Fpx" | "Giropay" | "Grabpay" | "Ideal" | "Klarna" | "Konbini" | "Link" | "Oxxo" | "P24" | "Paynow" | "Paypal" | "Pix" | "Promptpay" | "RevolutPay" | "SepaDebit" | "Sofort" | "Swish" | "UsBankAccount" | "WechatPay" | "Zip";
}

export interface redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_paymentMethod_Card {
  __typename: "Card";
  id: string;
  /**
   * Represents expiration date in MM/YY format
   */
  expiresAt: string;
  expired: boolean;
  last4: string;
  brand: CardBrand;
  /**
   * Display version of brand, eg american_express => American Express
   */
  displayBrand: string;
  /**
   * Indicates whether can payment method be removed or not, due to debt or active trip
   */
  removable: boolean;
}

export type redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_paymentMethod = redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_paymentMethod_Blik | redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_paymentMethod_Card;

export interface redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_productRenewals_ProductRenewalPending_product {
  __typename: "Product";
  id: string;
}

export interface redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_productRenewals_ProductRenewalPending {
  __typename: "ProductRenewalPending";
  product: redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_productRenewals_ProductRenewalPending_product;
}

export interface redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_productRenewals_ProductRenewalScheduled_product {
  __typename: "Product";
  id: string;
  price: CORE_Money | null;
}

export interface redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_productRenewals_ProductRenewalScheduled {
  __typename: "ProductRenewalScheduled";
  product: redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_productRenewals_ProductRenewalScheduled_product;
  scheduledAt: CORE_IsoDateTime;
}

export interface redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_productRenewals_ProductRenewalFailed_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_productRenewals_ProductRenewalFailed {
  __typename: "ProductRenewalFailed";
  product: redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_productRenewals_ProductRenewalFailed_product;
}

export type redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_productRenewals = redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_productRenewals_ProductRenewalPending | redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_productRenewals_ProductRenewalScheduled | redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_productRenewals_ProductRenewalFailed;

export interface redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_wallet {
  __typename: "UserWallet";
  enabled: boolean;
  balance: CORE_Money;
  currency: string;
}

export interface redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_currentTrip {
  __typename: "Trip";
  id: string;
}

export interface redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user {
  __typename: "User";
  id: string;
  name: string | null;
  email: string | null;
  gender: Gender | null;
  birthYear: string | null;
  preferredLanguageCode: string | null;
  postalCode: string | null;
  havePinCode: boolean | null;
  currentSubscription: redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_currentSubscription | null;
  phoneNumber: string;
  parsedPhoneNumber: redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_parsedPhoneNumber;
  penaltyStatus: PenaltyStatus;
  agreedToReceiveEmail: boolean | null;
  agreedToTerms: boolean;
  agreedToReceiveExternalPromotions: boolean | null;
  agreedToRegistrationAgreementWithdrawal: boolean;
  agreedToSubscriptionAgreementWithdrawal: boolean;
  agreedToSendDataToThirdParties: boolean | null;
  paymentMethod: redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_paymentMethod | null;
  requiresAddingPaymentCard: boolean | null;
  hasUnpaidOrders: boolean | null;
  /**
   * List of product renewals
   */
  productRenewals: redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_productRenewals[];
  superpowers: boolean | null;
  rfid: string | null;
  wallet: redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_wallet | null;
  currentTrip: redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user_currentTrip | null;
}

export interface redeemValueCodeExisitingUser_redeemValueCodeExisitingUser {
  __typename: "RedeemValueCodeResponse";
  user: redeemValueCodeExisitingUser_redeemValueCodeExisitingUser_user;
}

export interface redeemValueCodeExisitingUser {
  redeemValueCodeExisitingUser: redeemValueCodeExisitingUser_redeemValueCodeExisitingUser;
}

export interface redeemValueCodeExisitingUserVariables {
  valueCode: string;
  signupConsent?: SignupConsent | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: stationMapQuery
// ====================================================

export interface stationMapQuery_stationMapPage {
  __typename: "SanityStationMapPage";
  title: string;
  heading: string | null;
}

export interface stationMapQuery_system_coords {
  __typename: "Coord";
  lat: number | null;
  lng: number | null;
}

export interface stationMapQuery_system {
  __typename: "System";
  id: string;
  inSeason: boolean | null;
  coords: (stationMapQuery_system_coords | null)[] | null;
}

export interface stationMapQuery {
  stationMapPage: stationMapQuery_stationMapPage | null;
  system: stationMapQuery_system | null;
}

export interface stationMapQueryVariables {
  systemId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: featureTogglesQuery
// ====================================================

export interface featureTogglesQuery_featureToggles {
  __typename: "FeatureToggles";
  freeFloatingEnabled: boolean | null;
}

export interface featureTogglesQuery {
  featureToggles: featureTogglesQuery_featureToggles | null;
}

export interface featureTogglesQueryVariables {
  systemId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DockGroups
// ====================================================

export interface DockGroups_dockGroups_availabilityInfo_availableVehicleCategories {
  __typename: "VehicleCategoryAvailability";
  category: VehicleCategory2;
  count: number;
}

export interface DockGroups_dockGroups_availabilityInfo {
  __typename: "DockGroupAvailability";
  availableVehicles: number | null;
  availableDocks: number | null;
  availableVirtualDocks: number | null;
  availablePhysicalDocks: number | null;
  availableVehicleCategories: DockGroups_dockGroups_availabilityInfo_availableVehicleCategories[] | null;
}

export interface DockGroups_dockGroups_coord {
  __typename: "Coord";
  lat: number | null;
  lng: number | null;
}

export interface DockGroups_dockGroups {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
  state: DockGroupState;
  subTitle: string | null;
  enabled: boolean;
  availabilityInfo: DockGroups_dockGroups_availabilityInfo | null;
  coord: DockGroups_dockGroups_coord | null;
}

export interface DockGroups {
  dockGroups: (DockGroups_dockGroups | null)[];
}

export interface DockGroupsVariables {
  systemId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FreeFloatingVehicles
// ====================================================

export interface FreeFloatingVehicles_freeFloatingVehicles_location_location {
  __typename: "Coord";
  lat: number | null;
  lng: number | null;
}

export interface FreeFloatingVehicles_freeFloatingVehicles_location {
  __typename: "VehicleLocation";
  location: FreeFloatingVehicles_freeFloatingVehicles_location_location;
}

export interface FreeFloatingVehicles_freeFloatingVehicles {
  __typename: "Vehicle";
  id: string;
  name: string | null;
  number: string | null;
  batteryCharge: number | null;
  currentRange: number | null;
  maxRange: number | null;
  category: VehicleCategory2 | null;
  available: boolean | null;
  location: FreeFloatingVehicles_freeFloatingVehicles_location | null;
}

export interface FreeFloatingVehicles {
  freeFloatingVehicles: FreeFloatingVehicles_freeFloatingVehicles[];
}

export interface FreeFloatingVehiclesVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: completedTripSubscription
// ====================================================

export interface completedTripSubscription_completedTrip_direction_geometry {
  __typename: "Coord";
  lat: number | null;
  lng: number | null;
}

export interface completedTripSubscription_completedTrip_direction {
  __typename: "TripDirections";
  duration: number | null;
  distance: number | null;
  geometry: (completedTripSubscription_completedTrip_direction_geometry | null)[] | null;
}

export interface completedTripSubscription_completedTrip_startDockGroup {
  __typename: "DockGroup";
  id: string;
}

export interface completedTripSubscription_completedTrip_endDockGroup {
  __typename: "DockGroup";
  id: string;
}

export interface completedTripSubscription_completedTrip {
  __typename: "PublicTrip";
  id: string;
  startedAt: CORE_IsoDateTime | null;
  endedAt: CORE_IsoDateTime | null;
  direction: completedTripSubscription_completedTrip_direction | null;
  startDockGroup: completedTripSubscription_completedTrip_startDockGroup | null;
  endDockGroup: completedTripSubscription_completedTrip_endDockGroup | null;
}

export interface completedTripSubscription {
  completedTrip: completedTripSubscription_completedTrip | null;
}

export interface completedTripSubscriptionVariables {
  systemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: acceptTermsOfUseMutation
// ====================================================

export interface acceptTermsOfUseMutation {
  /**
   * Accept terms of use for current user, and get back the timestamp for when terms was accepted
   */
  acceptTermsOfUse: CORE_IsoDateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: currentUserUnpaidOrdersQuery
// ====================================================

export interface currentUserUnpaidOrdersQuery_currentUser_unpaidOrders {
  __typename: "Order";
  /**
   * Order ID
   */
  id: string;
  /**
   * Total order amount
   */
  amount: CORE_Money | null;
  /**
   * Order type
   */
  type: OrderType;
  /**
   * When the order was created
   */
  createdAt: CORE_IsoDateTime;
}

export interface currentUserUnpaidOrdersQuery_currentUser {
  __typename: "User";
  id: string;
  unpaidOrders: currentUserUnpaidOrdersQuery_currentUser_unpaidOrders[] | null;
}

export interface currentUserUnpaidOrdersQuery {
  currentUser: currentUserUnpaidOrdersQuery_currentUser | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: payoffUnpaidOrdersMutation
// ====================================================

export interface payoffUnpaidOrdersMutation_payoffUnpaidOrders {
  __typename: "Order";
  /**
   * Order ID
   */
  id: string;
}

export interface payoffUnpaidOrdersMutation {
  payoffUnpaidOrders: payoffUnpaidOrdersMutation_payoffUnpaidOrders[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createPhoneVerificationMutation
// ====================================================

export interface createPhoneVerificationMutation_createPhoneVerification_PhoneVerification {
  __typename: "PhoneVerification";
  sid: string;
  to: string;
}

export interface createPhoneVerificationMutation_createPhoneVerification_PhoneVerificationRateLimit {
  __typename: "PhoneVerificationRateLimit";
  /**
   * Seconds until user can retry
   */
  ttl: number;
  /**
   * Localised user facing message.
   * Does not say anything about when the user can retry, use ttl to create that message with users locale/time settings
   */
  message: string;
}

export interface createPhoneVerificationMutation_createPhoneVerification_PhoneVerificationError {
  __typename: "PhoneVerificationError";
  errorCode: PhoneVerificationErrorCode;
  message: string;
}

export type createPhoneVerificationMutation_createPhoneVerification = createPhoneVerificationMutation_createPhoneVerification_PhoneVerification | createPhoneVerificationMutation_createPhoneVerification_PhoneVerificationRateLimit | createPhoneVerificationMutation_createPhoneVerification_PhoneVerificationError;

export interface createPhoneVerificationMutation {
  createPhoneVerification: createPhoneVerificationMutation_createPhoneVerification;
}

export interface createPhoneVerificationMutationVariables {
  input: CreatePhoneVerificationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: verifyPhoneCodeAndAuthenticateMutation
// ====================================================

export interface verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_currentSubscription_product {
  __typename: "Product";
  id: string;
  name: string | null;
  durationMethod: ProductDurationMethod | null;
  durationMins: number | null;
  rentalMeterType: RentalMeterType | null;
  price: CORE_Money | null;
  validTo: CORE_IsoDateTime | null;
  renewOptMethod: ProductOptMethod;
  /**
   * Can the product be renewed?
   */
  isRenewable: boolean;
}

export interface verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_currentSubscription {
  __typename: "ProductSubscription";
  id: string;
  product: verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_currentSubscription_product | null;
  state: ProductSubscriptionStatus | null;
  validFrom: CORE_IsoDateTime | null;
  validTo: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
}

export interface verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_parsedPhoneNumber {
  __typename: "ParsedPhoneNumber";
  countryCode: string;
  nationalNumber: string;
  formattedNumber: string;
}

export interface verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_paymentMethod_Blik {
  __typename: "Blik" | "AcssDebit" | "Affirm" | "AfterpayClearpay" | "Alipay" | "AuBecsDebit" | "BacsDebit" | "Bancontact" | "Cashapp" | "CustomerBalance" | "Eps" | "Fpx" | "Giropay" | "Grabpay" | "Ideal" | "Klarna" | "Konbini" | "Link" | "Oxxo" | "P24" | "Paynow" | "Paypal" | "Pix" | "Promptpay" | "RevolutPay" | "SepaDebit" | "Sofort" | "Swish" | "UsBankAccount" | "WechatPay" | "Zip";
}

export interface verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_paymentMethod_Card {
  __typename: "Card";
  id: string;
  /**
   * Represents expiration date in MM/YY format
   */
  expiresAt: string;
  expired: boolean;
  last4: string;
  brand: CardBrand;
  /**
   * Display version of brand, eg american_express => American Express
   */
  displayBrand: string;
  /**
   * Indicates whether can payment method be removed or not, due to debt or active trip
   */
  removable: boolean;
}

export type verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_paymentMethod = verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_paymentMethod_Blik | verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_paymentMethod_Card;

export interface verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_productRenewals_ProductRenewalPending_product {
  __typename: "Product";
  id: string;
}

export interface verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_productRenewals_ProductRenewalPending {
  __typename: "ProductRenewalPending";
  product: verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_productRenewals_ProductRenewalPending_product;
}

export interface verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_productRenewals_ProductRenewalScheduled_product {
  __typename: "Product";
  id: string;
  price: CORE_Money | null;
}

export interface verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_productRenewals_ProductRenewalScheduled {
  __typename: "ProductRenewalScheduled";
  product: verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_productRenewals_ProductRenewalScheduled_product;
  scheduledAt: CORE_IsoDateTime;
}

export interface verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_productRenewals_ProductRenewalFailed_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_productRenewals_ProductRenewalFailed {
  __typename: "ProductRenewalFailed";
  product: verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_productRenewals_ProductRenewalFailed_product;
}

export type verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_productRenewals = verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_productRenewals_ProductRenewalPending | verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_productRenewals_ProductRenewalScheduled | verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_productRenewals_ProductRenewalFailed;

export interface verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_wallet {
  __typename: "UserWallet";
  enabled: boolean;
  balance: CORE_Money;
  currency: string;
}

export interface verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_currentTrip {
  __typename: "Trip";
  id: string;
}

export interface verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user {
  __typename: "User";
  id: string;
  name: string | null;
  email: string | null;
  gender: Gender | null;
  birthYear: string | null;
  preferredLanguageCode: string | null;
  postalCode: string | null;
  havePinCode: boolean | null;
  currentSubscription: verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_currentSubscription | null;
  phoneNumber: string;
  parsedPhoneNumber: verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_parsedPhoneNumber;
  penaltyStatus: PenaltyStatus;
  agreedToReceiveEmail: boolean | null;
  agreedToTerms: boolean;
  agreedToReceiveExternalPromotions: boolean | null;
  agreedToRegistrationAgreementWithdrawal: boolean;
  agreedToSubscriptionAgreementWithdrawal: boolean;
  agreedToSendDataToThirdParties: boolean | null;
  paymentMethod: verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_paymentMethod | null;
  requiresAddingPaymentCard: boolean | null;
  hasUnpaidOrders: boolean | null;
  /**
   * List of product renewals
   */
  productRenewals: verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_productRenewals[];
  superpowers: boolean | null;
  rfid: string | null;
  wallet: verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_wallet | null;
  currentTrip: verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user_currentTrip | null;
}

export interface verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication {
  __typename: "SuccessfulAuthentication";
  jwt: string;
  user: verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication_user;
}

export interface verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_PhoneVerificationError {
  __typename: "PhoneVerificationError";
  errorCode: PhoneVerificationErrorCode;
  message: string;
}

export type verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate = verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_SuccessfulAuthentication | verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate_PhoneVerificationError;

export interface verifyPhoneCodeAndAuthenticateMutation {
  /**
   * Verifies code sent to phone, and creates new active user if user does not exist
   */
  verifyPhoneCodeAndAuthenticate: verifyPhoneCodeAndAuthenticateMutation_verifyPhoneCodeAndAuthenticate;
}

export interface verifyPhoneCodeAndAuthenticateMutationVariables {
  input: PhoneVerificationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: loginMutation
// ====================================================

export interface loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_currentSubscription_product {
  __typename: "Product";
  id: string;
  name: string | null;
  durationMethod: ProductDurationMethod | null;
  durationMins: number | null;
  rentalMeterType: RentalMeterType | null;
  price: CORE_Money | null;
  validTo: CORE_IsoDateTime | null;
  renewOptMethod: ProductOptMethod;
  /**
   * Can the product be renewed?
   */
  isRenewable: boolean;
}

export interface loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_currentSubscription {
  __typename: "ProductSubscription";
  id: string;
  product: loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_currentSubscription_product | null;
  state: ProductSubscriptionStatus | null;
  validFrom: CORE_IsoDateTime | null;
  validTo: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
}

export interface loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_parsedPhoneNumber {
  __typename: "ParsedPhoneNumber";
  countryCode: string;
  nationalNumber: string;
  formattedNumber: string;
}

export interface loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_paymentMethod_Blik {
  __typename: "Blik" | "AcssDebit" | "Affirm" | "AfterpayClearpay" | "Alipay" | "AuBecsDebit" | "BacsDebit" | "Bancontact" | "Cashapp" | "CustomerBalance" | "Eps" | "Fpx" | "Giropay" | "Grabpay" | "Ideal" | "Klarna" | "Konbini" | "Link" | "Oxxo" | "P24" | "Paynow" | "Paypal" | "Pix" | "Promptpay" | "RevolutPay" | "SepaDebit" | "Sofort" | "Swish" | "UsBankAccount" | "WechatPay" | "Zip";
}

export interface loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_paymentMethod_Card {
  __typename: "Card";
  id: string;
  /**
   * Represents expiration date in MM/YY format
   */
  expiresAt: string;
  expired: boolean;
  last4: string;
  brand: CardBrand;
  /**
   * Display version of brand, eg american_express => American Express
   */
  displayBrand: string;
  /**
   * Indicates whether can payment method be removed or not, due to debt or active trip
   */
  removable: boolean;
}

export type loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_paymentMethod = loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_paymentMethod_Blik | loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_paymentMethod_Card;

export interface loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_productRenewals_ProductRenewalPending_product {
  __typename: "Product";
  id: string;
}

export interface loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_productRenewals_ProductRenewalPending {
  __typename: "ProductRenewalPending";
  product: loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_productRenewals_ProductRenewalPending_product;
}

export interface loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_productRenewals_ProductRenewalScheduled_product {
  __typename: "Product";
  id: string;
  price: CORE_Money | null;
}

export interface loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_productRenewals_ProductRenewalScheduled {
  __typename: "ProductRenewalScheduled";
  product: loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_productRenewals_ProductRenewalScheduled_product;
  scheduledAt: CORE_IsoDateTime;
}

export interface loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_productRenewals_ProductRenewalFailed_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_productRenewals_ProductRenewalFailed {
  __typename: "ProductRenewalFailed";
  product: loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_productRenewals_ProductRenewalFailed_product;
}

export type loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_productRenewals = loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_productRenewals_ProductRenewalPending | loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_productRenewals_ProductRenewalScheduled | loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_productRenewals_ProductRenewalFailed;

export interface loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_wallet {
  __typename: "UserWallet";
  enabled: boolean;
  balance: CORE_Money;
  currency: string;
}

export interface loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_currentTrip {
  __typename: "Trip";
  id: string;
}

export interface loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user {
  __typename: "User";
  id: string;
  name: string | null;
  email: string | null;
  gender: Gender | null;
  birthYear: string | null;
  preferredLanguageCode: string | null;
  postalCode: string | null;
  havePinCode: boolean | null;
  currentSubscription: loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_currentSubscription | null;
  phoneNumber: string;
  parsedPhoneNumber: loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_parsedPhoneNumber;
  penaltyStatus: PenaltyStatus;
  agreedToReceiveEmail: boolean | null;
  agreedToTerms: boolean;
  agreedToReceiveExternalPromotions: boolean | null;
  agreedToRegistrationAgreementWithdrawal: boolean;
  agreedToSubscriptionAgreementWithdrawal: boolean;
  agreedToSendDataToThirdParties: boolean | null;
  paymentMethod: loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_paymentMethod | null;
  requiresAddingPaymentCard: boolean | null;
  hasUnpaidOrders: boolean | null;
  /**
   * List of product renewals
   */
  productRenewals: loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_productRenewals[];
  superpowers: boolean | null;
  rfid: string | null;
  wallet: loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_wallet | null;
  currentTrip: loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user_currentTrip | null;
}

export interface loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess {
  __typename: "VerifyCodeAndLogInSuccess";
  jwt: string;
  user: loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess_user;
}

export interface loginMutation_verifyCodeAndLogIn_PhoneVerificationError {
  __typename: "PhoneVerificationError";
  errorCode: PhoneVerificationErrorCode;
  message: string;
}

export type loginMutation_verifyCodeAndLogIn = loginMutation_verifyCodeAndLogIn_VerifyCodeAndLogInSuccess | loginMutation_verifyCodeAndLogIn_PhoneVerificationError;

export interface loginMutation {
  verifyCodeAndLogIn: loginMutation_verifyCodeAndLogIn;
}

export interface loginMutationVariables {
  input: PhoneVerificationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: twilioUpdatePhoneNumberMutation
// ====================================================

export interface twilioUpdatePhoneNumberMutation_updatePhoneNumber_VerifyCodeAndUpdatePhoneNumberSuccess {
  __typename: "VerifyCodeAndUpdatePhoneNumberSuccess";
  updatedPhoneNumber: string;
}

export interface twilioUpdatePhoneNumberMutation_updatePhoneNumber_PhoneVerificationError {
  __typename: "PhoneVerificationError";
  errorCode: PhoneVerificationErrorCode;
  message: string;
}

export type twilioUpdatePhoneNumberMutation_updatePhoneNumber = twilioUpdatePhoneNumberMutation_updatePhoneNumber_VerifyCodeAndUpdatePhoneNumberSuccess | twilioUpdatePhoneNumberMutation_updatePhoneNumber_PhoneVerificationError;

export interface twilioUpdatePhoneNumberMutation {
  updatePhoneNumber: twilioUpdatePhoneNumberMutation_updatePhoneNumber;
}

export interface twilioUpdatePhoneNumberMutationVariables {
  input: PhoneVerificationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateConsentsMutation
// ====================================================

export interface updateConsentsMutation_updateLegalConsents {
  __typename: "User";
  id: string;
}

export interface updateConsentsMutation {
  /**
   * Accept multiple consents for current user
   */
  updateLegalConsents: updateConsentsMutation_updateLegalConsents | null;
}

export interface updateConsentsMutationVariables {
  consents: LegalConsentInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: phoneCountryCodesQuery
// ====================================================

export interface phoneCountryCodesQuery_phoneCountryCodes {
  __typename: "PhoneCountryCode";
  code: string;
  countryName: string;
}

export interface phoneCountryCodesQuery {
  phoneCountryCodes: (phoneCountryCodesQuery_phoneCountryCodes | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: businessPageQuery
// ====================================================

export interface businessPageQuery_system {
  __typename: "System";
  id: string;
  /**
   * Localised city name
   */
  cityName: string;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityFeatureModule_figureModule {
  __typename: "SanityFeatureFigureVideoGuide";
  _key: string;
  _type: string | null;
  video: string | null;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityFeatureModule {
  __typename: "SanityFeatureModule";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
  figureModule: businessPageQuery_businessPage_featureBlocks_SanityFeatureModule_figureModule[] | null;
  hideFromApps: boolean | null;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityMultiFeatureModule_featureModules_figureModule {
  __typename: "SanityFeatureFigureVideoGuide";
  _key: string;
  _type: string | null;
  video: string | null;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityMultiFeatureModule_featureModules {
  __typename: "SanityMultiFigureModule";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
  figureModule: businessPageQuery_businessPage_featureBlocks_SanityMultiFeatureModule_featureModules_figureModule[] | null;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityMultiFeatureModule {
  __typename: "SanityMultiFeatureModule";
  _key: string;
  title: string | null;
  featureModules: businessPageQuery_businessPage_featureBlocks_SanityMultiFeatureModule_featureModules[] | null;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityFeatureModuleTripDuration {
  __typename: "SanityFeatureModuleTripDuration";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityFeatureVideo_poster1x1 {
  __typename: "SanityImage";
  url: string | null;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityFeatureVideo_poster16x9 {
  __typename: "SanityImage";
  url: string | null;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityFeatureVideo {
  __typename: "SanityFeatureVideo";
  _key: string;
  title: string | null;
  videoUrl: string | null;
  autoplay: boolean | null;
  poster1x1: businessPageQuery_businessPage_featureBlocks_SanityFeatureVideo_poster1x1 | null;
  poster16x9: businessPageQuery_businessPage_featureBlocks_SanityFeatureVideo_poster16x9 | null;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityFeatureImage_image_metadata_dimensions {
  __typename: "SanityImageMetaDataDimensions";
  width: number | null;
  height: number | null;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityFeatureImage_image_metadata {
  __typename: "SanityImageMetaData";
  dimensions: businessPageQuery_businessPage_featureBlocks_SanityFeatureImage_image_metadata_dimensions | null;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityFeatureImage_image {
  __typename: "SanityImage";
  url: string | null;
  metadata: businessPageQuery_businessPage_featureBlocks_SanityFeatureImage_image_metadata | null;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityFeatureImage_link_page_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityFeatureImage_link_page {
  __typename: "SanityLinkPage";
  _id: string;
  _type: string;
  slug: businessPageQuery_businessPage_featureBlocks_SanityFeatureImage_link_page_slug | null;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityFeatureImage_link {
  __typename: "SanityLinkOnly";
  _key: string | null;
  page: businessPageQuery_businessPage_featureBlocks_SanityFeatureImage_link_page | null;
  externalLink: string | null;
  showIntercom: boolean | null;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityFeatureImage {
  __typename: "SanityFeatureImage";
  _key: string;
  /**
   * Internal block name
   */
  blockName: string | null;
  alt: string | null;
  image: businessPageQuery_businessPage_featureBlocks_SanityFeatureImage_image | null;
  variant: SanityFeatureModuleVariant | null;
  hideFromApps: boolean | null;
  link: businessPageQuery_businessPage_featureBlocks_SanityFeatureImage_link | null;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityHowItWorksFeatureBlock_steps {
  __typename: "SanityHowWorksStep";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityHowItWorksFeatureBlock {
  __typename: "SanityHowItWorksFeatureBlock";
  _key: string;
  steps: businessPageQuery_businessPage_featureBlocks_SanityHowItWorksFeatureBlock_steps[] | null;
  demonstrationVideo: string | null;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityStationMapFeatureBlock {
  __typename: "SanityStationMapFeatureBlock";
  _key: string;
  description: CORE_JSON | null;
  variant: SanityFeatureModuleVariant | null;
  hideFromApps: boolean | null;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityTripStatsFeatureBlock_phraseGroups {
  __typename: "SanityTripStatsFeatureBlockTripCountPhraseGroup";
  _key: string;
  phrases: (string | null)[] | null;
  threshold: number;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityTripStatsFeatureBlock {
  __typename: "SanityTripStatsFeatureBlock";
  _key: string;
  variant: SanityFeatureModuleVariant | null;
  hideFromApps: boolean | null;
  phraseGroups: (businessPageQuery_businessPage_featureBlocks_SanityTripStatsFeatureBlock_phraseGroups | null)[] | null;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityPageFeatureBlock {
  __typename: "SanityPageFeatureBlock";
  _key: string;
  title: string | null;
  pageRefType: string | null;
  variant: SanityFeatureModuleVariant | null;
  hideFromApps: boolean | null;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityInfoBlock_link_page_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityInfoBlock_link_page {
  __typename: "SanitySettingsNavigationPage";
  _id: string;
  _type: string;
  slug: businessPageQuery_businessPage_featureBlocks_SanityInfoBlock_link_page_slug | null;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityInfoBlock_link {
  __typename: "SanitySettingsNavigation";
  page: businessPageQuery_businessPage_featureBlocks_SanityInfoBlock_link_page | null;
  externalLink: string | null;
  showIntercom: boolean | null;
  title: string | null;
  _key: string | null;
}

export interface businessPageQuery_businessPage_featureBlocks_SanityInfoBlock {
  __typename: "SanityInfoBlock";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
  emotion: string | null;
  variant: SanityFeatureModuleVariant | null;
  hideFromApps: boolean | null;
  link: businessPageQuery_businessPage_featureBlocks_SanityInfoBlock_link | null;
}

export type businessPageQuery_businessPage_featureBlocks = businessPageQuery_businessPage_featureBlocks_SanityFeatureModule | businessPageQuery_businessPage_featureBlocks_SanityMultiFeatureModule | businessPageQuery_businessPage_featureBlocks_SanityFeatureModuleTripDuration | businessPageQuery_businessPage_featureBlocks_SanityFeatureVideo | businessPageQuery_businessPage_featureBlocks_SanityFeatureImage | businessPageQuery_businessPage_featureBlocks_SanityHowItWorksFeatureBlock | businessPageQuery_businessPage_featureBlocks_SanityStationMapFeatureBlock | businessPageQuery_businessPage_featureBlocks_SanityTripStatsFeatureBlock | businessPageQuery_businessPage_featureBlocks_SanityPageFeatureBlock | businessPageQuery_businessPage_featureBlocks_SanityInfoBlock;

export interface businessPageQuery_businessPage {
  __typename: "SanityBusinessPage";
  title: string | null;
  heading: string | null;
  description: CORE_JSON | null;
  featureBlocks: businessPageQuery_businessPage_featureBlocks[] | null;
}

export interface businessPageQuery {
  system: businessPageQuery_system | null;
  businessPage: businessPageQuery_businessPage | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: emailBusinessRegisterWelcomeQuery
// ====================================================

export interface emailBusinessRegisterWelcomeQuery_sanitySettings_footer {
  __typename: "SanityFooter";
  aboutOperator: (CORE_JSON | null)[] | null;
}

export interface emailBusinessRegisterWelcomeQuery_sanitySettings {
  __typename: "SanitySettings";
  title: string;
  footer: emailBusinessRegisterWelcomeQuery_sanitySettings_footer | null;
}

export interface emailBusinessRegisterWelcomeQuery_system {
  __typename: "System";
  id: string;
  businessUrl: string | null;
  colourKey: string | null;
}

export interface emailBusinessRegisterWelcomeQuery_order_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface emailBusinessRegisterWelcomeQuery_order {
  __typename: "Order";
  /**
   * Order ID
   */
  id: string;
  /**
   * B2B Company
   */
  company: emailBusinessRegisterWelcomeQuery_order_company | null;
}

export interface emailBusinessRegisterWelcomeQuery {
  sanitySettings: emailBusinessRegisterWelcomeQuery_sanitySettings;
  system: emailBusinessRegisterWelcomeQuery_system | null;
  order: emailBusinessRegisterWelcomeQuery_order | null;
}

export interface emailBusinessRegisterWelcomeQueryVariables {
  orderToken: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: giftCardQuery
// ====================================================

export interface giftCardQuery_giftCardPage {
  __typename: "GiftCardPage";
  title: string | null;
  heading: string | null;
  footer: CORE_JSON | null;
}

export interface giftCardQuery_valueCode_product {
  __typename: "Product";
  id: string;
  name: string | null;
  giftCardDescription: CORE_JSON | null;
}

export interface giftCardQuery_valueCode {
  __typename: "ValueCode";
  id: string | null;
  token: string;
  code: string;
  product: giftCardQuery_valueCode_product | null;
}

export interface giftCardQuery {
  giftCardPage: giftCardQuery_giftCardPage | null;
  valueCode: giftCardQuery_valueCode | null;
}

export interface giftCardQueryVariables {
  systemId?: string | null;
  token: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: howItWorksQuery
// ====================================================

export interface howItWorksQuery_system {
  __typename: "System";
  id: string;
  /**
   * Localised city name
   */
  cityName: string;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureModule_figureModule {
  __typename: "SanityFeatureFigureVideoGuide";
  _key: string;
  _type: string | null;
  video: string | null;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureModule {
  __typename: "SanityFeatureModule";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
  figureModule: howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureModule_figureModule[] | null;
  hideFromApps: boolean | null;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityMultiFeatureModule_featureModules_figureModule {
  __typename: "SanityFeatureFigureVideoGuide";
  _key: string;
  _type: string | null;
  video: string | null;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityMultiFeatureModule_featureModules {
  __typename: "SanityMultiFigureModule";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
  figureModule: howItWorksQuery_howItWorksPage_featureBlocks_SanityMultiFeatureModule_featureModules_figureModule[] | null;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityMultiFeatureModule {
  __typename: "SanityMultiFeatureModule";
  _key: string;
  title: string | null;
  featureModules: howItWorksQuery_howItWorksPage_featureBlocks_SanityMultiFeatureModule_featureModules[] | null;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureModuleTripDuration {
  __typename: "SanityFeatureModuleTripDuration";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureVideo_poster1x1 {
  __typename: "SanityImage";
  url: string | null;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureVideo_poster16x9 {
  __typename: "SanityImage";
  url: string | null;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureVideo {
  __typename: "SanityFeatureVideo";
  _key: string;
  title: string | null;
  videoUrl: string | null;
  autoplay: boolean | null;
  poster1x1: howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureVideo_poster1x1 | null;
  poster16x9: howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureVideo_poster16x9 | null;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureImage_image_metadata_dimensions {
  __typename: "SanityImageMetaDataDimensions";
  width: number | null;
  height: number | null;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureImage_image_metadata {
  __typename: "SanityImageMetaData";
  dimensions: howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureImage_image_metadata_dimensions | null;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureImage_image {
  __typename: "SanityImage";
  url: string | null;
  metadata: howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureImage_image_metadata | null;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureImage_link_page_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureImage_link_page {
  __typename: "SanityLinkPage";
  _id: string;
  _type: string;
  slug: howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureImage_link_page_slug | null;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureImage_link {
  __typename: "SanityLinkOnly";
  _key: string | null;
  page: howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureImage_link_page | null;
  externalLink: string | null;
  showIntercom: boolean | null;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureImage {
  __typename: "SanityFeatureImage";
  _key: string;
  /**
   * Internal block name
   */
  blockName: string | null;
  alt: string | null;
  image: howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureImage_image | null;
  variant: SanityFeatureModuleVariant | null;
  hideFromApps: boolean | null;
  link: howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureImage_link | null;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityHowItWorksFeatureBlock_steps {
  __typename: "SanityHowWorksStep";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityHowItWorksFeatureBlock {
  __typename: "SanityHowItWorksFeatureBlock";
  _key: string;
  steps: howItWorksQuery_howItWorksPage_featureBlocks_SanityHowItWorksFeatureBlock_steps[] | null;
  demonstrationVideo: string | null;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityStationMapFeatureBlock {
  __typename: "SanityStationMapFeatureBlock";
  _key: string;
  description: CORE_JSON | null;
  variant: SanityFeatureModuleVariant | null;
  hideFromApps: boolean | null;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityTripStatsFeatureBlock_phraseGroups {
  __typename: "SanityTripStatsFeatureBlockTripCountPhraseGroup";
  _key: string;
  phrases: (string | null)[] | null;
  threshold: number;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityTripStatsFeatureBlock {
  __typename: "SanityTripStatsFeatureBlock";
  _key: string;
  variant: SanityFeatureModuleVariant | null;
  hideFromApps: boolean | null;
  phraseGroups: (howItWorksQuery_howItWorksPage_featureBlocks_SanityTripStatsFeatureBlock_phraseGroups | null)[] | null;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityPageFeatureBlock {
  __typename: "SanityPageFeatureBlock";
  _key: string;
  title: string | null;
  pageRefType: string | null;
  variant: SanityFeatureModuleVariant | null;
  hideFromApps: boolean | null;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityInfoBlock_link_page_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityInfoBlock_link_page {
  __typename: "SanitySettingsNavigationPage";
  _id: string;
  _type: string;
  slug: howItWorksQuery_howItWorksPage_featureBlocks_SanityInfoBlock_link_page_slug | null;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityInfoBlock_link {
  __typename: "SanitySettingsNavigation";
  page: howItWorksQuery_howItWorksPage_featureBlocks_SanityInfoBlock_link_page | null;
  externalLink: string | null;
  showIntercom: boolean | null;
  title: string | null;
  _key: string | null;
}

export interface howItWorksQuery_howItWorksPage_featureBlocks_SanityInfoBlock {
  __typename: "SanityInfoBlock";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
  emotion: string | null;
  variant: SanityFeatureModuleVariant | null;
  hideFromApps: boolean | null;
  link: howItWorksQuery_howItWorksPage_featureBlocks_SanityInfoBlock_link | null;
}

export type howItWorksQuery_howItWorksPage_featureBlocks = howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureModule | howItWorksQuery_howItWorksPage_featureBlocks_SanityMultiFeatureModule | howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureModuleTripDuration | howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureVideo | howItWorksQuery_howItWorksPage_featureBlocks_SanityFeatureImage | howItWorksQuery_howItWorksPage_featureBlocks_SanityHowItWorksFeatureBlock | howItWorksQuery_howItWorksPage_featureBlocks_SanityStationMapFeatureBlock | howItWorksQuery_howItWorksPage_featureBlocks_SanityTripStatsFeatureBlock | howItWorksQuery_howItWorksPage_featureBlocks_SanityPageFeatureBlock | howItWorksQuery_howItWorksPage_featureBlocks_SanityInfoBlock;

export interface howItWorksQuery_howItWorksPage {
  __typename: "SanityHowItWorksPage";
  title: string | null;
  heading: string | null;
  description: CORE_JSON | null;
  file: CORE_JSON | null;
  featureBlocks: howItWorksQuery_howItWorksPage_featureBlocks[] | null;
}

export interface howItWorksQuery {
  system: howItWorksQuery_system | null;
  howItWorksPage: howItWorksQuery_howItWorksPage | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: orderConfirmationQuery
// ====================================================

export interface orderConfirmationQuery_receiptPage {
  __typename: "ReceiptPage";
  title: string | null;
  orderTitle: string | null;
}

export interface orderConfirmationQuery_order_valueCodes_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface orderConfirmationQuery_order_valueCodes {
  __typename: "ValueCode";
  id: string | null;
  token: string;
  product: orderConfirmationQuery_order_valueCodes_product | null;
}

export interface orderConfirmationQuery_order_paymentTransactions {
  __typename: "PaymentTransaction";
  /**
   * Payment transaction ID
   */
  id: string;
  /**
   * Transaction number at provider
   */
  transactionNumber: string | null;
  /**
   * Payment transaction status
   */
  status: PaymentTransactionStatus;
}

export interface orderConfirmationQuery_order_walletTransactions {
  __typename: "WalletTransaction";
  id: string;
  orderId: string;
  createdAt: CORE_IsoDateTime;
  type: WalletTransactionType;
  amount: CORE_Money;
}

export interface orderConfirmationQuery_order_discount_discountAmount_RelativeDiscountAmount {
  __typename: "RelativeDiscountAmount";
  amount: CORE_PositiveFloat;
  absoluteAmount: CORE_Money;
}

export interface orderConfirmationQuery_order_discount_discountAmount_AbsoluteDiscountAmount {
  __typename: "AbsoluteDiscountAmount";
  amount: CORE_PositiveFloat;
}

export type orderConfirmationQuery_order_discount_discountAmount = orderConfirmationQuery_order_discount_discountAmount_RelativeDiscountAmount | orderConfirmationQuery_order_discount_discountAmount_AbsoluteDiscountAmount;

export interface orderConfirmationQuery_order_discount {
  __typename: "Discount";
  /**
   * Defines discount amount
   */
  discountAmount: orderConfirmationQuery_order_discount_discountAmount;
  /**
   * Price with discount
   */
  totalAmountDiscounted: CORE_Money;
  /**
   * Total vat amount with discount
   */
  totalVatAmountDiscounted: CORE_Money | null;
  /**
   * Represents total amount of discount in absolute values depending on
   * discounted items quantity
   */
  totalDiscountAmount: CORE_Money;
}

export interface orderConfirmationQuery_order_orderLines_product {
  __typename: "Product";
  id: string;
  name: string | null;
  price: CORE_Money | null;
}

export interface orderConfirmationQuery_order_orderLines_valueCodes {
  __typename: "ValueCode";
  id: string | null;
  code: string;
  redeemedAt: CORE_IsoDateTime | null;
}

export interface orderConfirmationQuery_order_orderLines_discount_discountAmount_RelativeDiscountAmount {
  __typename: "RelativeDiscountAmount";
  amount: CORE_PositiveFloat;
  absoluteAmount: CORE_Money;
}

export interface orderConfirmationQuery_order_orderLines_discount_discountAmount_AbsoluteDiscountAmount {
  __typename: "AbsoluteDiscountAmount";
  amount: CORE_PositiveFloat;
}

export type orderConfirmationQuery_order_orderLines_discount_discountAmount = orderConfirmationQuery_order_orderLines_discount_discountAmount_RelativeDiscountAmount | orderConfirmationQuery_order_orderLines_discount_discountAmount_AbsoluteDiscountAmount;

export interface orderConfirmationQuery_order_orderLines_discount {
  __typename: "Discount";
  /**
   * Defines discount amount
   */
  discountAmount: orderConfirmationQuery_order_orderLines_discount_discountAmount;
  /**
   * Defines localized discount campaign name
   */
  name: string;
  /**
   * Defines how many products will be discounted
   */
  quantityDiscounted: CORE_PositiveInt;
  /**
   * Price with discount
   */
  totalAmountDiscounted: CORE_Money;
  /**
   * Represents total amount of discount in absolute values depending on
   * discounted items quantity
   */
  totalDiscountAmount: CORE_Money;
}

export interface orderConfirmationQuery_order_orderLines {
  __typename: "OrderLine";
  /**
   * Order line ID
   */
  id: string;
  /**
   * Order line type
   */
  type: OrderLineType | null;
  /**
   * Quantity of product or price matrix items
   */
  quantity: number;
  /**
   * Product amount
   */
  amount: CORE_Money;
  /**
   * Total amount is computed by quantity times amount
   */
  totalAmount: CORE_Money;
  /**
   * Product amount without VAT
   */
  amountWithoutVat: CORE_Money;
  /**
   * Localised title for the order line
   * @deprecated(reason: "Use title")
   */
  orderLineTitle: string | null;
  /**
   * The product for the order line
   */
  product: orderConfirmationQuery_order_orderLines_product | null;
  /**
   * Any value codes connected to the order line
   */
  valueCodes: (orderConfirmationQuery_order_orderLines_valueCodes | null)[] | null;
  /**
   * Applied discount
   */
  discount: orderConfirmationQuery_order_orderLines_discount | null;
}

export interface orderConfirmationQuery_order_user {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface orderConfirmationQuery_order_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface orderConfirmationQuery_order_trip_startDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
}

export interface orderConfirmationQuery_order_trip_startDock {
  __typename: "Dock";
  id: string;
  dockGroup: orderConfirmationQuery_order_trip_startDock_dockGroup;
}

export interface orderConfirmationQuery_order_trip_endDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
}

export interface orderConfirmationQuery_order_trip_endDock {
  __typename: "Dock";
  id: string;
  dockGroup: orderConfirmationQuery_order_trip_endDock_dockGroup;
}

export interface orderConfirmationQuery_order_trip_startLocation_location {
  __typename: "Coord";
  lat: number | null;
  lng: number | null;
}

export interface orderConfirmationQuery_order_trip_startLocation {
  __typename: "VehicleLocation";
  location: orderConfirmationQuery_order_trip_startLocation_location;
  address: string | null;
}

export interface orderConfirmationQuery_order_trip_endLocation_location {
  __typename: "Coord";
  lat: number | null;
  lng: number | null;
}

export interface orderConfirmationQuery_order_trip_endLocation {
  __typename: "VehicleLocation";
  location: orderConfirmationQuery_order_trip_endLocation_location;
  address: string | null;
}

export interface orderConfirmationQuery_order_trip {
  __typename: "Trip";
  id: string;
  startedAt: CORE_IsoDateTime | null;
  endedAt: CORE_IsoDateTime | null;
  startDock: orderConfirmationQuery_order_trip_startDock | null;
  endDock: orderConfirmationQuery_order_trip_endDock | null;
  startLocation: orderConfirmationQuery_order_trip_startLocation | null;
  endLocation: orderConfirmationQuery_order_trip_endLocation | null;
  duration: number | null;
  /**
   * Included free time to the trip (not live updated), represent duration length of first free item from Price Matrix
   * so it's not affected by Trip Additional Minutes at all
   */
  freePeriodMins: number;
  /**
   * Current paid duration (live updated) of the trip
   */
  paidPeriodMins: number;
}

export interface orderConfirmationQuery_order {
  __typename: "Order";
  /**
   * Order ID
   */
  id: string;
  /**
   * Total order amount
   */
  amount: CORE_Money | null;
  /**
   * Total order amount without VAT
   */
  amountWithoutVat: CORE_Money | null;
  /**
   * Currency for the order amount
   */
  currency: string;
  /**
   * Customer's email
   */
  customerEmail: string | null;
  /**
   * Total order vat amount
   */
  vatAmount: CORE_Money | null;
  /**
   * Order type
   */
  type: OrderType;
  /**
   * Completed at datetime
   */
  completedAt: CORE_IsoDateTime | null;
  /**
   * The masked credit card the order was processed with. Not exposed if the user is not logged in
   */
  creditCardMasked: string | null;
  /**
   * Value codes
   */
  valueCodes: (orderConfirmationQuery_order_valueCodes | null)[] | null;
  /**
   * Payment transactions
   */
  paymentTransactions: orderConfirmationQuery_order_paymentTransactions[];
  /**
   * Linked Wallet transactions; not empty if this is a "wallet deposit" order, or if the order was paid via Wallet balance
   */
  walletTransactions: orderConfirmationQuery_order_walletTransactions[];
  /**
   * Applied discount
   */
  discount: orderConfirmationQuery_order_discount | null;
  /**
   * Order lines
   */
  orderLines: (orderConfirmationQuery_order_orderLines | null)[];
  /**
   * User
   */
  user: orderConfirmationQuery_order_user | null;
  /**
   * B2B Company
   */
  company: orderConfirmationQuery_order_company | null;
  /**
   * The trip connected to order. Applies for pay2go and extended_rental orders
   */
  trip: orderConfirmationQuery_order_trip | null;
}

export interface orderConfirmationQuery {
  receiptPage: orderConfirmationQuery_receiptPage | null;
  order: orderConfirmationQuery_order | null;
}

export interface orderConfirmationQueryVariables {
  orderToken: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: redeemCouponPageQuery
// ====================================================

export interface redeemCouponPageQuery_redeemCouponPage {
  __typename: "SanityRedeemCouponPage";
  title: string;
  heading: string | null;
}

export interface redeemCouponPageQuery {
  redeemCouponPage: redeemCouponPageQuery_redeemCouponPage | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: systemAlertQuery
// ====================================================

export interface systemAlertQuery_systemAlert_dockGroups {
  __typename: "DockGroup";
  id: string;
  title: string | null;
}

export interface systemAlertQuery_systemAlert_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface systemAlertQuery_systemAlert_updates {
  __typename: "SystemAlertUpdate";
  id: string;
  createdAt: CORE_IsoDateTime;
  title: string | null;
  description: string | null;
}

export interface systemAlertQuery_systemAlert {
  __typename: "SystemAlert";
  id: string;
  resolvedAt: CORE_IsoDateTime | null;
  dockGroups: (systemAlertQuery_systemAlert_dockGroups | null)[] | null;
  systemAlertType: SystemAlertType;
  incidentStartTime: CORE_IsoDateTime;
  incidentEndTime: CORE_IsoDateTime | null;
  title: string | null;
  description: string | null;
  slug: systemAlertQuery_systemAlert_slug | null;
  updates: (systemAlertQuery_systemAlert_updates | null)[] | null;
  visibility: SystemAlertVisibility;
}

export interface systemAlertQuery {
  systemAlert: systemAlertQuery_systemAlert | null;
}

export interface systemAlertQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AsyncProcessCompletionFragment
// ====================================================

export interface AsyncProcessCompletionFragment_AsyncProcessCompleted {
  __typename: "AsyncProcessCompleted" | "AsyncProcessFailed" | "AsyncProcessCompletionPostponed";
}

export interface AsyncProcessCompletionFragment_AsyncProcessCompletionPending {
  __typename: "AsyncProcessCompletionPending";
  checkAfterMilliseconds: CORE_PositiveInt;
}

export type AsyncProcessCompletionFragment = AsyncProcessCompletionFragment_AsyncProcessCompleted | AsyncProcessCompletionFragment_AsyncProcessCompletionPending;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ConnectionPriceMatrix
// ====================================================

export interface ConnectionPriceMatrix_priceMatrix_matrix {
  __typename: "PriceMatrixItem";
  minutes: number;
  price: CORE_Money;
  quantity: number;
  type: PriceMatrixItemType;
}

export interface ConnectionPriceMatrix_priceMatrix {
  __typename: "PriceMatrix";
  lastStepInfinite: boolean;
  matrix: ConnectionPriceMatrix_priceMatrix_matrix[];
}

export interface ConnectionPriceMatrix {
  __typename: "ConnectionPriceMatrix";
  id: string;
  type: ConnectionPriceMatrixType;
  priceMatrix: ConnectionPriceMatrix_priceMatrix;
  vehicleCategory: VehicleCategory2;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Product
// ====================================================

export interface Product_image_metadata_dimensions {
  __typename: "SanityImageMetaDataDimensions";
  width: number | null;
  height: number | null;
}

export interface Product_image_metadata {
  __typename: "SanityImageMetaData";
  dimensions: Product_image_metadata_dimensions | null;
}

export interface Product_image {
  __typename: "SanityImage";
  url: string | null;
  metadata: Product_image_metadata | null;
}

export interface Product_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface Product_connectionPriceMatrixes_priceMatrix_matrix {
  __typename: "PriceMatrixItem";
  minutes: number;
  price: CORE_Money;
  quantity: number;
  type: PriceMatrixItemType;
}

export interface Product_connectionPriceMatrixes_priceMatrix {
  __typename: "PriceMatrix";
  lastStepInfinite: boolean;
  matrix: Product_connectionPriceMatrixes_priceMatrix_matrix[];
}

export interface Product_connectionPriceMatrixes {
  __typename: "ConnectionPriceMatrix";
  id: string;
  type: ConnectionPriceMatrixType;
  priceMatrix: Product_connectionPriceMatrixes_priceMatrix;
  vehicleCategory: VehicleCategory2;
}

export interface Product {
  __typename: "Product";
  id: string;
  /**
   * Localised product description
   */
  descriptionText: string | null;
  name: string | null;
  image: Product_image | null;
  slug: Product_slug | null;
  price: CORE_Money | null;
  extendedRentalPeriodCharge: CORE_Money | null;
  tripStartCharge: CORE_Money | null;
  rentalMeterType: RentalMeterType | null;
  campaign: string | null;
  purchasable: boolean | null;
  purchasableFrom: CORE_IsoDateTime | null;
  purchasableTo: CORE_IsoDateTime | null;
  connectionPriceMatrixes: Product_connectionPriceMatrixes[] | null;
  renewOptMethod: ProductOptMethod;
  durationMethod: ProductDurationMethod | null;
  durationMins: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CurrentSubscription
// ====================================================

export interface CurrentSubscription_product {
  __typename: "Product";
  id: string;
  name: string | null;
  durationMethod: ProductDurationMethod | null;
  durationMins: number | null;
  rentalMeterType: RentalMeterType | null;
  price: CORE_Money | null;
  validTo: CORE_IsoDateTime | null;
  renewOptMethod: ProductOptMethod;
  /**
   * Can the product be renewed?
   */
  isRenewable: boolean;
}

export interface CurrentSubscription {
  __typename: "ProductSubscription";
  id: string;
  product: CurrentSubscription_product | null;
  state: ProductSubscriptionStatus | null;
  validFrom: CORE_IsoDateTime | null;
  validTo: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CurrentUserProductRenewal
// ====================================================

export interface CurrentUserProductRenewal_ProductRenewalPending_product {
  __typename: "Product";
  id: string;
}

export interface CurrentUserProductRenewal_ProductRenewalPending {
  __typename: "ProductRenewalPending";
  product: CurrentUserProductRenewal_ProductRenewalPending_product;
}

export interface CurrentUserProductRenewal_ProductRenewalScheduled_product {
  __typename: "Product";
  id: string;
  price: CORE_Money | null;
}

export interface CurrentUserProductRenewal_ProductRenewalScheduled {
  __typename: "ProductRenewalScheduled";
  product: CurrentUserProductRenewal_ProductRenewalScheduled_product;
  scheduledAt: CORE_IsoDateTime;
}

export interface CurrentUserProductRenewal_ProductRenewalFailed_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface CurrentUserProductRenewal_ProductRenewalFailed {
  __typename: "ProductRenewalFailed";
  product: CurrentUserProductRenewal_ProductRenewalFailed_product;
}

export type CurrentUserProductRenewal = CurrentUserProductRenewal_ProductRenewalPending | CurrentUserProductRenewal_ProductRenewalScheduled | CurrentUserProductRenewal_ProductRenewalFailed;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CurrentUser
// ====================================================

export interface CurrentUser_currentSubscription_product {
  __typename: "Product";
  id: string;
  name: string | null;
  durationMethod: ProductDurationMethod | null;
  durationMins: number | null;
  rentalMeterType: RentalMeterType | null;
  price: CORE_Money | null;
  validTo: CORE_IsoDateTime | null;
  renewOptMethod: ProductOptMethod;
  /**
   * Can the product be renewed?
   */
  isRenewable: boolean;
}

export interface CurrentUser_currentSubscription {
  __typename: "ProductSubscription";
  id: string;
  product: CurrentUser_currentSubscription_product | null;
  state: ProductSubscriptionStatus | null;
  validFrom: CORE_IsoDateTime | null;
  validTo: CORE_IsoDateTime | null;
  createdAt: CORE_IsoDateTime | null;
}

export interface CurrentUser_parsedPhoneNumber {
  __typename: "ParsedPhoneNumber";
  countryCode: string;
  nationalNumber: string;
  formattedNumber: string;
}

export interface CurrentUser_paymentMethod_Blik {
  __typename: "Blik" | "AcssDebit" | "Affirm" | "AfterpayClearpay" | "Alipay" | "AuBecsDebit" | "BacsDebit" | "Bancontact" | "Cashapp" | "CustomerBalance" | "Eps" | "Fpx" | "Giropay" | "Grabpay" | "Ideal" | "Klarna" | "Konbini" | "Link" | "Oxxo" | "P24" | "Paynow" | "Paypal" | "Pix" | "Promptpay" | "RevolutPay" | "SepaDebit" | "Sofort" | "Swish" | "UsBankAccount" | "WechatPay" | "Zip";
}

export interface CurrentUser_paymentMethod_Card {
  __typename: "Card";
  id: string;
  /**
   * Represents expiration date in MM/YY format
   */
  expiresAt: string;
  expired: boolean;
  last4: string;
  brand: CardBrand;
  /**
   * Display version of brand, eg american_express => American Express
   */
  displayBrand: string;
  /**
   * Indicates whether can payment method be removed or not, due to debt or active trip
   */
  removable: boolean;
}

export type CurrentUser_paymentMethod = CurrentUser_paymentMethod_Blik | CurrentUser_paymentMethod_Card;

export interface CurrentUser_productRenewals_ProductRenewalPending_product {
  __typename: "Product";
  id: string;
}

export interface CurrentUser_productRenewals_ProductRenewalPending {
  __typename: "ProductRenewalPending";
  product: CurrentUser_productRenewals_ProductRenewalPending_product;
}

export interface CurrentUser_productRenewals_ProductRenewalScheduled_product {
  __typename: "Product";
  id: string;
  price: CORE_Money | null;
}

export interface CurrentUser_productRenewals_ProductRenewalScheduled {
  __typename: "ProductRenewalScheduled";
  product: CurrentUser_productRenewals_ProductRenewalScheduled_product;
  scheduledAt: CORE_IsoDateTime;
}

export interface CurrentUser_productRenewals_ProductRenewalFailed_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface CurrentUser_productRenewals_ProductRenewalFailed {
  __typename: "ProductRenewalFailed";
  product: CurrentUser_productRenewals_ProductRenewalFailed_product;
}

export type CurrentUser_productRenewals = CurrentUser_productRenewals_ProductRenewalPending | CurrentUser_productRenewals_ProductRenewalScheduled | CurrentUser_productRenewals_ProductRenewalFailed;

export interface CurrentUser_wallet {
  __typename: "UserWallet";
  enabled: boolean;
  balance: CORE_Money;
  currency: string;
}

export interface CurrentUser_currentTrip {
  __typename: "Trip";
  id: string;
}

export interface CurrentUser {
  __typename: "User";
  id: string;
  name: string | null;
  email: string | null;
  gender: Gender | null;
  birthYear: string | null;
  preferredLanguageCode: string | null;
  postalCode: string | null;
  havePinCode: boolean | null;
  currentSubscription: CurrentUser_currentSubscription | null;
  phoneNumber: string;
  parsedPhoneNumber: CurrentUser_parsedPhoneNumber;
  penaltyStatus: PenaltyStatus;
  agreedToReceiveEmail: boolean | null;
  agreedToTerms: boolean;
  agreedToReceiveExternalPromotions: boolean | null;
  agreedToRegistrationAgreementWithdrawal: boolean;
  agreedToSubscriptionAgreementWithdrawal: boolean;
  agreedToSendDataToThirdParties: boolean | null;
  paymentMethod: CurrentUser_paymentMethod | null;
  requiresAddingPaymentCard: boolean | null;
  hasUnpaidOrders: boolean | null;
  /**
   * List of product renewals
   */
  productRenewals: CurrentUser_productRenewals[];
  superpowers: boolean | null;
  rfid: string | null;
  wallet: CurrentUser_wallet | null;
  currentTrip: CurrentUser_currentTrip | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FeatureFigureModule
// ====================================================

export interface FeatureFigureModule {
  __typename: "SanityFeatureFigureVideoGuide";
  _key: string;
  _type: string | null;
  video: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FeatureModule
// ====================================================

export interface FeatureModule_figureModule {
  __typename: "SanityFeatureFigureVideoGuide";
  _key: string;
  _type: string | null;
  video: string | null;
}

export interface FeatureModule {
  __typename: "SanityFeatureModule";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
  figureModule: FeatureModule_figureModule[] | null;
  hideFromApps: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MultiFeatureModule
// ====================================================

export interface MultiFeatureModule_featureModules_figureModule {
  __typename: "SanityFeatureFigureVideoGuide";
  _key: string;
  _type: string | null;
  video: string | null;
}

export interface MultiFeatureModule_featureModules {
  __typename: "SanityMultiFigureModule";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
  figureModule: MultiFeatureModule_featureModules_figureModule[] | null;
}

export interface MultiFeatureModule {
  __typename: "SanityMultiFeatureModule";
  _key: string;
  title: string | null;
  featureModules: MultiFeatureModule_featureModules[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FeatureModuleTripDuration
// ====================================================

export interface FeatureModuleTripDuration {
  __typename: "SanityFeatureModuleTripDuration";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FeatureVideo
// ====================================================

export interface FeatureVideo_poster1x1 {
  __typename: "SanityImage";
  url: string | null;
}

export interface FeatureVideo_poster16x9 {
  __typename: "SanityImage";
  url: string | null;
}

export interface FeatureVideo {
  __typename: "SanityFeatureVideo";
  _key: string;
  title: string | null;
  videoUrl: string | null;
  autoplay: boolean | null;
  poster1x1: FeatureVideo_poster1x1 | null;
  poster16x9: FeatureVideo_poster16x9 | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FeatureImage
// ====================================================

export interface FeatureImage_image_metadata_dimensions {
  __typename: "SanityImageMetaDataDimensions";
  width: number | null;
  height: number | null;
}

export interface FeatureImage_image_metadata {
  __typename: "SanityImageMetaData";
  dimensions: FeatureImage_image_metadata_dimensions | null;
}

export interface FeatureImage_image {
  __typename: "SanityImage";
  url: string | null;
  metadata: FeatureImage_image_metadata | null;
}

export interface FeatureImage_link_page_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface FeatureImage_link_page {
  __typename: "SanityLinkPage";
  _id: string;
  _type: string;
  slug: FeatureImage_link_page_slug | null;
}

export interface FeatureImage_link {
  __typename: "SanityLinkOnly";
  _key: string | null;
  page: FeatureImage_link_page | null;
  externalLink: string | null;
  showIntercom: boolean | null;
}

export interface FeatureImage {
  __typename: "SanityFeatureImage";
  _key: string;
  /**
   * Internal block name
   */
  blockName: string | null;
  alt: string | null;
  image: FeatureImage_image | null;
  variant: SanityFeatureModuleVariant | null;
  hideFromApps: boolean | null;
  link: FeatureImage_link | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: HowItWorksFeatureBlock
// ====================================================

export interface HowItWorksFeatureBlock_steps {
  __typename: "SanityHowWorksStep";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
}

export interface HowItWorksFeatureBlock {
  __typename: "SanityHowItWorksFeatureBlock";
  _key: string;
  steps: HowItWorksFeatureBlock_steps[] | null;
  demonstrationVideo: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StationMapFeatureBlock
// ====================================================

export interface StationMapFeatureBlock {
  __typename: "SanityStationMapFeatureBlock";
  _key: string;
  description: CORE_JSON | null;
  variant: SanityFeatureModuleVariant | null;
  hideFromApps: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TripStatsFeatureBlock
// ====================================================

export interface TripStatsFeatureBlock_phraseGroups {
  __typename: "SanityTripStatsFeatureBlockTripCountPhraseGroup";
  _key: string;
  phrases: (string | null)[] | null;
  threshold: number;
}

export interface TripStatsFeatureBlock {
  __typename: "SanityTripStatsFeatureBlock";
  _key: string;
  variant: SanityFeatureModuleVariant | null;
  hideFromApps: boolean | null;
  phraseGroups: (TripStatsFeatureBlock_phraseGroups | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageFeatureBlock
// ====================================================

export interface PageFeatureBlock {
  __typename: "SanityPageFeatureBlock";
  _key: string;
  title: string | null;
  pageRefType: string | null;
  variant: SanityFeatureModuleVariant | null;
  hideFromApps: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InfoBlock
// ====================================================

export interface InfoBlock_link_page_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface InfoBlock_link_page {
  __typename: "SanitySettingsNavigationPage";
  _id: string;
  _type: string;
  slug: InfoBlock_link_page_slug | null;
}

export interface InfoBlock_link {
  __typename: "SanitySettingsNavigation";
  page: InfoBlock_link_page | null;
  externalLink: string | null;
  showIntercom: boolean | null;
  title: string | null;
  _key: string | null;
}

export interface InfoBlock {
  __typename: "SanityInfoBlock";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
  emotion: string | null;
  variant: SanityFeatureModuleVariant | null;
  hideFromApps: boolean | null;
  link: InfoBlock_link | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FeatureBlocks
// ====================================================

export interface FeatureBlocks_SanityFeatureModule_figureModule {
  __typename: "SanityFeatureFigureVideoGuide";
  _key: string;
  _type: string | null;
  video: string | null;
}

export interface FeatureBlocks_SanityFeatureModule {
  __typename: "SanityFeatureModule";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
  figureModule: FeatureBlocks_SanityFeatureModule_figureModule[] | null;
  hideFromApps: boolean | null;
}

export interface FeatureBlocks_SanityMultiFeatureModule_featureModules_figureModule {
  __typename: "SanityFeatureFigureVideoGuide";
  _key: string;
  _type: string | null;
  video: string | null;
}

export interface FeatureBlocks_SanityMultiFeatureModule_featureModules {
  __typename: "SanityMultiFigureModule";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
  figureModule: FeatureBlocks_SanityMultiFeatureModule_featureModules_figureModule[] | null;
}

export interface FeatureBlocks_SanityMultiFeatureModule {
  __typename: "SanityMultiFeatureModule";
  _key: string;
  title: string | null;
  featureModules: FeatureBlocks_SanityMultiFeatureModule_featureModules[] | null;
}

export interface FeatureBlocks_SanityFeatureModuleTripDuration {
  __typename: "SanityFeatureModuleTripDuration";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
}

export interface FeatureBlocks_SanityFeatureVideo_poster1x1 {
  __typename: "SanityImage";
  url: string | null;
}

export interface FeatureBlocks_SanityFeatureVideo_poster16x9 {
  __typename: "SanityImage";
  url: string | null;
}

export interface FeatureBlocks_SanityFeatureVideo {
  __typename: "SanityFeatureVideo";
  _key: string;
  title: string | null;
  videoUrl: string | null;
  autoplay: boolean | null;
  poster1x1: FeatureBlocks_SanityFeatureVideo_poster1x1 | null;
  poster16x9: FeatureBlocks_SanityFeatureVideo_poster16x9 | null;
}

export interface FeatureBlocks_SanityFeatureImage_image_metadata_dimensions {
  __typename: "SanityImageMetaDataDimensions";
  width: number | null;
  height: number | null;
}

export interface FeatureBlocks_SanityFeatureImage_image_metadata {
  __typename: "SanityImageMetaData";
  dimensions: FeatureBlocks_SanityFeatureImage_image_metadata_dimensions | null;
}

export interface FeatureBlocks_SanityFeatureImage_image {
  __typename: "SanityImage";
  url: string | null;
  metadata: FeatureBlocks_SanityFeatureImage_image_metadata | null;
}

export interface FeatureBlocks_SanityFeatureImage_link_page_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface FeatureBlocks_SanityFeatureImage_link_page {
  __typename: "SanityLinkPage";
  _id: string;
  _type: string;
  slug: FeatureBlocks_SanityFeatureImage_link_page_slug | null;
}

export interface FeatureBlocks_SanityFeatureImage_link {
  __typename: "SanityLinkOnly";
  _key: string | null;
  page: FeatureBlocks_SanityFeatureImage_link_page | null;
  externalLink: string | null;
  showIntercom: boolean | null;
}

export interface FeatureBlocks_SanityFeatureImage {
  __typename: "SanityFeatureImage";
  _key: string;
  /**
   * Internal block name
   */
  blockName: string | null;
  alt: string | null;
  image: FeatureBlocks_SanityFeatureImage_image | null;
  variant: SanityFeatureModuleVariant | null;
  hideFromApps: boolean | null;
  link: FeatureBlocks_SanityFeatureImage_link | null;
}

export interface FeatureBlocks_SanityHowItWorksFeatureBlock_steps {
  __typename: "SanityHowWorksStep";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
}

export interface FeatureBlocks_SanityHowItWorksFeatureBlock {
  __typename: "SanityHowItWorksFeatureBlock";
  _key: string;
  steps: FeatureBlocks_SanityHowItWorksFeatureBlock_steps[] | null;
  demonstrationVideo: string | null;
}

export interface FeatureBlocks_SanityStationMapFeatureBlock {
  __typename: "SanityStationMapFeatureBlock";
  _key: string;
  description: CORE_JSON | null;
  variant: SanityFeatureModuleVariant | null;
  hideFromApps: boolean | null;
}

export interface FeatureBlocks_SanityTripStatsFeatureBlock_phraseGroups {
  __typename: "SanityTripStatsFeatureBlockTripCountPhraseGroup";
  _key: string;
  phrases: (string | null)[] | null;
  threshold: number;
}

export interface FeatureBlocks_SanityTripStatsFeatureBlock {
  __typename: "SanityTripStatsFeatureBlock";
  _key: string;
  variant: SanityFeatureModuleVariant | null;
  hideFromApps: boolean | null;
  phraseGroups: (FeatureBlocks_SanityTripStatsFeatureBlock_phraseGroups | null)[] | null;
}

export interface FeatureBlocks_SanityPageFeatureBlock {
  __typename: "SanityPageFeatureBlock";
  _key: string;
  title: string | null;
  pageRefType: string | null;
  variant: SanityFeatureModuleVariant | null;
  hideFromApps: boolean | null;
}

export interface FeatureBlocks_SanityInfoBlock_link_page_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface FeatureBlocks_SanityInfoBlock_link_page {
  __typename: "SanitySettingsNavigationPage";
  _id: string;
  _type: string;
  slug: FeatureBlocks_SanityInfoBlock_link_page_slug | null;
}

export interface FeatureBlocks_SanityInfoBlock_link {
  __typename: "SanitySettingsNavigation";
  page: FeatureBlocks_SanityInfoBlock_link_page | null;
  externalLink: string | null;
  showIntercom: boolean | null;
  title: string | null;
  _key: string | null;
}

export interface FeatureBlocks_SanityInfoBlock {
  __typename: "SanityInfoBlock";
  _key: string;
  title: string | null;
  description: CORE_JSON | null;
  emotion: string | null;
  variant: SanityFeatureModuleVariant | null;
  hideFromApps: boolean | null;
  link: FeatureBlocks_SanityInfoBlock_link | null;
}

export type FeatureBlocks = FeatureBlocks_SanityFeatureModule | FeatureBlocks_SanityMultiFeatureModule | FeatureBlocks_SanityFeatureModuleTripDuration | FeatureBlocks_SanityFeatureVideo | FeatureBlocks_SanityFeatureImage | FeatureBlocks_SanityHowItWorksFeatureBlock | FeatureBlocks_SanityStationMapFeatureBlock | FeatureBlocks_SanityTripStatsFeatureBlock | FeatureBlocks_SanityPageFeatureBlock | FeatureBlocks_SanityInfoBlock;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ListProduct
// ====================================================

export interface ListProduct_image {
  __typename: "SanityImage";
  url: string | null;
}

export interface ListProduct_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface ListProduct {
  __typename: "Product";
  id: string;
  /**
   * Localised product description
   */
  descriptionText: string | null;
  name: string | null;
  image: ListProduct_image | null;
  slug: ListProduct_slug | null;
  price: CORE_Money | null;
  campaign: string | null;
  purchasable: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Navigation
// ====================================================

export interface Navigation_page_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface Navigation_page {
  __typename: "SanitySettingsNavigationPage";
  _id: string;
  _type: string;
  slug: Navigation_page_slug | null;
}

export interface Navigation {
  __typename: "SanitySettingsNavigation";
  page: Navigation_page | null;
  externalLink: string | null;
  showIntercom: boolean | null;
  title: string | null;
  _key: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FooterNavColumn
// ====================================================

export interface FooterNavColumn_items_page_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface FooterNavColumn_items_page {
  __typename: "SanitySettingsNavigationPage";
  _id: string;
  _type: string;
  slug: FooterNavColumn_items_page_slug | null;
}

export interface FooterNavColumn_items {
  __typename: "SanitySettingsNavigation";
  page: FooterNavColumn_items_page | null;
  externalLink: string | null;
  showIntercom: boolean | null;
  title: string | null;
  _key: string | null;
}

export interface FooterNavColumn {
  __typename: "SanityFooterNavColumn";
  _key: string;
  title: string | null;
  items: (FooterNavColumn_items | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Settings
// ====================================================

export interface Settings_primaryCta {
  __typename: "SanitySlug";
  current: string;
}

export interface Settings_privacyPageSlug {
  __typename: "SanitySlug";
  current: string;
}

export interface Settings_header_ctaButton_page {
  __typename: "SanitySettingsNavigationPage";
  _type: string;
}

export interface Settings_header_ctaButton {
  __typename: "SanitySettingsNavigation";
  title: string | null;
  page: Settings_header_ctaButton_page | null;
  externalLink: string | null;
  showIntercom: boolean | null;
}

export interface Settings_header {
  __typename: "SanityHeader";
  ctaButton: Settings_header_ctaButton | null;
}

export interface Settings_footer_navColumns_items_page_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface Settings_footer_navColumns_items_page {
  __typename: "SanitySettingsNavigationPage";
  _id: string;
  _type: string;
  slug: Settings_footer_navColumns_items_page_slug | null;
}

export interface Settings_footer_navColumns_items {
  __typename: "SanitySettingsNavigation";
  page: Settings_footer_navColumns_items_page | null;
  externalLink: string | null;
  showIntercom: boolean | null;
  title: string | null;
  _key: string | null;
}

export interface Settings_footer_navColumns {
  __typename: "SanityFooterNavColumn";
  _key: string;
  title: string | null;
  items: (Settings_footer_navColumns_items | null)[] | null;
}

export interface Settings_footer_socialMediaLinks {
  __typename: "SanitySocialMediaLink";
  _key: string;
  name: SocialMediaName | null;
  url: string | null;
}

export interface Settings_footer {
  __typename: "SanityFooter";
  aboutOperator: (CORE_JSON | null)[] | null;
  helpText: (CORE_JSON | null)[] | null;
  navColumns: (Settings_footer_navColumns | null)[] | null;
  socialMediaLinks: Settings_footer_socialMediaLinks[] | null;
}

export interface Settings_navigation_page_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface Settings_navigation_page {
  __typename: "SanitySettingsNavigationPage";
  _id: string;
  _type: string;
  slug: Settings_navigation_page_slug | null;
}

export interface Settings_navigation {
  __typename: "SanitySettingsNavigation";
  page: Settings_navigation_page | null;
  externalLink: string | null;
  showIntercom: boolean | null;
  title: string | null;
  _key: string | null;
}

export interface Settings {
  __typename: "SanitySettings";
  title: string;
  primaryCta: Settings_primaryCta | null;
  privacyPageSlug: Settings_privacyPageSlug | null;
  header: Settings_header | null;
  footer: Settings_footer | null;
  navigation: Settings_navigation[] | null;
  /**
   * BlockContent to be used in the cookie notification. If null, cookie notification is not shown on public-web
   */
  cookieNotification: CORE_JSON | null;
  strictlyNecessaryCookiesContent: CORE_JSON | null;
  performanceCookiesContent: CORE_JSON | null;
  functionalCookiesContent: CORE_JSON | null;
  targetingCookiesContent: CORE_JSON | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ReceiptOrder
// ====================================================

export interface ReceiptOrder_valueCodes_product {
  __typename: "Product";
  id: string;
  name: string | null;
}

export interface ReceiptOrder_valueCodes {
  __typename: "ValueCode";
  id: string | null;
  token: string;
  product: ReceiptOrder_valueCodes_product | null;
}

export interface ReceiptOrder_paymentTransactions {
  __typename: "PaymentTransaction";
  /**
   * Payment transaction ID
   */
  id: string;
  /**
   * Transaction number at provider
   */
  transactionNumber: string | null;
  /**
   * Payment transaction status
   */
  status: PaymentTransactionStatus;
}

export interface ReceiptOrder_walletTransactions {
  __typename: "WalletTransaction";
  id: string;
  orderId: string;
  createdAt: CORE_IsoDateTime;
  type: WalletTransactionType;
  amount: CORE_Money;
}

export interface ReceiptOrder_discount_discountAmount_RelativeDiscountAmount {
  __typename: "RelativeDiscountAmount";
  amount: CORE_PositiveFloat;
  absoluteAmount: CORE_Money;
}

export interface ReceiptOrder_discount_discountAmount_AbsoluteDiscountAmount {
  __typename: "AbsoluteDiscountAmount";
  amount: CORE_PositiveFloat;
}

export type ReceiptOrder_discount_discountAmount = ReceiptOrder_discount_discountAmount_RelativeDiscountAmount | ReceiptOrder_discount_discountAmount_AbsoluteDiscountAmount;

export interface ReceiptOrder_discount {
  __typename: "Discount";
  /**
   * Defines discount amount
   */
  discountAmount: ReceiptOrder_discount_discountAmount;
  /**
   * Price with discount
   */
  totalAmountDiscounted: CORE_Money;
  /**
   * Total vat amount with discount
   */
  totalVatAmountDiscounted: CORE_Money | null;
  /**
   * Represents total amount of discount in absolute values depending on
   * discounted items quantity
   */
  totalDiscountAmount: CORE_Money;
}

export interface ReceiptOrder_orderLines_product {
  __typename: "Product";
  id: string;
  name: string | null;
  price: CORE_Money | null;
}

export interface ReceiptOrder_orderLines_valueCodes {
  __typename: "ValueCode";
  id: string | null;
  code: string;
  redeemedAt: CORE_IsoDateTime | null;
}

export interface ReceiptOrder_orderLines_discount_discountAmount_RelativeDiscountAmount {
  __typename: "RelativeDiscountAmount";
  amount: CORE_PositiveFloat;
  absoluteAmount: CORE_Money;
}

export interface ReceiptOrder_orderLines_discount_discountAmount_AbsoluteDiscountAmount {
  __typename: "AbsoluteDiscountAmount";
  amount: CORE_PositiveFloat;
}

export type ReceiptOrder_orderLines_discount_discountAmount = ReceiptOrder_orderLines_discount_discountAmount_RelativeDiscountAmount | ReceiptOrder_orderLines_discount_discountAmount_AbsoluteDiscountAmount;

export interface ReceiptOrder_orderLines_discount {
  __typename: "Discount";
  /**
   * Defines discount amount
   */
  discountAmount: ReceiptOrder_orderLines_discount_discountAmount;
  /**
   * Defines localized discount campaign name
   */
  name: string;
  /**
   * Defines how many products will be discounted
   */
  quantityDiscounted: CORE_PositiveInt;
  /**
   * Price with discount
   */
  totalAmountDiscounted: CORE_Money;
  /**
   * Represents total amount of discount in absolute values depending on
   * discounted items quantity
   */
  totalDiscountAmount: CORE_Money;
}

export interface ReceiptOrder_orderLines {
  __typename: "OrderLine";
  /**
   * Order line ID
   */
  id: string;
  /**
   * Order line type
   */
  type: OrderLineType | null;
  /**
   * Quantity of product or price matrix items
   */
  quantity: number;
  /**
   * Product amount
   */
  amount: CORE_Money;
  /**
   * Total amount is computed by quantity times amount
   */
  totalAmount: CORE_Money;
  /**
   * Product amount without VAT
   */
  amountWithoutVat: CORE_Money;
  /**
   * Localised title for the order line
   * @deprecated(reason: "Use title")
   */
  orderLineTitle: string | null;
  /**
   * The product for the order line
   */
  product: ReceiptOrder_orderLines_product | null;
  /**
   * Any value codes connected to the order line
   */
  valueCodes: (ReceiptOrder_orderLines_valueCodes | null)[] | null;
  /**
   * Applied discount
   */
  discount: ReceiptOrder_orderLines_discount | null;
}

export interface ReceiptOrder_user {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface ReceiptOrder_company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface ReceiptOrder_trip_startDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
}

export interface ReceiptOrder_trip_startDock {
  __typename: "Dock";
  id: string;
  dockGroup: ReceiptOrder_trip_startDock_dockGroup;
}

export interface ReceiptOrder_trip_endDock_dockGroup {
  __typename: "DockGroup";
  id: string;
  name: string | null;
  title: string | null;
}

export interface ReceiptOrder_trip_endDock {
  __typename: "Dock";
  id: string;
  dockGroup: ReceiptOrder_trip_endDock_dockGroup;
}

export interface ReceiptOrder_trip_startLocation_location {
  __typename: "Coord";
  lat: number | null;
  lng: number | null;
}

export interface ReceiptOrder_trip_startLocation {
  __typename: "VehicleLocation";
  location: ReceiptOrder_trip_startLocation_location;
  address: string | null;
}

export interface ReceiptOrder_trip_endLocation_location {
  __typename: "Coord";
  lat: number | null;
  lng: number | null;
}

export interface ReceiptOrder_trip_endLocation {
  __typename: "VehicleLocation";
  location: ReceiptOrder_trip_endLocation_location;
  address: string | null;
}

export interface ReceiptOrder_trip {
  __typename: "Trip";
  id: string;
  startedAt: CORE_IsoDateTime | null;
  endedAt: CORE_IsoDateTime | null;
  startDock: ReceiptOrder_trip_startDock | null;
  endDock: ReceiptOrder_trip_endDock | null;
  startLocation: ReceiptOrder_trip_startLocation | null;
  endLocation: ReceiptOrder_trip_endLocation | null;
  duration: number | null;
  /**
   * Included free time to the trip (not live updated), represent duration length of first free item from Price Matrix
   * so it's not affected by Trip Additional Minutes at all
   */
  freePeriodMins: number;
  /**
   * Current paid duration (live updated) of the trip
   */
  paidPeriodMins: number;
}

export interface ReceiptOrder {
  __typename: "Order";
  /**
   * Order ID
   */
  id: string;
  /**
   * Total order amount
   */
  amount: CORE_Money | null;
  /**
   * Total order amount without VAT
   */
  amountWithoutVat: CORE_Money | null;
  /**
   * Currency for the order amount
   */
  currency: string;
  /**
   * Customer's email
   */
  customerEmail: string | null;
  /**
   * Total order vat amount
   */
  vatAmount: CORE_Money | null;
  /**
   * Order type
   */
  type: OrderType;
  /**
   * Completed at datetime
   */
  completedAt: CORE_IsoDateTime | null;
  /**
   * The masked credit card the order was processed with. Not exposed if the user is not logged in
   */
  creditCardMasked: string | null;
  /**
   * Value codes
   */
  valueCodes: (ReceiptOrder_valueCodes | null)[] | null;
  /**
   * Payment transactions
   */
  paymentTransactions: ReceiptOrder_paymentTransactions[];
  /**
   * Linked Wallet transactions; not empty if this is a "wallet deposit" order, or if the order was paid via Wallet balance
   */
  walletTransactions: ReceiptOrder_walletTransactions[];
  /**
   * Applied discount
   */
  discount: ReceiptOrder_discount | null;
  /**
   * Order lines
   */
  orderLines: (ReceiptOrder_orderLines | null)[];
  /**
   * User
   */
  user: ReceiptOrder_user | null;
  /**
   * B2B Company
   */
  company: ReceiptOrder_company | null;
  /**
   * The trip connected to order. Applies for pay2go and extended_rental orders
   */
  trip: ReceiptOrder_trip | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ListSystemAlert
// ====================================================

export interface ListSystemAlert_dockGroups {
  __typename: "DockGroup";
  id: string;
  title: string | null;
}

export interface ListSystemAlert_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface ListSystemAlert_updates {
  __typename: "SystemAlertUpdate";
  id: string;
  createdAt: CORE_IsoDateTime;
  title: string | null;
  description: string | null;
}

export interface ListSystemAlert {
  __typename: "SystemAlert";
  id: string;
  resolvedAt: CORE_IsoDateTime | null;
  dockGroups: (ListSystemAlert_dockGroups | null)[] | null;
  systemAlertType: SystemAlertType;
  incidentStartTime: CORE_IsoDateTime;
  incidentEndTime: CORE_IsoDateTime | null;
  title: string | null;
  description: string | null;
  visibility: SystemAlertVisibility;
  slug: ListSystemAlert_slug | null;
  updates: (ListSystemAlert_updates | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BuyGiftCardProduct
// ====================================================

export interface BuyGiftCardProduct_slug {
  __typename: "SanitySlug";
  current: string;
}

export interface BuyGiftCardProduct_connectionPriceMatrixes_priceMatrix_matrix {
  __typename: "PriceMatrixItem";
  minutes: number;
  price: CORE_Money;
  quantity: number;
  type: PriceMatrixItemType;
}

export interface BuyGiftCardProduct_connectionPriceMatrixes_priceMatrix {
  __typename: "PriceMatrix";
  lastStepInfinite: boolean;
  matrix: BuyGiftCardProduct_connectionPriceMatrixes_priceMatrix_matrix[];
}

export interface BuyGiftCardProduct_connectionPriceMatrixes {
  __typename: "ConnectionPriceMatrix";
  id: string;
  type: ConnectionPriceMatrixType;
  priceMatrix: BuyGiftCardProduct_connectionPriceMatrixes_priceMatrix;
  vehicleCategory: VehicleCategory2;
}

export interface BuyGiftCardProduct {
  __typename: "Product";
  id: string;
  /**
   * Localised product description
   */
  descriptionText: string | null;
  name: string | null;
  price: CORE_Money | null;
  extendedRentalPeriodCharge: CORE_Money | null;
  tripStartCharge: CORE_Money | null;
  rentalMeterType: RentalMeterType | null;
  campaign: string | null;
  slug: BuyGiftCardProduct_slug | null;
  connectionPriceMatrixes: BuyGiftCardProduct_connectionPriceMatrixes[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PlaceSubscriptionOrder
// ====================================================

export interface PlaceSubscriptionOrder_discount_partner {
  __typename: "Partner";
  id: PartnerId;
  name: string;
}

export interface PlaceSubscriptionOrder_discount_discountAmount_RelativeDiscountAmount {
  __typename: "RelativeDiscountAmount";
  amount: CORE_PositiveFloat;
}

export interface PlaceSubscriptionOrder_discount_discountAmount_AbsoluteDiscountAmount {
  __typename: "AbsoluteDiscountAmount";
  amount: CORE_PositiveFloat;
}

export type PlaceSubscriptionOrder_discount_discountAmount = PlaceSubscriptionOrder_discount_discountAmount_RelativeDiscountAmount | PlaceSubscriptionOrder_discount_discountAmount_AbsoluteDiscountAmount;

export interface PlaceSubscriptionOrder_discount {
  __typename: "Discount";
  /**
   * Defines localized discount campaign name
   */
  name: string;
  /**
   * Defines how many products will be discounted
   */
  quantityDiscounted: CORE_PositiveInt;
  /**
   * Price with discount
   */
  totalAmountDiscounted: CORE_Money;
  /**
   * Defines associated with discount partner
   */
  partner: PlaceSubscriptionOrder_discount_partner | null;
  /**
   * Defines discount amount
   */
  discountAmount: PlaceSubscriptionOrder_discount_discountAmount;
}

export interface PlaceSubscriptionOrder {
  __typename: "Order";
  /**
   * Order ID
   */
  id: string;
  /**
   * Order status
   */
  status: OrderStatus | null;
  /**
   * Order type
   */
  type: OrderType;
  /**
   * Total order amount
   */
  amount: CORE_Money | null;
  /**
   * Total order vat amount
   */
  vatAmount: CORE_Money | null;
  /**
   * Average vat rate
   */
  vatRate: number | null;
  /**
   * Currency for the order amount
   */
  currency: string;
  /**
   * Order token, external ID, to use publicly in URLs
   */
  token: string;
  /**
   * Completed at datetime
   */
  completedAt: CORE_IsoDateTime | null;
  /**
   * Applied discount
   */
  discount: PlaceSubscriptionOrder_discount | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MilanATMCardFragment
// ====================================================

export interface MilanATMCardFragment {
  __typename: "MilanATMCard";
  identificationNumber: number;
  isValid: boolean;
  partnerId: PartnerId;
  serialNumber: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AnonymousValueCodeOrderErrorCode {
  ERR_NOT_FOUND = "ERR_NOT_FOUND",
  ERR_PRODUCT_NOT_GIFTABLE = "ERR_PRODUCT_NOT_GIFTABLE",
  ERR_PRODUCT_NOT_PURCHASABLE = "ERR_PRODUCT_NOT_PURCHASABLE",
  ERR_VALIDATION_FAILED = "ERR_VALIDATION_FAILED",
}

export enum AppOs {
  Android = "Android",
  iOS = "iOS",
}

export enum AttachPartnerAccountErrorCode {
  ERR_PARTNER = "ERR_PARTNER",
  ERR_PARTNER_ACCOUNT_ALREADY_EXISTS = "ERR_PARTNER_ACCOUNT_ALREADY_EXISTS",
  ERR_PARTNER_ACCOUNT_INVALID = "ERR_PARTNER_ACCOUNT_INVALID",
  ERR_PARTNER_ACCOUNT_NOT_FOUND = "ERR_PARTNER_ACCOUNT_NOT_FOUND",
  ERR_PARTNER_NOT_SUPPORTED = "ERR_PARTNER_NOT_SUPPORTED",
  ERR_PARTNER_VALIDATION = "ERR_PARTNER_VALIDATION",
}

export enum CardBrand {
  american_express = "american_express",
  amex = "amex",
  diners = "diners",
  discover = "discover",
  jcb = "jcb",
  maestro = "maestro",
  mastercard = "mastercard",
  unionpay = "unionpay",
  unknown = "unknown",
  visa = "visa",
}

export enum ConnectionPriceMatrixType {
  reservation = "reservation",
  trip = "trip",
}

export enum ConsentEnforcement {
  hidden = "hidden",
  optional = "optional",
  required = "required",
}

export enum DetachPartnerAccountErrorCode {
  ERR_PARTNER_ACCOUNT_NOT_FOUND = "ERR_PARTNER_ACCOUNT_NOT_FOUND",
}

export enum DiscountCodeErrorCode {
  ERR_DISCOUNT = "ERR_DISCOUNT",
  ERR_DISCOUNT_INACTIVE = "ERR_DISCOUNT_INACTIVE",
  ERR_DISCOUNT_LIMIT_REACHED = "ERR_DISCOUNT_LIMIT_REACHED",
  ERR_DISCOUNT_NOT_FOUND = "ERR_DISCOUNT_NOT_FOUND",
  ERR_DISCOUNT_USED = "ERR_DISCOUNT_USED",
}

export enum DockGroupState {
  active = "active",
  decommissioned = "decommissioned",
  maintenance = "maintenance",
  not_in_service = "not_in_service",
  planning = "planning",
  testing = "testing",
}

export enum Gender {
  female = "female",
  male = "male",
  other = "other",
}

export enum GeoJSONType {
  LineString = "LineString",
  Point = "Point",
}

export enum InvoiceSortField {
  createdAt = "createdAt",
  id = "id",
  status = "status",
}

export enum InvoiceStatus {
  collection_failed = "collection_failed",
  draft = "draft",
  open = "open",
  paid = "paid",
  uncollectible = "uncollectible",
  void = "void",
}

/**
 * The language codes we support in the clients, ISO 639
 */
export enum LanguageCode {
  de = "de",
  en = "en",
  es = "es",
  fi = "fi",
  fr = "fr",
  it = "it",
  nb = "nb",
  pl = "pl",
  sv = "sv",
  uk = "uk",
}

export enum LegalConsentType {
  registration_agreement_withdrawal = "registration_agreement_withdrawal",
  send_data_to_third_parties = "send_data_to_third_parties",
  subscription_agreement_withdrawal = "subscription_agreement_withdrawal",
  terms_of_use_and_privacy_policy = "terms_of_use_and_privacy_policy",
}

export enum OrderDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum OrderLineType {
  business_registration_fee = "business_registration_fee",
  business_regular_charge = "business_regular_charge",
  extended_rental = "extended_rental",
  free_floating_docking_fee = "free_floating_docking_fee",
  free_minutes_per_day = "free_minutes_per_day",
  pay2go = "pay2go",
  registration_fee = "registration_fee",
  reservation = "reservation",
  subscription = "subscription",
  trip = "trip",
  value_code = "value_code",
  verification = "verification",
  wallet_deposit = "wallet_deposit",
}

export enum OrderPaymentState {
  CANCELLED = "CANCELLED",
  DECLINED = "DECLINED",
  ERROR = "ERROR",
  ORDER_NOT_FOUND = "ORDER_NOT_FOUND",
  PAYMENT_NOT_FOUND = "PAYMENT_NOT_FOUND",
  PENDING_COMPLETE = "PENDING_COMPLETE",
  SUCCESS = "SUCCESS",
}

export enum OrderStatus {
  cancelled = "cancelled",
  completed = "completed",
  created = "created",
  paid = "paid",
  partially_refunded = "partially_refunded",
  payment_failed = "payment_failed",
  payment_postponed = "payment_postponed",
  pending_payment = "pending_payment",
  pending_refund = "pending_refund",
  refunded = "refunded",
}

export enum OrderType {
  business_registration_fee = "business_registration_fee",
  business_regular_charge = "business_regular_charge",
  extended_rental = "extended_rental",
  pay2go = "pay2go",
  registration_fee = "registration_fee",
  reservation = "reservation",
  subscription = "subscription",
  trip = "trip",
  value_code = "value_code",
  verification = "verification",
  wallet_deposit = "wallet_deposit",
}

export enum PartnerDiscountErrorCode {
  ERR_DISCOUNT = "ERR_DISCOUNT",
  ERR_DISCOUNT_INACTIVE = "ERR_DISCOUNT_INACTIVE",
  ERR_DISCOUNT_LIMIT_REACHED = "ERR_DISCOUNT_LIMIT_REACHED",
  ERR_DISCOUNT_NOT_FOUND = "ERR_DISCOUNT_NOT_FOUND",
  ERR_DISCOUNT_USED = "ERR_DISCOUNT_USED",
  ERR_PARTNER = "ERR_PARTNER",
  ERR_PARTNER_ACCOUNT_ALREADY_EXISTS = "ERR_PARTNER_ACCOUNT_ALREADY_EXISTS",
  ERR_PARTNER_ACCOUNT_INVALID = "ERR_PARTNER_ACCOUNT_INVALID",
  ERR_PARTNER_ACCOUNT_NOT_FOUND = "ERR_PARTNER_ACCOUNT_NOT_FOUND",
  ERR_PARTNER_NOT_SUPPORTED = "ERR_PARTNER_NOT_SUPPORTED",
  ERR_PARTNER_VALIDATION = "ERR_PARTNER_VALIDATION",
}

export enum PartnerId {
  milan_atm = "milan_atm",
  obos = "obos",
}

export enum PaymentDocumentType {
  invoice = "invoice",
  order = "order",
}

export enum PaymentIntentErrorCode {
  ERR_CHARGE_PAYMENT_TYPE_NOT_ALLOWED = "ERR_CHARGE_PAYMENT_TYPE_NOT_ALLOWED",
  ERR_FRAUDULENT = "ERR_FRAUDULENT",
  ERR_INSUFFICIENT_FUNDS = "ERR_INSUFFICIENT_FUNDS",
  ERR_PAYMENT_DECLINED = "ERR_PAYMENT_DECLINED",
  ERR_PAYMENT_PROCESSING_ERROR = "ERR_PAYMENT_PROCESSING_ERROR",
  ERR_USER_CARD_EXPIRED = "ERR_USER_CARD_EXPIRED",
  ERR_USER_NO_PAYMENT_METHOD = "ERR_USER_NO_PAYMENT_METHOD",
  ERR_WALLET_DISABLED = "ERR_WALLET_DISABLED",
}

export enum PaymentMethodType {
  any = "any",
  blik = "blik",
  card = "card",
}

export enum PaymentTransactionStatus {
  cancelled = "cancelled",
  completed = "completed",
  created = "created",
  failed = "failed",
  pending_authentication = "pending_authentication",
  scheduled = "scheduled",
}

export enum PenaltyStatus {
  blocked = "blocked",
  none = "none",
  severe_warning = "severe_warning",
  warning = "warning",
}

export enum PhoneVerificationErrorCode {
  ERR_CREATE_PHONE_VERIFICATION_RATE_LIMIT = "ERR_CREATE_PHONE_VERIFICATION_RATE_LIMIT",
  ERR_INVALID_PHONE_NUMBER = "ERR_INVALID_PHONE_NUMBER",
  ERR_INVALID_PHONE_VERIFICATION_CODE = "ERR_INVALID_PHONE_VERIFICATION_CODE",
  ERR_IN_USE_PHONE_NUMBER = "ERR_IN_USE_PHONE_NUMBER",
  ERR_PHONE_VERIFICATION_DENIED = "ERR_PHONE_VERIFICATION_DENIED",
  ERR_PHONE_VERIFICATION_ERROR = "ERR_PHONE_VERIFICATION_ERROR",
  ERR_PHONE_VERIFICATION_EXPIRED = "ERR_PHONE_VERIFICATION_EXPIRED",
  ERR_PHONE_VERIFICATION_NOT_FOUND = "ERR_PHONE_VERIFICATION_NOT_FOUND",
  ERR_PHONE_VERIFICATION_TERMS = "ERR_PHONE_VERIFICATION_TERMS",
  ERR_UNKNOWN_PHONE_NUMBER = "ERR_UNKNOWN_PHONE_NUMBER",
  ERR_USER_REGISTRATION_DISABLED = "ERR_USER_REGISTRATION_DISABLED",
}

export enum PriceMatrixItemType {
  free_minutes = "free_minutes",
  period = "period",
  unlock = "unlock",
}

export enum ProductDurationMethod {
  absolute = "absolute",
  relative = "relative",
}

export enum ProductOptMethod {
  disabled = "disabled",
  forced = "forced",
  in = "in",
  out = "out",
}

export enum ProductSubscriptionStatus {
  active = "active",
  disabled = "disabled",
  inactive = "inactive",
  pending = "pending",
}

export enum RentalMeterType {
  running = "running",
  standard = "standard",
}

export enum SanityFeatureModuleVariant {
  primary = "primary",
  subtleWhite = "subtleWhite",
}

export enum SanityImageOptionCrop {
  bottom = "bottom",
  center = "center",
  entropy = "entropy",
  focalpoint = "focalpoint",
  left = "left",
  right = "right",
  top = "top",
}

export enum SanityImageOptionFit {
  clip = "clip",
  crop = "crop",
  fill = "fill",
  fillmax = "fillmax",
  max = "max",
  min = "min",
  scale = "scale",
}

export enum SanityTermOfConditionType {
  data = "data",
  user = "user",
  vehicle = "vehicle",
}

export enum SocialMediaName {
  facebook = "facebook",
  instagram = "instagram",
  twitter = "twitter",
  youtube = "youtube",
}

export enum Sponsor {
  atb = "atb",
  foodora = "foodora",
  just_eat = "just_eat",
  obos = "obos",
}

export enum SubscriptionOrderErrorCode {
  ERR_ACCOUNT_ALREADY_HAS_SUBSCRIPTION = "ERR_ACCOUNT_ALREADY_HAS_SUBSCRIPTION",
  ERR_MISSING_AUTH_TOKEN = "ERR_MISSING_AUTH_TOKEN",
  ERR_NOT_FOUND = "ERR_NOT_FOUND",
  ERR_PRODUCT_NOT_PURCHASABLE = "ERR_PRODUCT_NOT_PURCHASABLE",
}

export enum SystemAlertType {
  other = "other",
  station_closure = "station_closure",
  station_move = "station_move",
  system_closure = "system_closure",
}

export enum SystemAlertVisibility {
  public_all = "public_all",
  public_web = "public_web",
}

export enum SystemType {
  bikesharing = "bikesharing",
  busshelters = "busshelters",
  carsharing = "carsharing",
  scootersharing = "scootersharing",
}

export enum TripState {
  cancelled = "cancelled",
  completed = "completed",
  in_progress = "in_progress",
  on_hold = "on_hold",
  starting = "starting",
}

export enum UpdateProductRenewalErrorCode {
  ERR_PRODUCT_NOT_RENEWABLE = "ERR_PRODUCT_NOT_RENEWABLE",
  ERR_USER_NO_SUBSCRIPTION = "ERR_USER_NO_SUBSCRIPTION",
}

export enum UserDataExportRequestFormat {
  JSON = "JSON",
}

export enum UserDataExportRequestRetentionPolicy {
  none = "none",
  remove_after_expiration = "remove_after_expiration",
}

export enum UserDataExportRequestState {
  error = "error",
  processed = "processed",
  removed = "removed",
  requested = "requested",
}

export enum UserRfidAssignMethod {
  scan_qr = "scan_qr",
  set_rfid = "set_rfid",
}

export enum ValueCodeOrderErrorCode {
  ERR_MISSING_AUTH_TOKEN = "ERR_MISSING_AUTH_TOKEN",
  ERR_NOT_FOUND = "ERR_NOT_FOUND",
  ERR_PRODUCT_NOT_GIFTABLE = "ERR_PRODUCT_NOT_GIFTABLE",
  ERR_PRODUCT_NOT_PURCHASABLE = "ERR_PRODUCT_NOT_PURCHASABLE",
  ERR_VALIDATION_FAILED = "ERR_VALIDATION_FAILED",
}

export enum VehicleCategory2 {
  bike = "bike",
  ebike = "ebike",
  ebike_with_childseat = "ebike_with_childseat",
  scooter = "scooter",
}

export enum VerificationMode {
  login = "login",
  signup = "signup",
  update = "update",
}

export enum WalletDepositOrderErrorCode {
  ERR_MISSING_AUTH_TOKEN = "ERR_MISSING_AUTH_TOKEN",
  ERR_NOT_FOUND = "ERR_NOT_FOUND",
  ERR_PAYMENT_INVALID_AMOUNT = "ERR_PAYMENT_INVALID_AMOUNT",
  ERR_WALLET_DISABLED = "ERR_WALLET_DISABLED",
}

export enum WalletEnablement {
  disabled = "disabled",
  forced = "forced",
  opt_in = "opt_in",
}

export enum WalletTransactionType {
  credit = "credit",
  credit_refund = "credit_refund",
  debit = "debit",
  debit_refund = "debit_refund",
}

export interface CreatePhoneVerificationInput {
  phoneNumber: string;
  mode: VerificationMode;
  termsAccepted?: boolean | null;
  legalConsents?: LegalConsentInput[] | null;
}

export interface LegalConsentInput {
  type: LegalConsentType;
  enforcement: ConsentEnforcement;
  accepted?: boolean | null;
}

export interface OrderLineInput {
  productId: string;
  quantity: CORE_PositiveInt;
}

export interface PartnerMilanATMInput {
  identificationNumber: number;
  serialNumber: string;
  fiscalCode: string;
}

/**
 * Paths to redirect to after checkout. `system.webUrl` will be prepended to the paths. Example of a path could be `/profile`.
 */
export interface PaymentPaths {
  successPath: string;
  cancelPath: string;
}

export interface PhoneVerificationInput {
  twilioVerificationSid: string;
  code: string;
  termsAccepted?: boolean | null;
  legalConsents?: LegalConsentInput[] | null;
}

export interface QueryOptions {
  page?: number | null;
  perPage?: number | null;
  order?: string | null;
  orderBy?: string | null;
  showDeleted?: boolean | null;
}

export interface SanityImageOptions {
  width?: number | null;
  height?: number | null;
  fit?: SanityImageOptionFit | null;
  crop?: SanityImageOptionCrop | null;
  quality?: number | null;
}

export interface SignupConsent {
  info?: boolean | null;
  autoRenew?: boolean | null;
  allowCharges?: boolean | null;
}

export interface UserDataExportRequestOptionsInput {
  retentionPolicy?: UserDataExportRequestRetentionPolicy | null;
  format?: UserDataExportRequestFormat | null;
  expirationTimestamp?: CORE_IsoDateTime | null;
  exportPersonalInfo?: boolean | null;
  exportTrips?: boolean | null;
  exportSubscriptions?: boolean | null;
  exportOrders?: boolean | null;
  exportPenalties?: boolean | null;
  exportConversations?: boolean | null;
  exportLogEntries?: boolean | null;
}

export interface UserInput {
  gender?: Gender | null;
  name?: string | null;
  birthYear?: string | null;
  phoneNumber?: string | null;
  email?: CORE_EmailAddress | null;
  address?: string | null;
  postalCode?: string | null;
  rfid?: string | null;
  preferredLanguageCode?: string | null;
  enabledExtendedRentalTrips?: boolean | null;
  enabledPushNotifications?: boolean | null;
  agreedToReceiveEmail?: boolean | null;
  agreedToReceiveExternalPromotions?: boolean | null;
  agreedToSendDataToThirdParties?: boolean | null;
  walletEnabled?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
